import styled from "styled-components";

const StyledListingCard = styled.div`
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  background-color: #f6f6f6;
  border-top-left-radius: 10px;
  margin: 2rem 1rem;
  height: 120px;
  .img_container {
    img {
      height: 120px;
      width: 100%;
      max-width: 161px;
      object-fit: cover;
    }
  }
  .card_body {
    margin: 0 1rem;
    .shopnow_btn {
      padding: 0.5rem 1rem;
      width: 120px;
      background-color: #404040;
      color: #fff;
      font-size: 14px;
      text-transform: uppercase;
      a {
        color: #fff;
      }
    }

    h3 {
      font-size: 25px;
      font-weight: 400;
    }
  }
`;

export default StyledListingCard;
