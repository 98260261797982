import styled from "styled-components";

const StyledPrescriptionTab = styled.div`
  background-color: #f8f8f8;

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 700;
    h6 {
      font-size: 13px;
      color: #0066ff;
    }
  }
`;

export default StyledPrescriptionTab;
