import React from "react";
import StyledCategoryCard from "./StyledCategoryCard";
import StyledButton from "../../../styles/categoryStyles.css";
import { Link } from "react-router-dom";

const CategoryCard = (props) => {
  return (
    <StyledCategoryCard>
      {window.screen.width > 900 ? (
        <div style={{ position: "relative" }} className="default-boxshadow">
          <img className="modal_image" src={props.category.image} />
          <div style={{ position: "absolute", bottom: "25px", width: "100%" }}>
            <h3 className="category_main_title">{props.category.title}</h3>
            <div className="shop-now-btn">
              <Link to={props.category.path}>Shop Now </Link>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div
            style={{
              position: "relative",
              boxShadow: "0 2px 5px 0 rgb(0 0 0 / 20%)",
            }}
          >
            <img className="modal_image" src={props.category.image} />
            <div style={{ textAlign: "center" }}>
              <h3
                className="category_main_title_mob"
                style={{ position: "absolute" }}
              >
                {props.category.title}
              </h3>
            </div>
          </div>
          <div className="shop-now-btn">
            <Link to={props.category.path}>Shop Now </Link>
          </div>
        </>
      )}
    </StyledCategoryCard>
  );
};

export default CategoryCard;
