import styled from "styled-components";

const StyledGlassType = styled.div`
  /* border: 1px solid red; */
  margin: 0.9rem;
  .glass_img {
    img {
      width: 100%;
    }
  }
  .glases_name {
    text-align: center;
    font-size: 14px;
    margin: 0.5rem 0;
    /* width: 100%; */
    .glasses_text {
      font-size: 18px;
      color: #404040;
      font-weight: 400;
      a {
        color: #404040;
      }
    }
  }
`;

export default StyledGlassType;
