import { createSlice } from "@reduxjs/toolkit";
import produce from 'immer'
import { auth } from "../../Firebase/Config";

const initialState = {
  productData: {
    sizes: [],
    colors: []
  },
  cartProducts: [],
  wishlist: [],
  showloginModal: false,
  quantity: 0
};
const addItemToArray = (state, action) => {
  const nextState = produce(state.cartProducts, (draftState) => {
    draftState[action.payload["index"]] = action.payload["data"]
  });
  state.cartProducts = nextState;
  if (!auth.currentUser) {
    localStorage.setItem("cart", JSON.stringify(state.cartProducts));
  }
}
const removewishlist = (state, action) => {
  const nextState = produce(state.wishlist, (draftState) => {
    draftState.splice(action.payload["index"], 1);
  });
  state.wishlist = nextState;

}

export const productDataSlice = createSlice({
  name: "setproductData",
  initialState,
  reducers: {
    setdataProduct: (state, action) => {
      state.productData = action.payload;
    },
    setquantity: (state, action) => {
      state.quantity = action.payload;
    },
    setcartproducts: (state, action) => {
      state.cartProducts = action.payload;
      if (!auth.currentUser) {
        localStorage.setItem("cart", JSON.stringify(state.cartProducts));
      }
    },
    setwishlist: (state, action) => {
      state.wishlist = action.payload;
    },
    removeWishlist: removewishlist,
    setshowLogin: (state, action) => {
      state.showloginModal = action.payload;
    },
    setcartproductsatindex: addItemToArray,
    updatedata: (state, action) => {
    }
  },
});
// Action creators are generated for each case reducer function
export const { setdataProduct, setquantity, setcartproducts, setcartproductsatindex, setshowLogin,setwishlist,removeWishlist } =
  productDataSlice.actions;

export default productDataSlice.reducer;
