import React from "react";
import StyledShopByText from "./StyledShopByText";

const ShopByText = () => {
  return (
    <StyledShopByText>
      <h1 className="shop_text">Shop By</h1>
    </StyledShopByText>
  );
};

export default ShopByText;
