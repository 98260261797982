import styled from "styled-components";

const StyledSizeComponent = styled.div`
  padding: 1rem 0;
  .size_text {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .size {
      font-size: 15.48px;
    }
    .size_chart {
      margin-left: 2rem !important;
      font-size: 15.48px;
      color: #0066ff;
      .greater_sign {
        margin-left: 10px;
      }
    }
  }
  .size_circles {
    display: flex;
    padding-top: 1rem;

    .circle {
      cursor: pointer;
      // border-radius: 50%;
      // width: 32.22px;
      // height: 32.22px;
      padding:0.5rem 1rem;
      background-color: #f6f6f6;
      display: flex;
      justify-content: center;
      align-items: center;

      margin: 0 0.2rem;
    }
  }
  @media screen and (max-width:900px){
    .size_text{
      padding-left:25px;
    }
    .size_circles{
      margin-left:25px;
    }
  }
`;

export default StyledSizeComponent;
