import { Button, Col, Input, Modal, notification, Row, Select, Spin, } from "antd";
import Title from "antd/lib/skeleton/Title";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signin, ValidateOtp } from "../../Firebase/auth";
import { httpGet, httpPost } from "../../networkutils/networkutils";
import { setshowLogin, setwishlist } from "../../redux-tool/features/product-features";
const LoginModal = (props) => {
    const [spinning, setspinning] = useState(false);
    const navigate = useNavigate();

    const visibleModal = useSelector((state) => state.setproductData.showloginModal);
    const getcartproducts = useSelector((state) => state.setproductData.cartProducts);

    const [countrycode, setcountryCode] = useState("+91");
    const [mobilenumber, setmobilenumber] = useState("");
    const [showotp, setshowotp] = useState(false);
    const [otp, setotp] = useState("");
    const [fireresult, setfireresult] = useState("");
    const { Option } = Select;
    const dispatch = useDispatch();
    useEffect(() => {
    }, [])
    const handleOk = (e) => {
        dispatch(setshowLogin(false))
    };
    const handleCancel = (e) => {
        dispatch(setshowLogin(false))
    };
    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: message,
            description:
                '',
        });
    };
    const extractids = () => {
        return new Promise((resolve, reject) => {
            var items = [];
            getcartproducts.forEach((value, index) => {
                items.push({
                    id: value["productID"],
                    qty: value["cart_quantity"]
                });
                if (index === getcartproducts.length - 1) resolve(items);
            });
        })
    }
    const addtowishlist = () => {
        const body = {
            product_id: props.productID
        }
        httpPost("wishlist/add", body).then((res) => {
            if (res["status"] === "SUCCESS") {
                getwishlist();
            }
        })
    }
    const getwishlist = () => {
        httpGet("wishlist/get").then((wsres) => {
            if (wsres["status"] === "SUCCESS") {
                dispatch(setwishlist(wsres["data"]))
            }
        })
    }
    return (
        <>
            <Modal
                title=""
                visible={visibleModal}
                onOk={handleOk}
                onCancel={handleCancel}
                okButtonProps={{ disabled: true }}
                cancelButtonProps={{ disabled: true }}
                footer={null}
                width={500}
            >
                <Spin spinning={spinning} tip="Loading...">
                    <div className="login-modal">
                        <Row justify="center">
                            <Col xs={20}>
                                <Title className="modal-title" level={2} style={{ textAlign: "center", marginBottom: "1rem !important" }}>Sign up</Title>
                                <br />
                                <Input.Group compact>
                                    <Select onSelect={(e) => {
                                        setcountryCode(e);
                                    }} defaultValue={countrycode}>
                                        <Option value="+91">+91</Option>
                                        <Option value="+971">+971</Option>
                                    </Select>

                                    <Input onChange={(e) => {
                                        setmobilenumber(e.target.value)
                                    }} style={{ width: '80%' }} defaultValue="" placeholder="Phone number" />
                                </Input.Group>
                                <br />
                                {showotp ? <Input placeholder="OTP" onChange={(e) => {
                                    setotp(e.target.value)
                                }} style={{ width: '100%' }} defaultValue="" /> : null}
                                <div id="recaptcha-container"></div>
                                <Button
                                    onClick={() => {
                                        if (mobilenumber.length > 4) {
                                            setspinning(true);
                                            if (showotp) {
                                                ValidateOtp(fireresult, otp).then((res) => {
                                                    setspinning(false);
                                                    localStorage.setItem('token', res.user.uid);
                                                    dispatch(setshowLogin(false))
                                                    openNotificationWithIcon("success", 'Successfully verified');
                                                    extractids().then((data) => {
                                                        httpPost("cart/addcartbyID", { items: JSON.stringify(data) }).then((res) => {
                                                            if (res["status"] === "SUCCESS") {
                                                                localStorage.removeItem("cart");
                                                                addtowishlist();
                                                            }
                                                        })
                                                    })
                                                }).catch(() => {
                                                    setspinning(false);
                                                    openNotificationWithIcon("Error", "Not able to verify");
                                                })
                                            } else {
                                                signin(countrycode + mobilenumber).then((result) => {
                                                    setfireresult(result);
                                                    setshowotp(true);
                                                    setspinning(false);
                                                }).catch(() => {
                                                    setspinning(false);
                                                    openNotificationWithIcon("Error", "Not able to verify");
                                                });
                                            }
                                        }

                                    }}
                                    style={{ width: "100%", marginTop: "1rem", backgroundColor: "#1A1A1A", color: "#fff" }}
                                >{showotp ? "VERIFY" : "SEND OTP"}</Button>

                            </Col>
                        </Row>
                    </div>
                </Spin>
            </Modal></>
    )
}

export default LoginModal;