import React, { useEffect, useState } from "react";
import { Row, Col, Divider } from "antd";
import SingleProductImage from "./Components/SingleProductImage/SingleProductImage";
import ProductImageGrid from "./Components/productImageGrid/ProductImageGrid";
import ProductDetailComponent from "./Components/ProductDetailComponent/ProductDetailComponent";
import { Button } from "antd";
import tick from "../../../assets/uploadImage/tick.svg";
import camera from "../../../assets/uploadImage/Camera.svg";
import { Collapse } from "antd";
import ProductCard from "../../ListingMainPage/Components/ProductCard/ProductCard";
import measure from "../../../assets/uploadImage/measureglass.svg";
import measure3 from "../../../assets/uploadImage/measureglass3.svg";
import { Breadcrumb } from "antd";

import { useLocation } from "react-router-dom";
import { httpGet } from "../../../networkutils/networkutils";
import { useDispatch } from "react-redux";
import { setdataProduct } from "../../../redux-tool/features/product-features";
import { Grid } from "@mui/material";

const ProductPage = (props) => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [productData, setproductData] = useState({
    product_details: [],
    frame_measurment: [],
    specification: [],
    sizes: [],
    colors: [],
    images: [],
    product_description: "",
  });
  useEffect(() => {
    getproductData(state);
    window.scrollTo(0, 0);
  }, []);

  const getproductData = (id) => {
    httpGet(`listing/getproductData?id=${id}`).then((res) => {
      if (res["status"] === "SUCCESS") {
        setproductData(res["data"]);
        dispatch(setdataProduct(res["data"]));
      }
    });
  };
  const { Panel } = Collapse;
  const [Img, setImg] = useState();
  const sampleProducts = [];

  return (
    <>
      <Row>
        <Col xs={24} style={{ padding: "1rem 0" }}>
          {" "}
          <Breadcrumb style={{ marginLeft: "4%" }}>
            <Breadcrumb.Item>
              <a href="/home"> Home</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <a href="/listing/main"> Listing</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <a href=""> Product Page </a>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      {window.screen.width > 900 ?
        <Row>
          {/* <Col></Col> */}
          <Col xs={2}>
            <ProductImageGrid
              setImg={setImg}
              Img={Img}
              arrayImg={productData["images"]}
            />
          </Col>
          <Col xs={14}>
            <SingleProductImage Img={Img ?? productData["primary_image"]} />
            <Row justify="center">
              <Col
                style={{
                  display: "flex",
                  padding: "1rem 0",
                }}
                xs={12}
              >
                <Button
                  className="button1"
                  type="primary"
                  style={{
                    fontSize: "13.93px",
                    backgroundColor: "white",
                    color: "#0066FF",
                    borderRadius: "7.74px",
                    marginRight: "1rem",
                    display: "flex",
                    alignItems: "center",
                    border: "0.232161px solid #8e8e8e5c",
                  }}
                >
                  <img width="15" src={tick} />
                  <div style={{ marginLeft: "3px" }} className="button_text">
                    CHOOSE LENSES
                  </div>
                </Button>
                <Button
                  className="button1"
                  type="primary"
                  style={{
                    fontSize: "13.93px",
                    backgroundColor: "white",
                    color: "#8E8E8E",
                    borderRadius: "7.74px",
                    marginLeft: "1rem",
                    display: "flex",
                    alignItems: "center",
                    border: "0.232161px solid #8e8e8e5c",
                  }}
                >
                  <img src={camera} />
                  <div style={{ marginLeft: "3px" }} className="button_text">
                    TRY ONLINE
                  </div>
                </Button>
              </Col>
            </Row>
          </Col>
          <Col xs={8}>
            <ProductDetailComponent data={productData} productID={state} />
          </Col>
          {/* <Col></Col> */}
        </Row> : <>
          <Grid container>
            <Grid item xs={12}>
              <SingleProductImage Img={Img ?? productData["primary_image"]} />
              <Grid container justifyContent="center">
                <Grid item

                  xs={5}
                >
                  <Button
                    className="button1"
                    type="primary"
                    style={{
                      fontSize: "13.93px",
                      backgroundColor: "white",
                      color: "#0066FF",
                      borderRadius: "7.74px",
                      marginRight: "1rem",
                      display: "flex",
                      alignItems: "center",
                      border: "0.232161px solid #8e8e8e5c",
                    }}
                  >
                    <img width="15" src={tick} />
                    <div style={{ marginLeft: "3px" }} className="button_text">
                      CHOOSE LENSES
                    </div>
                  </Button>
                </Grid>
                <Grid item xs={5} justifyContent="center">
                  <Button
                    className="button1"
                    type="primary"
                    style={{
                      fontSize: "13.93px",
                      backgroundColor: "white",
                      color: "#8E8E8E",
                      borderRadius: "7.74px",
                      marginLeft: "1rem",
                      display: "flex",
                      alignItems: "center",
                      border: "0.232161px solid #8e8e8e5c",
                    }}
                  >
                    <img src={camera} />
                    <div className="button_text">
                      TRY ONLINE
                    </div>
                  </Button>
                </Grid>
              </Grid>
            </Grid>

          </Grid><br />
          <Grid container justifyContent={"center"}>
            <Grid item xs={8} justifyContent="center">
              <ProductImageGrid
                setImg={setImg}
                Img={Img}
                arrayImg={productData["images"]}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent={"center"}>
            <Grid item xs={12}>
              <ProductDetailComponent data={productData} productID={state} />
            </Grid>
          </Grid>
        </>
      }

      {window.screen.width > 900 ?
        <Row>
          <Col xs={16}></Col>
          <Col xs={8}>
            {" "}
            <Collapse
              expandIconPosition="right"
              style={{ height: "100%", backgroundColor: "white", border: "none" }}
              defaultActiveKey={["1", "2"]}
              accordion
            >
              <Panel header="Product Details" key="1">
                <p>{productData["product_description"]}</p>
                <Row>
                  <Col xs={12}>
                    <ul style={{ listStyle: "none" }}>
                      {productData["product_details"].map((value) => {
                        return <li>{value["name"]}</li>;
                      })}
                    </ul>
                  </Col>
                  <Col xs={12}>
                    <ul style={{ listStyle: "none" }}>
                      {productData["product_details"].map((value) => {
                        return <li>{value["value"]}</li>;
                      })}
                      <Divider />
                    </ul>
                  </Col>
                </Row>
              </Panel>
              <Panel header="Frame & Measurements" key="2">
                <Row>
                  <Col xs={24}>
                    mm <span>In</span>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} style={{ marginTop: "1rem" }}>
                    <ul style={{ listStyle: "none" }}>
                      <li style={{ padding: "0.5rem 0rem" }}>
                        <img width="71.26px" src={measure} />
                      </li>
                      <li style={{ padding: "0.5rem 0rem" }}>
                        {" "}
                        <img width="71.26px" src={measure} />
                      </li>
                      <li style={{ padding: "0.5rem 0rem" }}>
                        {" "}
                        <img width="71.26px" src={measure3} />
                      </li>
                    </ul>
                  </Col>
                  <Col xs={12} style={{ marginTop: "1rem" }}>
                    {" "}
                    <ul style={{ listStyle: "none" }}>
                      {productData["frame_measurment"].map((value) => {
                        return (
                          <li style={{ padding: "0.4rem 0rem" }}>
                            {value["name"]} {value["value"]}
                          </li>
                        );
                      })}
                      <Divider />
                    </ul>
                  </Col>
                </Row>
              </Panel>
              <Panel
                header="Material & Care"
                key="3"
                style={{ backgrounColor: "white" }}
              >
                <h5>Plastic and Metal</h5>
                <p style={{ marginTop: "1rem" }}>
                  Plastic and Metal Remove dust and grime by gently wiping the
                  lens with a cloth
                </p>
              </Panel>
              <Panel header="Specifications" key="4">
                <Row>
                  {productData["specification"].map((value) => {
                    return (
                      <Col xs={12}>
                        <div>{value["name"]}</div>
                        <div>{value["value"]}</div>
                        <Divider />
                      </Col>
                    );
                  })}
                </Row>
              </Panel>
              {/* <Panel header="Ratings" key="5">
            <Row>
              <Col xs={12}>
                <ProductRatingComponent />
              </Col>
              <Col xs={12}>bar</Col>
            </Row>
          </Panel> */}
              {/* <Panel header="Customer Photos" key="6">
            <p>sameer</p>
          </Panel>
          <Panel header="Customer Reviews" key="7">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Card
                style={{ border: "none", margin: "1rem 0", width: "100%" }}
                title={
                  <div
                    style={{
                      width: "10px",
                      height: "10x",
                    }}
                  >
                    <img src={reviewphoto} />
                    <span style={{ fontSize: "11.21px", marginLeft: "2px" }}>
                      Ajmal Khan
                    </span>
                    <div>
                      <Rate disbled defaultValue={2} />
                      <span style={{ fontSize: "9.61px" }}>
                        January 15, 2022
                      </span>
                    </div>
                  </div>
                }
                extra={
                  <a href="#">
                    <i class="fa-solid fa-thumbs-up"> </i>
                    <span
                      style={{
                        fontSize: "9.61px",
                        fontWeight: "400",
                        lineHeight: "17.1px",
                      }}
                    >
                      {" "}
                      125 like
                    </span>
                  </a>
                }
              >
                <p>
                  Quality and fitting are good, but item doesn't have cover,
                  due to that particular reason my item got scratched. Wish
                  they sold the cover too with product.
                </p>
              </Card>
              <Card
                style={{ border: "none", marginTop: "1rem ", width: "100%" }}
                title={
                  <div
                    style={{
                      width: "10px",
                      height: "10x",
                    }}
                  >
                    <img src={reviewphoto} />
                    <span style={{ fontSize: "11.21px", marginLeft: "2px" }}>
                      Ajmal Khan
                    </span>
                    <div>
                      <Rate disbled defaultValue={2} />
                      <span style={{ fontSize: "9.61px" }}>
                        January 15, 2022
                      </span>
                    </div>
                  </div>
                }
                extra={
                  <a href="#">
                    <i class="fa-solid fa-thumbs-up"> </i>
                    <span
                      style={{
                        fontSize: "9.61px",
                        fontWeight: "400",
                        lineHeight: "17.1px",
                      }}
                    >
                      {" "}
                      125 like
                    </span>
                  </a>
                }
              >
                <p>
                  Quality and fitting are good, but item doesn't have cover,
                  due to that particular reason my item got scratched. Wish
                  they sold the cover too with product.
                </p>
              </Card>
            </div>
          </Panel> */}
            </Collapse>
          </Col>
        </Row> : <Grid container justifyContent={"center"}>
          <Grid item  xs={12}>
            {" "}
            <Collapse
              expandIconPosition="right"
              style={{ height: "100%", backgroundColor: "white", border: "none" }}
              defaultActiveKey={["1", "2"]}
              accordion
            >
              <Panel header="Product Details" key="1">
                <p>{productData["product_description"]}</p>
                <Row>
                  <Col xs={12}>
                    <ul style={{ listStyle: "none" }}>
                      {productData["product_details"].map((value) => {
                        return <li>{value["name"]}</li>;
                      })}
                    </ul>
                  </Col>
                  <Col xs={12}>
                    <ul style={{ listStyle: "none" }}>
                      {productData["product_details"].map((value) => {
                        return <li>{value["value"]}</li>;
                      })}
                      <Divider />
                    </ul>
                  </Col>
                </Row>
              </Panel>
              <Panel header="Frame & Measurements" key="2">
                <Row>
                  <Col xs={24}>
                    mm <span>In</span>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} style={{ marginTop: "1rem" }}>
                    <ul style={{ listStyle: "none" }}>
                      <li style={{ padding: "0.5rem 0rem" }}>
                        <img width="71.26px" src={measure} />
                      </li>
                      <li style={{ padding: "0.5rem 0rem" }}>
                        {" "}
                        <img width="71.26px" src={measure} />
                      </li>
                      <li style={{ padding: "0.5rem 0rem" }}>
                        {" "}
                        <img width="71.26px" src={measure3} />
                      </li>
                    </ul>
                  </Col>
                  <Col xs={12} style={{ marginTop: "1rem" }}>
                    {" "}
                    <ul style={{ listStyle: "none" }}>
                      {productData["frame_measurment"].map((value) => {
                        return (
                          <li style={{ padding: "0.4rem 0rem" }}>
                            {value["name"]} {value["value"]}
                          </li>
                        );
                      })}
                      <Divider />
                    </ul>
                  </Col>
                </Row>
              </Panel>
              <Panel
                header="Material & Care"
                key="3"
                style={{ backgrounColor: "white" }}
              >
                <h5>Plastic and Metal</h5>
                <p style={{ marginTop: "1rem" }}>
                  Plastic and Metal Remove dust and grime by gently wiping the
                  lens with a cloth
                </p>
              </Panel>
              <Panel header="Specifications" key="4">
                <Row>
                  {productData["specification"].map((value) => {
                    return (
                      <Col xs={12}>
                        <div>{value["name"]}</div>
                        <div>{value["value"]}</div>
                        <Divider />
                      </Col>
                    );
                  })}
                </Row>
              </Panel>
              {/* <Panel header="Ratings" key="5">
              <Row>
                <Col xs={12}>
                  <ProductRatingComponent />
                </Col>
                <Col xs={12}>bar</Col>
              </Row>
            </Panel> */}
              {/* <Panel header="Customer Photos" key="6">
              <p>sameer</p>
            </Panel>
            <Panel header="Customer Reviews" key="7">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Card
                  style={{ border: "none", margin: "1rem 0", width: "100%" }}
                  title={
                    <div
                      style={{
                        width: "10px",
                        height: "10x",
                      }}
                    >
                      <img src={reviewphoto} />
                      <span style={{ fontSize: "11.21px", marginLeft: "2px" }}>
                        Ajmal Khan
                      </span>
                      <div>
                        <Rate disbled defaultValue={2} />
                        <span style={{ fontSize: "9.61px" }}>
                          January 15, 2022
                        </span>
                      </div>
                    </div>
                  }
                  extra={
                    <a href="#">
                      <i class="fa-solid fa-thumbs-up"> </i>
                      <span
                        style={{
                          fontSize: "9.61px",
                          fontWeight: "400",
                          lineHeight: "17.1px",
                        }}
                      >
                        {" "}
                        125 like
                      </span>
                    </a>
                  }
                >
                  <p>
                    Quality and fitting are good, but item doesn't have cover,
                    due to that particular reason my item got scratched. Wish
                    they sold the cover too with product.
                  </p>
                </Card>
                <Card
                  style={{ border: "none", marginTop: "1rem ", width: "100%" }}
                  title={
                    <div
                      style={{
                        width: "10px",
                        height: "10x",
                      }}
                    >
                      <img src={reviewphoto} />
                      <span style={{ fontSize: "11.21px", marginLeft: "2px" }}>
                        Ajmal Khan
                      </span>
                      <div>
                        <Rate disbled defaultValue={2} />
                        <span style={{ fontSize: "9.61px" }}>
                          January 15, 2022
                        </span>
                      </div>
                    </div>
                  }
                  extra={
                    <a href="#">
                      <i class="fa-solid fa-thumbs-up"> </i>
                      <span
                        style={{
                          fontSize: "9.61px",
                          fontWeight: "400",
                          lineHeight: "17.1px",
                        }}
                      >
                        {" "}
                        125 like
                      </span>
                    </a>
                  }
                >
                  <p>
                    Quality and fitting are good, but item doesn't have cover,
                    due to that particular reason my item got scratched. Wish
                    they sold the cover too with product.
                  </p>
                </Card>
              </div>
            </Panel> */}
            </Collapse>
          </Grid>
        </Grid>}
      <Divider />

      <Row wrap="true" style={{ padding: "1rem 0" }}>
        {sampleProducts.map((data) => {
          return (
            <Col xs={8}>
              <ProductCard payload={data} />
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default ProductPage;
