import React, { useEffect } from "react";
import { Row, Col, Breadcrumb } from "antd";
import { Checkbox } from "antd";
import { Layout, Menu } from "antd";
import EyeGlassType from "../MainPage/Components/EyeGlassType";
import BreadCrumb from "./Components/BreadCrumbComponent/BreadCrumb";
import { Dropdown } from "antd";
import RectangleGlasses from "../../assets/filtermenuimages/ReactangleGlasses.svg";
import { Slider } from "antd";
import { Card } from "antd";
import uploadImage from "../../assets/uploadImage/uploadimage.png";
import upload from "../../assets/uploadImage/upload.png";
import loveShape from "../../assets/uploadImage/loveshape.svg";
import downarrow from "../../assets/icons/down_arrow.svg";
import plusIcon from "../../assets/icons/plus_icon.svg";
import Line from "../../assets/uploadImage/Line.png";
import Glassleg from "../../assets/uploadImage/glassleg.svg";
import Glassvector from "../../assets/uploadImage/glassvector.svg";
import StyledFilterText from "./Components/StyledFilterText";
import StyledColorFilterComponent from "./Components/StyledColorFilterComponent";
import StyledTextUpload from "./Components/StyledTextUpload";
import StyledUploadImage from "./Components/StyledUploadImage";
import ProductCard from "./Components/ProductCard/ProductCard";
import StyledPriceComponent from "./Components/StyledPriceComponent";
import StyledViewAll from "./Components/StyledViewAll";
import "../../styles/mainpageStyles.css";
import { Outlet } from "react-router";
import { httpGet } from "../../networkutils/networkutils";
import { useState } from "react";
import { Button, Grid } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import { DownOutlined } from "@ant-design/icons";

const ListingMainPage = () => {
  const [frameshapes, setframeshapes] = useState([]);
  const [colors, setcolors] = useState([]);
  const [lenstypes, setlensTypes] = useState([]);
  const [frametypes, setframetypes] = useState([]);
  const [materials, setmaterials] = useState([]);
  const [brands, setbrands] = useState([]);
  const [features, setfeatures] = useState([]);
  const [products, setProducts] = useState([]);
  const [showfilter, setshowfilter] = useState(false);
  const [genderFilter, setgenderFilter] = useState([]);
  const [frameshapeFilter, setframeshapeFilter] = useState([]);
  const [lenstypeFilter, setlenstypeFilter] = useState([]);
  const [sizesFilter, setsizesFilter] = useState([]);
  const [brandFilter, setbrandFilter] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    getfilters();
    getproducts();
  }, []);

  const getfilters = () => {
    httpGet("listing/getfilters").then((res) => {
      if (res["status"] === "SUCCESS") {
        const filters = res["data"];
        setframeshapes([...filters["frame_shapes"]]);
        setcolors([...filters["colors"]]);
        setlensTypes([...filters["lens_types"]]);
        setframetypes([...filters["frame_types"]]);
        setmaterials([...filters["materials"]]);
        setfeatures([...filters["features"]]);
        setbrands([...filters["brands"]]);
      }
    });
  };
  const getproducts = (queryparams) => {
    httpGet(`listing/getproducts?limit=25${queryparams}`).then((res) => {
      if (res["status"] === "SUCCESS") {
        setProducts([...res["data"]]);
      }
    });
  };
  const trs = [{ id: "1", name: "Test" }];

  const { Header, Content, Footer, Sider } = Layout;
  const { SubMenu } = Menu;
  const { Meta } = Card;
  const glassCategories = [
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/glass1.png?alt=media&token=d630efdc-5cda-4aac-b622-29a97b17239c",
      title: "Eyeglasses",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/glass2.png?alt=media&token=d630efdc-5cda-4aac-b622-29a97b17239c",
      title: "Sunglasses",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/glass3.png?alt=media&token=d630efdc-5cda-4aac-b622-29a97b17239c",
      title: "Readers",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/glass4.png?alt=media&token=d630efdc-5cda-4aac-b622-29a97b17239c",
      title: "Blue Light",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/glass5.png?alt=media&token=d630efdc-5cda-4aac-b622-29a97b17239c",
      title: "Protective Eyewear",
    },
  ];

  const sampleavatars = [
    {
      id: "1",
      image:
        "https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/sample1.png?alt=media&token=49964508-d4da-49e1-aad1-2131e2b03c1a",
    },
    {
      id: "3",
      image:
        "https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/sample3.png?alt=media&token=49964508-d4da-49e1-aad1-2131e2b03c1a",
    },
    {
      id: "2",
      image:
        "https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/sample2.png?alt=media&token=49964508-d4da-49e1-aad1-2131e2b03c1a",
    },
    {
      id: "2",
      image:
        "https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/sample2.png?alt=media&token=49964508-d4da-49e1-aad1-2131e2b03c1a",
    },
  ];
  const sizes = [
    { id: 0, name: "Medium" },
    { id: 1, name: "Small" },
    { id: 2, name: "Large" },
    { id: 3, name: "XL" },
  ];
  const handleClick = (e) => {};
  const Filters = () => {
    return (
      <Menu
        onClick={handleClick}
        style={{ backgroundColor: "white" }}
        // defaultSelectedKeys={["1"]}
        defaultOpenKeys={[
          "sub1",
          "sub2",
          "sub3",
          "sub4",
          "sub5",
          "sub6",
          "sub7",
          "sub8",
          "sub9",
          "sub10",
          "sub11",
          "sub12",
        ]}
        mode="inline"
      >
        <SubMenu style={{ backgroundColor: "white" }} key="sub1" title="Gender">
          <Menu.Item key="1">
            <Checkbox
              checked={genderFilter.indexOf("Male") !== -1}
              onChange={() => {
                const getindex = genderFilter.indexOf("Male");
                if (getindex !== -1) {
                  genderFilter.splice(getindex, 1);
                  setgenderFilter([...genderFilter]);
                } else {
                  genderFilter.push("Male");
                  setgenderFilter([...genderFilter]);
                }
                getproducts(`&gender=${genderFilter.join(",")}`);
              }}
            >
              Male
            </Checkbox>
          </Menu.Item>
          <Menu.Item key="2">
            <Checkbox
              checked={genderFilter.indexOf("Female") !== -1}
              onChange={() => {
                const getindex = genderFilter.indexOf("Female");
                if (getindex !== -1) {
                  genderFilter.splice(getindex, 1);
                  setgenderFilter([...genderFilter]);
                } else {
                  genderFilter.push("Female");
                  setgenderFilter([...genderFilter]);
                }
                getproducts(`&gender=${genderFilter.join(",")}`);
              }}
            >
              Female
            </Checkbox>
          </Menu.Item>
        </SubMenu>
        <div className="divider" />
        <SubMenu
          style={{ backgroundColor: "white" }}
          key="sub2"
          title="Frame shape"
        >
          {frameshapes.map((value, index) => {
            return (
              <>
                <Menu.Item key={"fr_" + index}>
                  <Checkbox
                    checked={frameshapeFilter.indexOf(value["id"]) !== -1}
                    onChange={(e) => {
                      const value = e.target.value;
                      const index = frameshapeFilter.indexOf(value);
                      if (index !== -1) {
                        frameshapeFilter.splice(index, 1);
                        setframeshapeFilter([...frameshapeFilter]);
                      } else {
                        frameshapeFilter.push(value);
                        setframeshapeFilter([...frameshapeFilter]);
                      }
                      getproducts(`&frame_shape=${frameshapeFilter.join(",")}`);
                    }}
                    key={value["id"]}
                    value={value["id"]}
                  >
                    {value["name"]}
                  </Checkbox>
                </Menu.Item>
              </>
            );
          })}
        </SubMenu>

        <SubMenu style={{ backgroundColor: "white" }} key="sub3" title="Color">
          <div style={{ marginLeft: "15px" }}>
            <Grid style={{ padding: "0.5rem 0" }} container>
              {colors.map((value) => {
                return (
                  <Grid item xs={3}>
                    <StyledColorFilterComponent bg={value["color_code"]} />
                    {/* <p
                  style={{
                    marginTop: "5px",
                    textAlign: "start",
                    fontSize: "12px",
                    marginBottom: "5px",
                    textTransform: "capitalize",
                  }}
                >
                  {value["name"]}
                </p> */}
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </SubMenu>
        {/* <div className="divider" />

    <SubMenu
      style={{ backgroundColor: "white" }}
      key="sub4"
      title="Frame Type"
    >
      {frametypes.map((value, index) => {
        return (
          <Menu.Item key={index}>
            <Checkbox key={value["id"]} value={value["id"]}>
              {value["name"]}
            </Checkbox>
          </Menu.Item>
        );
      })}
    </SubMenu> */}
        <div className="divider" />

        <SubMenu key="sub5" title="Lens type">
          {lenstypes.map((value, index) => {
            return (
              <Menu.Item key={"lens_" + index}>
                <Checkbox
                  key={value["id"]}
                  checked={lenstypeFilter.indexOf(value["id"]) !== -1}
                  onChange={(e) => {
                    const value = e.target.value;
                    const index = lenstypeFilter.indexOf(value);
                    if (index !== -1) {
                      lenstypeFilter.splice(index, 1);
                      setlenstypeFilter([...lenstypeFilter]);
                    } else {
                      lenstypeFilter.push(value);
                      setlenstypeFilter([...lenstypeFilter]);
                    }
                    getproducts(`&lens_type=${lenstypeFilter.join(",")}`);
                  }}
                  value={value["id"]}
                >
                  {value["name"]}
                </Checkbox>
              </Menu.Item>
            );
          })}
        </SubMenu>
        <div className="divider" />

        <SubMenu key="sub6" title="Size">
          {/* <h5
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <StyledViewAll>Not sure? Find your size</StyledViewAll>
      </h5> */}
          {sizes.map((value) => {
            return (
              <Menu.Item key={"size_" + value["id"]}>
                <Checkbox
                  value={value["name"]}
                  checked={sizesFilter.indexOf(value["name"]) !== -1}
                  onChange={(e) => {
                    const value = e.target.value;
                    const index = sizesFilter.indexOf(value);
                    if (index !== -1) {
                      sizesFilter.splice(index, 1);
                      setsizesFilter([...sizesFilter]);
                    } else {
                      sizesFilter.push(value);
                      setsizesFilter([...sizesFilter]);
                    }
                    getproducts(`&size=${sizesFilter.join(",")}`);
                  }}
                >
                  {value["name"]}
                </Checkbox>
              </Menu.Item>
            );
          })}
        </SubMenu>
        <div className="divider" />
        <SubMenu key="sub7" title="Material">
          {materials.map((value, index) => {
            return (
              <Menu.Item style={{ margin: "0.5rem 0" }} key={index}>
                <Checkbox key={value["id"]} value={value["id"]}>
                  {value["name"]}
                </Checkbox>
              </Menu.Item>
            );
          })}
        </SubMenu>
        <div className="divider" />
        <SubMenu key="sub8" title="Features">
          {features.map((value, index) => {
            return (
              <Menu.Item style={{ margin: "0.5rem 0" }} key={index}>
                <Checkbox key={value["id"]} value={value["id"]}>
                  {value["name"]}
                </Checkbox>
              </Menu.Item>
            );
          })}
        </SubMenu>
        <div className="divider" />
        <SubMenu key="sub9" title="Brand">
          {brands.map((value, index) => {
            return (
              <Menu.Item style={{ margin: "0.5rem 0" }} key={"brand_" + index}>
                <Checkbox
                  key={value["id"]}
                  value={value["id"]}
                  checked={brandFilter.indexOf(value["id"]) !== -1}
                  onChange={(e) => {
                    const value = e.target.value;
                    const index = brandFilter.indexOf(value);
                    if (index !== -1) {
                      brandFilter.splice(index, 1);
                      setbrandFilter([...brandFilter]);
                    } else {
                      brandFilter.push(value);
                      setbrandFilter([...brandFilter]);
                    }
                    getproducts(`&brand=${brandFilter.join(",")}`);
                  }}
                >
                  {value["name"]}
                </Checkbox>
              </Menu.Item>
            );
          })}
        </SubMenu>
        {/* <div className="divider" />

<SubMenu
style={{ backgroundColor: "white" }}
key="sub10"
title="Product Badge"
>
<Menu.Item style={{ margin: "0.5rem 0" }} key="44">
  <Checkbox>Premium Frames</Checkbox>
</Menu.Item>
<Menu.Item style={{ margin: "0.5rem 0" }} key="45">
  <Checkbox>Clearance</Checkbox>
</Menu.Item>
<Menu.Item style={{ margin: "0.5rem 0" }} key="46">
  <Checkbox>New Arrivals</Checkbox>
</Menu.Item>
</SubMenu> */}
        <div className="divider" />
        <SubMenu style={{ backgroundColor: "white" }} key="sub11" title="Price">
          <StyledPriceComponent>
            <h5>AED 25 - AED 1,000</h5>
            <Menu.Item key="47">
              <Slider
                style={{ paddingLeft: "0px" }}
                range
                defaultValue={[10, 40]}
              />
            </Menu.Item>
          </StyledPriceComponent>
        </SubMenu>
        <div className="divider" />
        {/* <SubMenu
          style={{ backgroundColor: "white" }}
          key="sub12"
          title="Frame Measurement"
        >
          <StyledPriceComponent>
            <div className="frame_img_text">
              <div className="frame_img">
                <img src={Line} />
                <img src={Glassleg} />
              </div>
              <div className="frame_text">
                <h5 className="text">0-190mm</h5>
              </div>
            </div>
            <Menu.Item key="48">
              <Slider range defaultValue={[10, 40]} />
            </Menu.Item>
          </StyledPriceComponent>
          <StyledPriceComponent>
            <div className="frame_img_text">
              <div className="frame_img">
                <img src={Line} />
                <img src={Glassvector} />
              </div>
              <div className="frame_text">
                <h5 className="text">38-68mm</h5>
              </div>
            </div>
            <Menu.Item key="49">
              <Slider range defaultValue={[10, 40]} />
            </Menu.Item>
          </StyledPriceComponent>
          <StyledViewAll>Learn More ?</StyledViewAll>
        </SubMenu> */}
        <div className="divider" />
      </Menu>
    );
  };

  const menu = (
    <Menu>
      <Menu.Item>Most Relevent</Menu.Item>
      <Menu.Item>Most Popular</Menu.Item>
      <Menu.Item>Name</Menu.Item>
      <Menu.Item>Lowest Price</Menu.Item>
      <Menu.Item>Highest Price</Menu.Item>
    </Menu>
  );

  return (
    <>
      <Outlet />
      {window.screen.width > 900 ? (
        <Row justify="center ">
          {glassCategories.map((data) => {
            return (
              <Col xs={4}>
                <EyeGlassType category={data} />
              </Col>
            );
          })}
        </Row>
      ) : (
        <Grid container spacing={2}>
          {glassCategories.map((data) => {
            return (
              <Grid item xs={4}>
                <EyeGlassType category={data} />
              </Grid>
            );
          })}
        </Grid>
      )}
      <div className="divider"></div>
      <div className="ml-2 mr-2">
        <Row
          style={{
            padding: "1rem 0rem ",
            marginRight: "0 !important",
          }}
          align="middle"
        >
          <Col xs={12}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <a href="/home">Home</a>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Listing</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-end",
              // border: "1px solid red",
            }}
            xs={12}
          >
            <Dropdown overlay={menu} trigger={["click"]}>
              <a
                style={{
                  border: "1px solid #eaeaec",
                  borderRadius: "10px",
                  padding: "0.2rem 1rem",
                  color: "black",
                  fontSize: "16px",
                }}
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                Most Relevent {""} <DownOutlined />
              </a>
            </Dropdown>
          </Col>
        </Row>
      </div>
      <div className="divider mb-2"></div>

      {window.screen.width > 900 ? (
        <Row>
          <Col xs={4}>
            <StyledFilterText>Filter By</StyledFilterText>
            <div className="divider" />
            <Filters />
          </Col>
          <Col xs={20}>
            <Row justify="center">
              <Col xs={24}>
                {" "}
                <Row>
                  {/* <Col xs={24}></Col> */}
                  <Col xs={24}>
                    <Row>
                      <Col xs={8}>
                        <StyledTextUpload>
                          <h2 className="upload_text">
                            Upload your picture or use any of our Models
                          </h2>
                          <p className="upload_text">
                            To save picture or access Previous uploads,
                          </p>
                          <p className="sign-here">sign in here</p>
                        </StyledTextUpload>
                      </Col>
                      <Col xs={16}>
                        <StyledUploadImage>
                          <div className="upload-image-sec">
                            <div className="upload-sec">
                              <div className="upload-circle">
                                <img
                                  src={plusIcon}
                                  style={{ padding: "9px", height: "50px" }}
                                />
                              </div>
                              <p className="upload-text">Upload Picture</p>
                            </div>
                          </div>
                          {sampleavatars.map((data) => {
                            return (
                              <div className="upload-img">
                                <img height="150px" src={data.image} />
                              </div>
                            );
                          })}
                        </StyledUploadImage>
                      </Col>
                    </Row>
                  </Col>
                </Row>{" "}
              </Col>
            </Row>
            <div className="divider" />
            <Row wrap="true">
              {products.map((data) => {
                return (
                  <Col
                    style={{ display: "flex", justifyContent: "center" }}
                    xs={8}
                  >
                    <ProductCard payload={data} />
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      ) : (
        <Grid container spacing={1}>
          <Grid item xs={6} justifyContent="center">
            <Button
              onClick={() => {
                setshowfilter(!showfilter);
              }}
            >
              {showfilter ? "HIDE FITER" : "SHOW FITER"}
            </Button>
          </Grid>
          <Drawer
            anchor={"left"}
            open={showfilter}
            onClose={() => {
              setshowfilter(false);
            }}
          >
            <Box
              sx={{ width: 250 }}
              onClick={() => {
                // setshowfilter(false);
              }}
              onKeyDown={() => {
                // setshowfilter(false);
              }}
            >
              <List>
                <Filters />
              </List>
            </Box>
          </Drawer>
          {showfilter ? (
            <Grid item xs={12}>
              <StyledFilterText>Filter By</StyledFilterText>
              <div className="divider" />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <div className="divider" />
            <Grid container spacing={2}>
              {products.map((data) => {
                return (
                  <Grid
                    style={{ display: "flex", justifyContent: "center" }}
                    xs={6}
                  >
                    <ProductCard payload={data} />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ListingMainPage;
