import { Grid } from "@mui/material";
import { Col, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import StyledSaleBanner from "./StyledSaleBanner";

const SaleBanner = () => {
  const banners = [
    "https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/banner1.png?alt=media&token=996d0538-3de8-473c-88d6-087ffa802c58",
    "https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/banner2.png?alt=media&token=c770b4fb-ed97-428c-ac62-b90bb51756e0",
  ];
  return window.screen.width > 900 ? (
    <StyledSaleBanner>
      <div className="container-fluid">
        <div className="row">
          <div className="col" span={12}>
            <Link to="listing/main">
              <img
                style={{ paddingRight: "10px" }}
                width="100%"
                src={banners[0]}
              />
            </Link>
          </div>
          <div className="col" span={12}>
            <Link to="listing/main">
              <img
                style={{ paddingLeft: "10px" }}
                width="100%"
                src={banners[1]}
              />
            </Link>
          </div>
        </div>
      </div>
    </StyledSaleBanner>
  ) : (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Link to="listing/main">
            <img width="100%" src={banners[0]} />
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Link to="listing/main">
            <img width="100%" src={banners[1]} />
          </Link>
        </Grid>
      </Grid>
    </>
  );
};

export default SaleBanner;
