import React, { useEffect, useState } from "react";
import BrandCard from "./Components/BrandCard";
import rayban from "../../assets/brandlogos/rayban.svg";
import { Col } from "antd";
import StyledContainer from "../../Components/StyledContainer";
import oakley from "../../assets/brandlogos/oakley.svg";
import gucci from "../../assets/brandlogos/gucci.svg";
import armani from "../../assets/brandlogos/armani.svg";
import coach from "../../assets/brandlogos/coach.svg";
import dkny from "../../assets/brandlogos/dkny.svg";
import muse from "../../assets/brandlogos/muse.svg";
import persol from "../../assets/brandlogos/Persol.svg";
import prada from "../../assets/brandlogos/Prada.svg";
import versace from "../../assets/brandlogos/versace.svg";
import CategoryCard from "./Components/CategoryCard";
import SaleBanner from "./Components/SaleBanner";
import Carousel from "./Components/Carousel";
import EyeWearForEveryone from "./Components/EyeWearForEveryone";
import EyeGlassType from "./Components/EyeGlassType";
import ShopByText from "./Components/ShopByText";
import TransCard from "./Components/TransCard";
import DiscoverMore from "./Components/DiscoverMore";
import DiscoverMoreCard from "./Components/DiscoverMoreCard";
import BannerContainer from "./Components/BannerContainer";
import SelectComponent from "./Components/SelectComponent";
import "../../styles/mainpageStyles.css";
import StyleDealofMonth from "./Components/StyleDealofMonth";
import { Link } from "react-router-dom";
import { httpGet } from "../../networkutils/networkutils";
import { Grid } from "@mui/material";

const MainPage = () => {
  const [sitecontents, setsitecontents] = useState({
    ad_banner: [],
    categories: [],
    slider: [],
    deal_of_month: [],
  });
  useEffect(() => {
    window.scrollTo(0, 0);
    getContents();
  }, []);
  const getContents = () => {
    httpGet("sitecontents/get").then((res) => {
      if (res["status"] === "SUCCESS") {
        setsitecontents(res["data"]);
      }
    });
  };

  const glassCategories = [
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/glass1.png?alt=media&token=d630efdc-5cda-4aac-b622-29a97b17239c",
      title: "Eyeglasses",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/glass2.png?alt=media&token=d630efdc-5cda-4aac-b622-29a97b17239c",
      title: "Sunglasses",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/glass3.png?alt=media&token=d630efdc-5cda-4aac-b622-29a97b17239c",
      title: "Readers",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/glass4.png?alt=media&token=d630efdc-5cda-4aac-b622-29a97b17239c",
      title: "Blue Light",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/glass5.png?alt=media&token=d630efdc-5cda-4aac-b622-29a97b17239c",
      title: "Protective Eyewear",
    },
  ];
  const featuresBanners = [
    {
      id: 1,
      image:
        "https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/ad-image1.jpg?alt=media&token=27711bd6-9c8c-4ee5-94af-1a2cb4b80a36",
      title: "Light Intelligent Lenses",
      desc: "",
      btn_title: "SHOP NOW",
    },
    {
      id: 2,
      image:
        "https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/ad-image2.jpg?alt=media&token=27711bd6-9c8c-4ee5-94af-1a2cb4b80a36",
      title: "Contact Lenses",
      desc: "",
      btn_title: "SHOP NOW",
    },
    {
      id: 3,
      image:
        "https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/try_on.png?alt=media&token=2d657f46-3d57-40af-9c12-252472b54d07",
      title: "Virtual Try-On",
      desc: "See yourself in any pair from your home.",
      btn_title: "Try Now",
    },
    {
      id: 4,
      image:
        "https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/take_quiz.png?alt=media&token=66fdaaa4-b0ab-4c30-babf-c13d67811afd",
      title: "Find your frame in 60 seconds.",
      desc: "Need help looking for your perfect pair? Take our quiz to find the best fit for you",
      btn_title: "Take the Quiz",
    },
  ];
  const moreDiscover = [
    { id: 1, title: "Kids Collection" },
    { id: 2, title: "Multifocal" },
    { id: 3, title: "Designer Glasses" },
    { id: 4, title: "Sport Glasses" },
    { id: 5, title: "Gift Card" },
    { id: 6, title: "Gift Card" },
    { id: 7, title: "Gift Card" },
    { id: 8, title: "Blue Lens" },
    { id: 9, title: "Computer Glasses" },
    { id: 10, title: "Contact Lenses" },
  ];
  return (
    <>
      {window.screen.width > 900 ? (
        <Carousel data={sitecontents["slider"]} />
      ) : (
        <div style={{ marginTop: "4rem" }}>
          <Carousel data={sitecontents["slider"]} />
        </div>
      )}

      {window.screen.width > 900 ? (
        <StyledContainer>
          <Grid container spacer={2} justifyContent="center">
            {/* <Col xs={1}></Col> */}
            <Grid xs={1}>
              <BrandCard img={rayban} />
            </Grid>
            <Grid xs={1}>
              <BrandCard img={oakley} />
            </Grid>
            <Grid xs={1}>
              <BrandCard img={gucci} />
            </Grid>
            <Grid xs={1}>
              <BrandCard img={persol} />
            </Grid>
            <Grid xs={1}>
              <BrandCard img={prada} />
            </Grid>
            <Grid xs={1}>
              <BrandCard img={muse} />
            </Grid>
            <Grid xs={1}>
              <BrandCard img={versace} />
            </Grid>
            <Grid xs={1}>
              <BrandCard img={dkny} />
            </Grid>
            <Grid xs={1}>
              <BrandCard img={coach} />
            </Grid>
            <Grid xs={1}>
              <BrandCard img={armani} />
            </Grid>
            <Grid xs={1}>
              <BrandCard />
            </Grid>
            {/* <Col xs={1}></Col> */}
          </Grid>
        </StyledContainer>
      ) : (
        <>
          <div
            className="container"
            style={{ marginTop: "1rem", padding: "20px" }}
          >
            <Grid container spacing={2}>
              {/* <Col xs={1}></Col> */}
              <Grid item xs={4}>
                <BrandCard img={rayban} />
              </Grid>
              <Grid item xs={4}>
                <BrandCard img={oakley} />
              </Grid>
              <Grid item xs={4}>
                <BrandCard img={gucci} />
              </Grid>
              <Grid item xs={4}>
                <BrandCard img={persol} />
              </Grid>
              <Grid item xs={4}>
                <BrandCard img={prada} />
              </Grid>
              <Grid item xs={4}>
                <BrandCard img={muse} />
              </Grid>
              <Grid item xs={4}>
                <BrandCard img={versace} />
              </Grid>
              <Grid item xs={4}>
                <BrandCard img={dkny} />
              </Grid>
              <Grid item xs={4}>
                <BrandCard img={coach} />
              </Grid>
              <Grid item xs={4}>
                <BrandCard img={armani} />
              </Grid>
              <Grid item xs={4}>
                <BrandCard />
              </Grid>
              {/* <Col xs={1}></Col> */}
            </Grid>
          </div>
          <div className="divider"></div>
        </>
      )}
      <StyleDealofMonth>
        <div className="deal_month_sec">
          {window.screen.width > 900 ? (
            <div
              style={{
                margin: "0 20px",
                padding: "20px",
                backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/deal-month-bg.png?alt=media&token=7e56eadf-2321-4835-82b4-845ac8892dcf)`,
                backgroundSize: "cover",
                borderRadius: "13px",
              }}
            >
              <Grid container justifyContent="center">
                <Grid item xs={6}>
                  <h2
                    className="deal-of-month-title"
                    style={{ marginTop: "2rem !important" }}
                  >
                    Deal of the Month
                  </h2>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={6}
                justifyContent="center"
                style={{ paddingLeft: "1.2rem", paddingRight: "1.2rem" }}
              >
                {sitecontents["deal_of_month"].map((data, index) => {
                  return index <= 3 ? (
                    <Grid item xs={3} style={{ margin: "0 auto" }}>
                      <Link to={data["path"]}>
                        <img
                          className="default-boxshadow"
                          width="100%"
                          // height="250px"
                          style={{ margin: "4px", borderRadius: "10px" }}
                          src={data["image"]}
                        />
                      </Link>
                    </Grid>
                  ) : null;
                })}
              </Grid>
            </div>
          ) : (
            <div
              className="container"
              style={{
                paddingTop: "15px",
                paddingBottom: "15px",
                backgroundImage: `url(
                  https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/deal-month-bg-mob.png?alt=media&token=86ab438d-3f2b-42ee-b8ee-70f93ea33baa
                )`,
              }}
            >
              <Grid container justifyContent="center">
                <Grid item xs={10}>
                  <h2
                    className="deal-of-month-title"
                    style={{ textAlign: "center" }}
                  >
                    Deal of the Month
                  </h2>
                  <br />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                {sitecontents["deal_of_month"].map((data, index) => {
                  return index !== sitecontents["deal_of_month"].length - 1 ? (
                    <Grid item xs={6}>
                      <Link to={data["path"]}>
                        <img
                          width="100%"
                          style={{
                            margin: "0px",
                            borderRadius: "10px",
                            boxShadow: "0 2px 5px 0 rgb(0 0 0 / 20%)",
                          }}
                          src={data["image"]}
                        />
                      </Link>
                    </Grid>
                  ) : null;
                })}
              </Grid>
            </div>
          )}
        </div>
      </StyleDealofMonth>
      {window.screen.width > 900 ? (
        <div style={{ padding: "20px" }}>
          <Grid
            container
            spacing={6}
            style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
          >
            {sitecontents["categories"].map((data) => {
              return (
                <Grid item xs={4}>
                  <CategoryCard category={data} />
                </Grid>
              );
            })}
          </Grid>
        </div>
      ) : (
        <>
          <div className="container" style={{ padding: "0 20px 20px" }}>
            <Grid container spacing={2}>
              {sitecontents["categories"].map((data) => {
                return (
                  <Grid item xs={4}>
                    <CategoryCard category={data} />
                  </Grid>
                );
              })}
            </Grid>
          </div>
          <div className="divider"></div>
        </>
      )}
      <SaleBanner />
      <div className="container">
        <div className="row" justify="center" wrap="false">
          <div className="col">
            <EyeWearForEveryone />
          </div>
        </div>
      </div>
      {window.screen.width > 900 ? (
        <div className="container">
          <div className="row" justify="center ">
            {glassCategories.map((data) => {
              return (
                <div className="col">
                  <EyeGlassType category={data} />
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <>
          <Grid container spacing={2}>
            {glassCategories.map((data) => {
              return (
                <Grid item xs={6}>
                  <EyeGlassType category={data} />
                </Grid>
              );
            })}
            <div className="divider"></div>
          </Grid>
        </>
      )}
      {window.screen.width < 900 ? (
        <Grid container justifyContent="center">
          <Grid item xs={6}>
            <ShopByText />
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      <SelectComponent />
      <div className="" style={{ padding: "0 0.9rem" }}>
        {window.screen.width > 900 ? (
          <>
            <Grid
              container
              spacing={5}
              className="feature-banner"
              style={{
                marginTop: "1rem",
                paddingLeft: "2.2rem",
                paddingRight: "2.2rem",
              }}
            >
              <Grid item xs={6}>
                <TransCard payload={featuresBanners[0]} />
              </Grid>
              <Grid item xs={6}>
                {" "}
                <TransCard payload={featuresBanners[1]} />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={5}
              style={{
                paddingLeft: "2.2rem",
                paddingRight: "2.2rem",
              }}
            >
              <Grid item xs={6}>
                <TransCard payload={featuresBanners[2]} />
              </Grid>
              <Grid item xs={6}>
                {" "}
                <TransCard payload={featuresBanners[3]} />
              </Grid>
            </Grid>
            <div className="divider"></div>
          </>
        ) : (
          <Grid container>
            <div className="divider"></div>
            <Grid container className="">
              <Grid item xs={12}>
                <TransCard payload={featuresBanners[0]} />
              </Grid>
              <Grid item xs={12}>
                {" "}
                <TransCard payload={featuresBanners[1]} />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <TransCard payload={featuresBanners[2]} />
              </Grid>
              <Grid item xs={12}>
                {" "}
                <TransCard payload={featuresBanners[3]} />
              </Grid>
            </Grid>
            <div className="divider"></div>
          </Grid>
        )}
        {window.screen.width > 900 ? (
          <>
            <div className="row justify-content-center">
              <Col xs={20}>
                <DiscoverMore />
              </Col>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="container">
        {window.screen.width > 900 ? (
          <div className="row justify-content-center" wrap="true">
            {moreDiscover.map((data) => {
              return (
                <Col xs={4}>
                  <DiscoverMoreCard key={data.id} payload={data} />
                </Col>
              );
            })}
            <div className="divider"></div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <Grid container>
        <Grid item xs={12}>
          <BannerContainer />
        </Grid>
      </Grid>
    </>
  );
};

export default MainPage;
