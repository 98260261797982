import styled from "styled-components";

const StyledImageGrid = styled.div`
  @media screen and (min-width:900px){
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
  }
  img {
    width: 100%;
    max-width: 66px;
    padding: 0.8rem 0;
    border: 0.1px solid #96969633;
    margin: 5px;
    cursor: pointer;
  }
`;

export default StyledImageGrid;
