import { Col, Row } from "antd";
import { Link } from "react-router-dom";

const Kidsmegamenu = () => {
  return (
    <div className="desktop-backdropStyle">
      <div className="desktop-paneContent">
        <Row>
          <Col>
            <li className="desktop-oddColumnContent">
              <ul className="desktop-navBlock">
                <li>
                  <Link to="/listing/women" className="desktop-categoryName">
                    Boys{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/listing/women" className="desktop-categoryLink">
                    Shop All Glasses
                  </Link>
                </li>
                <li>
                  <Link to="/listing/women" className="desktop-categoryLink">
                    Sunglasses
                  </Link>
                </li>
                <li>
                  <Link to="/listing/women" className="desktop-categoryLink">
                    Eye Glasses
                  </Link>
                </li>
                <li>
                  <Link to="/listing/women" className="desktop-categoryLink">
                    Contact Lens
                  </Link>
                </li>
                <li>
                  <Link to="/listing/women" className="desktop-categoryLink">
                    {" "}
                    Computer Glasses
                  </Link>
                </li>

                <li>
                  <a href="" className="desktop-categoryLink">
                    Protective Eyewear
                  </a>
                </li>
                <div className="desktop-hrLine"></div>
                <li>
                  <a href="" className="desktop-categoryLink">
                    {" "}
                    Designer Glasses
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    {" "}
                    Best Sellers
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    {" "}
                    New Arrivals
                  </a>
                </li>
              </ul>
            </li>
          </Col>
          <Col>
            <li className="desktop-oddColumnContent">
              <ul className="desktop-navBlock">
                <li>
                  <a href="" className="desktop-categoryName">
                    Girls
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Shop All Glasses
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Little Kids
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Pre-Teens
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Teens
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    {" "}
                    Sunglasses
                  </a>
                </li>

                <li>
                  <a href="" className="desktop-categoryLink">
                    Eye Glasses
                  </a>
                </li>
                <div className="desktop-hrLine"></div>
                <li>
                  <a href="" className="desktop-categoryLink">
                    {" "}
                    Computer Glasses
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    {" "}
                    Protective Eyewear
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    {" "}
                    Best Sellers
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    {" "}
                    New Arrivals
                  </a>
                </li>
              </ul>
            </li>
          </Col>
          <Col>
            <li className="desktop-oddColumnContent">
              <ul className="desktop-navBlock">
                <li>
                  <a href="" className="desktop-categoryName">
                    Shop By Face Shape
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Oval
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Square
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Diamond
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Round
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    {" "}
                    Heart
                  </a>
                </li>

                <li>
                  <a href="" className="desktop-categoryLink">
                    Triangle
                  </a>
                </li>
                <div className="desktop-hrLine"></div>
              </ul>
            </li>
          </Col>
          <Col>
            <li className="desktop-oddColumnContent">
              <ul className="desktop-navBlock">
                <li>
                  <a href="" className="desktop-categoryName">
                    Shop By Color
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Tortoise
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Black
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Clear
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Gold
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    {" "}
                    Brown
                  </a>
                </li>

                <li>
                  <a href="" className="desktop-categoryLink">
                    Blue
                  </a>
                </li>
                <div className="desktop-hrLine"></div>
                <li>
                  <a href="" className="desktop-categoryLink">
                    {" "}
                    Silver
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Blue
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Gray
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Pink
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    View all
                  </a>
                </li>
              </ul>
            </li>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default Kidsmegamenu;
