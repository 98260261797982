import { Select } from "antd";
import React, { useEffect, useState } from "react";
import PriceComponent from "../PriceComponent/PriceComponent";
import RatingComponent from "../RatingComponent/RatingComponent";
import StyledProductDetail from "./StyledProductDetail";
import SizeComponent from "../SizeComponent/SizeComponent";
import StyledColorFilterComponent from "../../../../ListingMainPage/Components/StyledColorFilterComponent";
import ColorComponent from "../../../ProductPage/Components/ColorComponent/ColorComponent";
import { Button } from "antd";
import bag1 from "../../../../../assets/uploadImage/bag.svg";
import love from "../../../../../assets/uploadImage/love.svg";
import { httpdelete, httpGet, httpPost } from "../../../../../networkutils/networkutils";
import Title from "antd/lib/typography/Title";
import { signin, ValidateOtp } from "../../../../../Firebase/auth";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setdataProduct, setcartproducts, setcartproductsatindex } from "../../../../../redux-tool/features/product-features";
import { auth } from "../../../../../Firebase/Config";
import produce from "immer";

const ProductDetailComponent = (props) => {
  var productData = useSelector(
    (state) => state.setproductData.productData
  );
  var cartItems = useSelector((state) => state.setproductData.cartProducts);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.data != undefined) {
    }
  }, [])

  const cartitemText = () => {
    var items = cartItems.filter((va) => va["productID"] === props.productID);
    if (items.length !== 0) {
      return "REMOVE FROM BAG";
    } else {
      return "ADD TO BAG";
    }
  }
  const getproducts = () => {
    httpGet("cart/getproducts").then((res) => {
      if (res["status"] === "SUCCESS") {
        dispatch(setcartproducts(res["data"]))
      }
    })
  }
  const { Option } = Select;
  return (
    <StyledProductDetail>

      <h1 className="product_title">{productData.product_name}</h1>
      <p className="product_para">
        {productData.sub_title}
      </p>
      {/* <div className="rating_component">
        <RatingComponent />
      </div> */}

      <PriceComponent />

      <SizeComponent />
      <ColorComponent />
      <div className="button_group">
        <Button
          className="button1"
          type="primary"
        >
          <img src={love} />
          <div className="button_text">WISH LIST</div>
        </Button>
        <Button
          className="button2"
          type="primary"
          style={{
            
          }}
          onClick={() => {

            if (!auth.currentUser) {
              var item = cartItems.findIndex((val) => val["productID"] === props.productID);
              if (item !== -1) {
                var nextitem = produce(cartItems, (draftstat) => {
                  draftstat.splice(item, 1);
                });
                dispatch(setcartproducts(nextitem));
              } else {
                var nextitem = produce(cartItems, (draftstat) => {
                  draftstat.push({
                    ...productData,
                    size: productData["sizes"][0],
                    color_code: productData["colors"][0]["code"],
                    productID: props.productID,
                    cart_quantity: 1,
                  });
                });
                dispatch(setcartproducts(nextitem));
              }

            } else {
              var items = cartItems.filter((va) => va["productID"] === props.productID);
              if (items.length === 0) {
                const body = {
                  productID: props.productID,
                  quantity: 1
                };
                httpPost("cart/add", body).then((res) => {
                  if (res["status"] === "SUCCESS") {
                    getproducts();
                  }
                })
              } else {
                httpdelete("cart/removeitem", { cartID: items[0]["cart_id"] }).then((res) => {
                  if (res["status"] === "SUCCESS") {
                    getproducts();
                  }
                })
              }

            }
          }}
        >
          <img src={bag1} />
          <div className="button_text">{cartitemText()}</div>
        </Button>
      </div>
    </StyledProductDetail>
  );
};

export default ProductDetailComponent;
