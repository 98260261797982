import React from "react";
import { Routes, Route, Redirect, Navigate } from "react-router-dom";
import HomeScreen from "./Screens/HomeScreen/HomeScreen";
import "./App.less";
import ListingMainPage from "./Screens/ListingMainPage/ListingMainPage";

import "antd/dist/antd.css";
import MainPage from "./Screens/MainPage/MainPage";
import ListingPage from "./Screens/ListingPage/ListingPage";
import ListingWomenpage from "./Screens/ListingWomen/ListingWomnepage";
import ListingKidspage from "./Screens/ListingKids/ListingKidsPage";
import ListingMenpage from "./Screens/ListingMen/ListingMen";
import ProductPage from "./Screens/MainPage/ProductPage/ProductPage";
import TakeQuiz from "./Screens/TakeQuiz/TakeQuiz";
import CartScreen from "./Screens/CartScreen/CartScreen";
import MyOrderScreen from "./Screens/MyOrderScreen/MyOrderscreen";
import ProfileScreen from "./Screens/ProfileScreen/ProfileScreen";
import CheckoutScreen from "./Screens/CheckoutScreen/CheckoutScreen";
import WishListScreen from "./Screens/WishListScreen/WIshListScreen";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route index element={<HomeScreen />} />
        <Route path="/" element={<HomeScreen />}>
          <Route path="home" element={<MainPage />} />
          <Route path="listing" element={<ListingMainPage />}>
            <Route path="main" element={<ListingPage />} />
            <Route path="women" element={<ListingWomenpage />} />
            <Route path="kids" element={<ListingKidspage />} />
            <Route path="men" element={<ListingMenpage />} />
          </Route>
          <Route path="/quiz" element={<TakeQuiz />} />
          <Route path="/cart" element={<CartScreen />} />
          <Route path="/myorders" element={<MyOrderScreen />} />
          <Route path="product" element={<ProductPage />} />
          <Route path="/profile" element={<ProfileScreen />} />
          <Route path="/checkout" element={<CheckoutScreen />} />
          <Route path="/wishlist" element={<WishListScreen />} />
        </Route>
        <Route
          path="*"
          element={<Navigate to="/home" />}
        />    
      </Routes>
    </>
  );
}

export default App;
