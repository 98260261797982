import { Breadcrumb, Col, Divider, Row, Button, Modal, Spin, notification, Input, Select } from "antd";
import Title from "antd/lib/skeleton/Title";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signin, ValidateOtp } from "../../Firebase/auth";
import { auth } from "../../Firebase/Config";
import { httpGet, httpPost } from "../../networkutils/networkutils";
import { setcartproducts } from "../../redux-tool/features/product-features";
import MainCart from "./Components/MainCart";
import StyledCartScreen from "./StyledCartScreen";

const CartScreen = (props) => {
  const [visible, setVisible] = useState(false);
  const [spinning, setspinning] = useState(false);
  const [chekoutspinning, setchekoutspinning] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getcartproducts = useSelector((state) => state.setproductData.cartProducts);

  const [countrycode, setcountryCode] = useState("+91");
  const [mobilenumber, setmobilenumber] = useState("");
  const [showotp, setshowotp] = useState(false);
  const [otp, setotp] = useState("");
  const [fireresult, setfireresult] = useState("");
  const { Option } = Select;

  useEffect(() => {
    if (!auth.currentUser) {

    } else {
      getproducts();
    }
  }, [])
  const getproducts = () => {
    httpGet("cart/getproducts").then((res) => {
      if (res["status"] === "SUCCESS") {
        dispatch(setcartproducts(res["data"]))
      }
    })
  }
  const setproductPrice = () => {
    var totlprice = 0;
    getcartproducts.map((value) => {
      if (value["cart_quantity"]) {
        totlprice += parseFloat(value["selling_price"]) * value["cart_quantity"];
      }
    });
    return totlprice;
  }
  const setdiscountPrice = () => {
    var totlprice = 0;
    getcartproducts.map((value) => {
      if (value["cart_quantity"]) {
        totlprice += (parseFloat(value["product_price"]) - parseFloat(value["selling_price"])) * value["cart_quantity"];
      }
    });
    return totlprice;
  }
  const handleOk = (e) => {
    setVisible(false);
  };
  const extractids = () => {
    return new Promise((resolve, reject) => {
      var items = [];
      getcartproducts.forEach((value, index) => {
        items.push({
          id: value["productID"],
          qty: value["cart_quantity"]
        });
        if (index === getcartproducts.length - 1) resolve(items);
      });
    })
  }
  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: message,
      description:
        '',
    });
  };

  const handleCancel = (e) => {
    setVisible(false);
  }; const { TextArea } = Input;
  return (
    <StyledCartScreen>
      <Modal
        title=""
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ disabled: true }}
        cancelButtonProps={{ disabled: true }}
        footer={null}
        width={500}
      >
        <Spin spinning={spinning} tip="Loading...">
          <div className="login-modal">
            <Row justify="center">
              <Col xs={20}>
                <Title className="modal-title" level={2} style={{ textAlign: "center", marginBottom: "1rem !important" }}>Sign up</Title>
                <br />
                <Input.Group compact>
                  <Select onSelect={(e) => {
                    setcountryCode(e);
                  }} defaultValue={countrycode}>
                    <Option value="+91">+91</Option>
                    <Option value="+971">+971</Option>
                  </Select>

                  <Input onChange={(e) => {
                    setmobilenumber(e.target.value)
                  }} style={{ width: '80%' }} defaultValue="" placeholder="Phone number" />
                </Input.Group>
                <br />
                {showotp ? <Input placeholder="OTP" onChange={(e) => {
                  setotp(e.target.value)
                }} style={{ width: '100%' }} defaultValue="" /> : null}
                <div id="recaptcha-container"></div>
                <Button
                  onClick={() => {
                    setspinning(true);
                    if (showotp) {
                      ValidateOtp(fireresult, otp).then((res) => {
                        setspinning(false);
                        localStorage.setItem('token', res.user.uid);
                        setVisible(false);
                        openNotificationWithIcon("success", 'Successfully verified');
                        extractids().then((data) => {
                          httpPost("cart/addcartbyID", { items: JSON.stringify(data) }).then((res) => {
                            if (res["status"] === "SUCCESS") {
                              localStorage.removeItem("cart");
                              navigate("/checkout")
                            }
                          })
                        })
                      }).catch(() => {
                        setspinning(false);
                        openNotificationWithIcon("Error", "Not able to verify");
                      })
                    } else {
                      signin(countrycode + mobilenumber).then((result) => {
                        setfireresult(result);
                        setshowotp(true);
                        setspinning(false);
                      }).catch(() => {
                        setspinning(false);
                        openNotificationWithIcon("Error", "Not able to verify");
                      });
                    }

                  }}
                  style={{ width: "100%", marginTop: "1rem", backgroundColor: "#1A1A1A", color: "#fff" }}
                >{showotp ? "VERIFY" : "SEND OTP"}</Button>

              </Col>
            </Row>
          </div>
        </Spin>
      </Modal>
      <div className="container">
        <div className="row" style={{ padding: "1rem 2rem" }}>
          <div className="col" xs={24}>
            {" "}
            <Row>
              <Col xs={24} style={{ padding: "1rem 0" }}>
                {" "}
                <Breadcrumb style={{ marginLeft: "4%" }}>
                  <Breadcrumb.Item>
                    <a href="/home"> Home</a>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <a href="/listing/main"> Listing</a>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    Cart Page
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
          </div>
          <Divider style={{ margin: 0 }} />
        </div>
      </div>
      <div className="container">
        <Spin spinning={chekoutspinning} tip="Loading...">
          <div className="row" style={{ padding: "2rem 0 " }} >
            <div
              className="col-md-7"
              style={{
                border: "1px solid #00000017",
                borderRadius: "16px",
              }}
              span={12}
            >
              {getcartproducts.map((value, index) => {
                return <MainCart data={value} index={index} />;
              })}
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-4" style={{
              border: "1px solid #00000017",
              borderRadius: "16px",

            }}>
              <div className="row" justify="center" style={{ padding: "10px" }}>
                <div className="col" xs={12}>
                  <p className="chckout-total" style={{ textAlign: "center" }}>
                    Subtotal (1 item)
                  </p>
                </div>
                <div className="col" xs={12}>
                  <p className="chckout-price" style={{ textAlign: "center" }}>AED {setproductPrice()}</p>
                </div>
              </div>
              <div className="row" justify="center">
                <div className="col" xs={12} style={{ padding: "0px 10px" }}>
                  <p className="chckout-total" style={{ textAlign: "center" }}>
                    Discount
                  </p>
                </div>
                <div className="col" xs={12}>
                  <p className="chckout-price" style={{ textAlign: "center" }}>-AED {setdiscountPrice()}</p>
                </div>
              </div>
              <div className="row justify-content-center">

                <Button
                  className="checkout-btn"
                  onClick={() => {
                    if (getcartproducts.length !== 0) {
                      navigate("/checkout");
                    }
                  }}
                >
                  Proceed to Checkout
                </Button>
              </div>
            </div>
          </div>
        </Spin>
      </div>
    </StyledCartScreen>
  );
};

export default CartScreen;
