import styled from "styled-components";

const StyleBannerColumn = styled.div`
  .text-banner {
    background: #f6f6f6;
    width: 100%;
    height: 100%;
    div {
      padding: 5rem 2rem;
      text-align: start;
      h2 {
        color: #404040;
        font-size: 35px;
        font-weight: 400;
      }
      p {
        padding-top: 18px;
        margin: 0;
        color: #404040;
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
`;
export default StyleBannerColumn;
