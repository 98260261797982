import { auth } from "../Firebase/Config";

const baseUrl = "https://api.kareye.com/v1/";
// const baseUrl = "http://localhost:8000/v1/";
export async function httpGet(api) {
  const token = auth.currentUser?.uid;
  return new Promise((resolve, reject) => {
    fetch(baseUrl + api, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token ?? ""}`,
      },
    })
      .then((response) => resolve(response.json()))
      .catch(reject);
  });
}

export async function httpPost(api, body) {
  const token = auth.currentUser?.uid;
  return new Promise((resolve, reject) => {
    fetch(baseUrl + api, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token ?? ""}`,
      },
    })
      .then((response) => resolve(response.json()))
      .catch(reject);
  });
}
export async function httpdelete(api, body) {
  const token = auth.currentUser?.uid;
  return new Promise((resolve, reject) => {
    fetch(baseUrl + api, {
      method: "DELETE",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token ?? ""}`,
      },
    })
      .then((response) => resolve(response.json()))
      .catch(reject);
  });
}
