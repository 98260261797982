import styled from "styled-components";

const StyledTextUpload = styled.div`
  .upload_text {
    padding: 1rem 1rem 0 !important;
  }
  h2 {
    font-size: 20px;
    color: #404040;
    font-weight: 500;
    line-height: 30px;
  }
  p {
    font-size: 15px;
    color: #404040;
    font-weight: 400;
    margin-bottom: 0px;
  }
  .sign-here {
    cursor: pointer;
    font-size: 12px;
    color: #0066ff;
    padding: 0rem 1rem;
  }
`;
export default StyledTextUpload;
