import styled from "styled-components";

const StyleKidsCategory = styled.div`
  height: 100%;
  width: 100%;
  background: #f6f6f6;
  .column-grid {
    margin: 2rem 1.2rem;
    h2 {
      margin-top: 1rem !important;
      color: #404040;
      font-size: 23px;
      font-weight: 400;
      text-align: center;
      text-transform: uppercase;
    }
    p {
      color: #404040;
      font-size: 15px;
      text-align: center;
      margin-bottom: 0px;
    }
    .messure {
      color: #8e8e8e;
      font-size: 12px;
      text-align: center;
      margin-bottom: 0px;
    }
    .shop-now {
      text-align: center;
      padding: 0.5rem 0.9rem;
      background: #404040;
      margin: 0.3rem 3rem;
      color: #fff;
      cursor: pointer;
    }
  }
`;
export default StyleKidsCategory;
