import { Col, Row } from "antd";
import { Link } from "react-router-dom";

const SunGlassmegamenu = () => {
  return (
    <div className="desktop-backdropStyle">
      <div className="desktop-paneContent">
        <Row>
          <Col>
            <li className="desktop-oddColumnContent">
              <ul className="desktop-navBlock">
                <li>
                  <Link to="/listing/women" className="desktop-categoryName">
                    Women
                  </Link>
                </li>
                <li>
                  <Link to="/listing/women" className="desktop-categoryLink">
                    Shop All Glasses
                  </Link>
                </li>
                <li>
                  <Link to="/listing/women" className="desktop-categoryLink">
                    Best Sellers
                  </Link>
                </li>
                <li>
                  <Link to="/listing/women" className="desktop-categoryLink">
                    Prescription Sunglasses
                  </Link>
                </li>
                <li>
                  <Link to="/listing/women" className="desktop-categoryLink">
                    Designer Sunglasses
                  </Link>
                </li>
                <li>
                  <Link to="/listing/women" className="desktop-categoryLink">
                    {" "}
                    Clearance
                  </Link>
                </li>

                <li>
                  <a href="" className="desktop-categoryLink">
                    Girls' Eyeglasses
                  </a>
                </li>
              </ul>
            </li>
          </Col>
          <Col>
            <li className="desktop-oddColumnContent">
              <ul className="desktop-navBlock">
                <li>
                  <a href="" className="desktop-categoryName">
                    Men
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Shop All Sunglasses
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Best Sellers
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Prescription Sunglasses
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Designer Sunglasses
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    {" "}
                    Clearance
                  </a>
                </li>

                <li>
                  <a href="" className="desktop-categoryLink">
                    Boys' Eyeglasses
                  </a>
                </li>
              </ul>
            </li>
          </Col>
          <Col>
            <li className="desktop-oddColumnContent">
              <ul className="desktop-navBlock">
                <li>
                  <a href="" className="desktop-categoryName">
                    Special Lenses
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Polarized
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Clip-Ons
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Multifocal
                  </a>
                </li>
                <div className="desktop-hrLine"></div>
              </ul>
            </li>
          </Col>
          <Col>
            <li className="desktop-oddColumnContent">
              <ul className="desktop-navBlock">
                <li>
                  <a href="" className="desktop-categoryName">
                    Featured Categories
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Fashion and Trends
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Kids' Glasses
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Sports Glasses
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Safety Glasses
                  </a>
                </li>
              </ul>
            </li>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default SunGlassmegamenu;
