import React from "react";
import { Link } from "react-router-dom";
import StyledColorComponent from "./StyledColorsComponent";
import { Grid } from "@mui/material";
const ColorComponent = () => {
  return (
    <StyledColorComponent>
      <Grid container>
        <Grid item xs={3}>
          <Link to="/listing/main">
            <div className="color-col black"></div>
            <p className="color-title">Black</p>
          </Link>
        </Grid>
        <Grid xs={3}>
          <Link to="/listing/main">
            <div className="color-col">
              <img
                height="50px"
                src="https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/tortoiseshell.png?alt=media&token=15835574-803b-49cf-a309-370ddb7705b6"
              />
            </div>
            <p className="color-title">Tortoiseshell</p>
          </Link>
        </Grid>
        <Grid xs={3}>
          <Link to="/listing/main">
            <div className="color-col blue"></div>
            <p className="color-title">Blue</p>
          </Link>
        </Grid>
        <Grid xs={3}>
          <div className="color-col green"></div>
          <p className="color-title">Green</p>
        </Grid>
      </Grid>
      <Grid container className=" group-row">
        <Grid item xs={3}>
          <div className="color-col red"></div>
          <p className="color-title">Red</p>
        </Grid>
        <Grid item xs={3}>
          <div className="color-col clear"></div>
          <p className="color-title">Clear</p>
        </Grid>
        <Grid item xs={3}>
          <div className="color-col pink"></div>
          <p className="color-title">Pink</p>
        </Grid>
        <Grid item xs={3}>
          <div className="color-col gold"></div>
          <p className="color-title">Gold</p>
        </Grid>
      </Grid>
      <div className="row">
        <div className="shop-app-btn">Shop all</div>
      </div>
    </StyledColorComponent>
  );
};

export default ColorComponent;
