import styled from "styled-components";

const StyledDiscoverMore = styled.div`
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  h1{
    font-size: 30px;
    color: #404040;
    font-weight: 400;
}
  }
`;

export default StyledDiscoverMore;
