import { Row } from "antd";
import React from "react";
import StyledFaceComponent from "./StyledFaceComponent";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";

const FaceComponent = () => {
  const faceShapes = [
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/fcs_oval.png?alt=media&token=af65d5b5-8b1c-46e1-a0ad-3b16617b0725",
      title: "Oval",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/fcs_square.png?alt=media&token=af65d5b5-8b1c-46e1-a0ad-3b16617b0725",
      title: "Square",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/fcs_diamond.png?alt=media&token=af65d5b5-8b1c-46e1-a0ad-3b16617b0725",
      title: "Diamond",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/fcs_round.png?alt=media&token=af65d5b5-8b1c-46e1-a0ad-3b16617b0725",
      title: "Round",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/fcs_heart.png?alt=media&token=af65d5b5-8b1c-46e1-a0ad-3b16617b0725",
      title: "Heart",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/fcs_traingle.png?alt=media&token=af65d5b5-8b1c-46e1-a0ad-3b16617b0725",
      title: "Triangle",
    },
  ];
  return (
    <StyledFaceComponent>
      <>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={4}>
            <Link to="/listing/main">
              <div className="face_img">
                <img src={faceShapes[0]["image"]} alt="" />
                <h6 className="text">{faceShapes[0]["title"]}</h6>
              </div>
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link to="/listing/main">
              <div className="face_img">
                <img src={faceShapes[1]["image"]} alt="" />
                <h6 className="text">{faceShapes[1]["title"]}</h6>
              </div>
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link to="/listing/main">
              <div className="face_img">
                <img src={faceShapes[2]["image"]} />

                <h6 className="text">{faceShapes[2]["title"]}</h6>
              </div>
            </Link>
          </Grid>
        </Grid>
        <Grid className=" row face_row">
          <Grid item xs={4}>
            <Link to="/listing/main">
              <div className="face_img">
                <img src={faceShapes[3]["image"]} alt="" />

                <h6 className="text">{faceShapes[3]["title"]}</h6>
              </div>
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link to="/listing/main">
              <div className="face_img">
                <img src={faceShapes[4]["image"]} alt="" />
                <h6 className="text">{faceShapes[4]["title"]}</h6>
              </div>
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link to="/listing/main">
              <div className="face_img">
                <img src={faceShapes[5]["image"]} alt="" />
                <h6 className="text">{faceShapes[5]["title"]}</h6>
              </div>
            </Link>
          </Grid>
        </Grid>
      </>
      {/* <Grid container>
        <Grid xs={1}></Grid>
        <Grid item xs={10}>
          <p className="last-row-text">
            Don’t know your face shape? <span> Take the quiz </span>
          </p>
        </Grid>
      </Grid> */}
    </StyledFaceComponent>
  );
};

export default FaceComponent;
