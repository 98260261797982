import styled from "styled-components";

const StyledColorFilterComponent = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 100%;
  margin-top: 0.2rem;
  background-color: ${({ bg }) => bg};
  cursor: pointer;
`;

export default StyledColorFilterComponent;
