import React from "react";
import StyledBrandCard from "./StyledBrandCard";
import "../../../styles/brandrowStyle.css";
import { Link } from "react-router-dom";

const BrandCard = ({ img }) => {
  return (
    <StyledBrandCard>
      {img ? (
        <img src={img} />
      ) : (
        <h6 className="shop_text">
          <Link to="/listing/main"> Shop All</Link>
        </h6>
      )}
    </StyledBrandCard>
  );
};

export default BrandCard;
