import styled from "styled-components";

const StyledUploadImage = styled.div`
  /* border: 1px solid red; */
  display: flex;
  justify-content: flex-start;
  .upload-img {
    width: 140px;
    padding: 1rem 0.4rem;
    text-align: center;
  }
  .upload-image-sec {
    cursor:pointer;
    margin: 1rem 0.4rem;
    width: 110px;
    height: 150px;
    background: #d0d0d050;
    // opacity: 0.5;
    border-radius: 10px;
  }
  .upload-circle {
    height: 50px;
    width: 50px;
    background: #0066ff;
    opacity: 1;
    margin: 2rem auto 0;
    border-radius: 25px;
  }
  .upload-text{
    margin 1px 0px;
    color:#0066FF;
    font-size:14px;
    font-weight:500;
    text-align: center;
  }
`;

export default StyledUploadImage;
