import React from "react";
import StyledPriceDetailComponent from "../PriceComponent/StyledPriceDetailComponent";
import { useSelector } from "react-redux";

const PriceComponent = (props) => {
  const productData = useSelector(
    (state) => state.setproductData.productData
  );
  return (
    <StyledPriceDetailComponent>
      <div className="price_sec">
        <div className="price_details">
          <h3>AED {productData.selling_price}</h3>{" "}
          <strike className="regular_price">
            <h6>AED {productData.product_price}</h6>
          </strike>
          <h5 className="discount_price">(25% OFF)</h5>
        </div>
        <h4 className="tax_text"> Inclusive of all Taxes</h4>
      </div>
    </StyledPriceDetailComponent>
  );
};

export default PriceComponent;
