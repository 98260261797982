import styled from "styled-components";

const StyledQuizCardComponent = styled.div`
  padding: 4rem 6rem;
  /* border: 1px solid red; */

  margin: 0 10px;
  box-shadow: rgb(0 0 0 / 6%) 0px 4px 10px 0px;
  border-radius: 14px;
  h3 {
    font-size: 20px;
  }
`;

export default StyledQuizCardComponent;
