import { Grid } from "@mui/material";
import { Col, Row } from "antd";
import StyleBannerColumn from "./components/StylebannerColumn";
import StyleKidsCategory from "./components/StyleKidsCategory";

const ListingKidspage = () => {
  const kidscategories = [
    {
      id: "1",
      title: "TODDLERS AGE 0-3",
      image:
        "https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/kid1.png?alt=media&token=20e03d01-60c1-4b5b-9e3f-af1340164551",
      desc: "Extra Small Frames",
      meassurement: "100-114 mm",
      btn: "SHOP TODDLER",
    },
    {
      id: "1",
      title: "LITTLE KIDS AGE 4-7",
      image:
        "https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/kid2.png?alt=media&token=20e03d01-60c1-4b5b-9e3f-af1340164551",
      desc: "Small Frames",
      meassurement: "115-119 mm",
      btn: "SHOP LITTLE KIDS",
    },
    {
      id: "1",
      title: "PRE-TEEN AGE 8-12",
      image:
        "https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/kid3.png?alt=media&token=20e03d01-60c1-4b5b-9e3f-af1340164551",
      desc: "Medium Frames",
      meassurement: "120-129 mm",
      btn: "SHOP PRE-TEEN",
    },
    {
      id: "1",
      title: "TEEN AGE 13+",
      image:
        "https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/kid4.png?alt=media&token=20e03d01-60c1-4b5b-9e3f-af1340164551",
      desc: "Large Frames",
      meassurement: "130+ mm",
      btn: "SHOP TEEN",
    },
  ];

  return (
    <>
      <StyleBannerColumn>
        {window.screen.width > 900 ?
          <Row>
            <Col xs={12}>
              <div className="text-banner">
                <div>
                  <h2>Kid’s Glasses</h2>
                  <p>
                    Kids are tough on glasses because, well, kids are kids. That’s
                    why we offer the same high-quality, durable kids glasses and
                    protective lenses that you’d get from an optician for 1/10th
                    the price.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={12}>
              <img
                width="100%"
                src="https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/listing_kids_banner.png?alt=media&token=738852a2-bdb8-4589-bfc5-02ef3e1f8f92"
              />
            </Col>
          </Row> : <Grid container spacing={1} >
            <Grid item  xs={12}>
            <Grid item xs={12}>
              <img
              style={{marginTop:"3.5rem"}}
                width="100%"
                src="https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/listing_kids_banner.png?alt=media&token=738852a2-bdb8-4589-bfc5-02ef3e1f8f92"
              />
            </Grid>
              <div className="text-banner">
                <div>
                  <h2>Kid’s Glasses</h2>
                  <p>
                    Kids are tough on glasses because, well, kids are kids. That’s
                    why we offer the same high-quality, durable kids glasses and
                    protective lenses that you’d get from an optician for 1/10th
                    the price.
                  </p>
                </div>
              </div>
            </Grid>
            
          </Grid>}
      </StyleBannerColumn>
      <StyleKidsCategory>
       {window.screen.width > 900 ?
         <Row style={{ marginTop: "3rem" }}>
         {kidscategories.map((data) => {
           return (
             <Col xs={6}>
               <div className="column-grid">
                 <img width="100%" src={data.image} />
                 <h2>{data.title}</h2>
                 <p>{data.desc}</p>
                 <p className="messure">{data.meassurement}</p>
                 <div className="shop-now">{data.btn}</div>
               </div>
             </Col>
           );
         })}
       </Row>:  <Grid container style={{ marginTop: "3rem" }}>
          {kidscategories.map((data) => {
            return (
              <Grid item xs={6}>
                <div className="column-grid">
                  <img width="100%" src={data.image} />
                  <h2>{data.title}</h2>
                  <p>{data.desc}</p>
                  <p className="messure">{data.meassurement}</p>
                  <div className="shop-now">{data.btn}</div>
                </div>
              </Grid>
            );
          })}
        </Grid>}
      </StyleKidsCategory>
    </>
  );
};
export default ListingKidspage;
