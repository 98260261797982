import styled from "styled-components";

const StyledBanner = styled.div`
  margin-top: 2rem;
  .ad-banner2 {
    position: relative;
  }
  .ad-container {
    top: 5%;
    right: 5%;
    width: 30%;
    padding-bottom: 1rem;
    position: absolute;
    background: #f6f6f6;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
  }
  @media screen and (min-width: 900px) {
    .ad-container {
      top: 0%;
      margin-top: 4%;
      height: 82%;
    }
  }
  .ad-container-title {
    font-size: 28px;
    font-weight: 400;
    color: #404040;
    text-align: center;
  }
  @media screen and (min-width: 900px) {
    .ad-container-title {
      padding-left: 2.5rem !important;
      padding-top: 20px !important;
      text-align: left;
    }
  }
  .ad-container-title-mob {
    font-size: 26px;
    font-weight: 500;
    color: #404040;
    text-align: center;
    margin-bottom: 20px !important;
  }
  .ad-caption-mob {
    padding-left: 10px;
  }
  .get-connect-whatsapp-btn {
    width: 100%;
    margin-top: 20px;
    border-radius: 13px;
    background-color: #404040;
    color: white;
    font-size: 16px;
    padding: 1rem 1rem;
    text-transform: uppercase;
    cursor: pointer;
    text-align: center;
  }
  .divider {
    height: 2px;
    margin: 12px 0 0 0px;
    background-color: #eaeaec;
  }
  .point-row {
    margin-top: 10px;
  }
  .point-icon {
    margin: 3% auto;
    height: 50px;
    width: 50px;
    border-radius: 30px;
    background: #fff;
    img {
      padding: 15px;
    }
  }
  .grid-title {
    color: #404040;
    font-weight: 500;
    font-size: 20px;
  }
  .grid-title2 {
    color: #404040;
    font-size: 16px;
  }
`;
export default StyledBanner;
