import React, { useState } from "react";
import { Button, Col, Modal, Row } from "antd";
import tick from "../../../../assets/uploadImage/tick.svg";
import camera from "../../../../assets/uploadImage/Camera.svg";
import StyledProductCard from "./StyledProductCard";
import { useNavigate } from "react-router";
import ProductDetailComponent from "../../../MainPage/ProductPage/Components/ProductDetailComponent/ProductDetailComponent";
import SingleProductImage from "../../../MainPage/ProductPage/Components/SingleProductImage/SingleProductImage";
import ProductImageGrid from "../../../MainPage/ProductPage/Components/productImageGrid/ProductImageGrid";
import {
  httpdelete,
  httpGet,
  httpPost,
} from "../../../../networkutils/networkutils";
import { useDispatch, useSelector } from "react-redux";
import {
  removeWishlist,
  setdataProduct,
  setshowLogin,
  setwishlist,
} from "../../../../redux-tool/features/product-features";
import { auth } from "../../../../Firebase/Config";
import LoginModal from "../../../../Components/LoginModal/LoginModal";

const ProductCard = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [Img, setImg] = useState();
  const [productData, setproductData] = useState({
    product_details: [],
    frame_measurment: [],
    specification: [],
    sizes: [],
    colors: [],
    images: [],
  });
  const wishlistItems = useSelector((state) => state.setproductData.wishlist);
  const [visible, setVisible] = useState();

  const showModal = () => {
    getproductData(props.payload.id);
    setVisible(true);
  };
  const getproductData = (id) => {
    httpGet(`listing/getproductData?id=${id}`).then((res) => {
      if (res["status"] === "SUCCESS") {
        dispatch(setdataProduct(res["data"]));
        setproductData(res["data"]);
      }
    });
  };
  const handleOk = (e) => {
    setVisible(false);
  };

  const handleCancel = (e) => {
    setVisible(false);
  };
  const navigatetoProduct = (id) => {
    navigate("/product", { state: id });
  };
  const wishlistbtntext = () => {
    if (wishlistItems) {
      var items = wishlistItems.map(
        (val) => val["product_id"] === props.payload.id
      );
      if (items.length === 0) {
        return "Add to wishlist";
      } else {
        return "Remove from wishlist";
      }
    }
  };
  const addtowishlist = () => {
    const body = {
      product_id: props.payload.id,
    };
    httpPost("wishlist/add", body).then((res) => {
      if (res["status"] === "SUCCESS") {
        httpGet("wishlist/get").then((wsres) => {
          if (wsres["status"] === "SUCCESS") {
            dispatch(setwishlist(wsres["data"]));
          }
        });
      }
    });
  };
  const removewishlist = (id, index) => {
    httpdelete(`wishlist/remove?wishlistID=${id}`).then((res) => {
      if (res["status"] === "SUCCESS") {
        var payload = {
          index: index,
        };
        dispatch(removeWishlist(payload));
      }
    });
  };

  return (
    <StyledProductCard>
      <LoginModal productID={props.payload.id} />
      <Modal
        title=""
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ disabled: true }}
        cancelButtonProps={{ disabled: true }}
        footer={null}
        width={1200}
      >
        <div style={{ padding: "2rem 1rem" }}>
          <Row>
            <Col xs={2}>
              <ProductImageGrid
                setImg={setImg}
                Img={Img}
                arrayImg={productData["images"]}
              />
            </Col>
            <Col xs={14}>
              <SingleProductImage Img={Img ?? productData["primary_image"]} />
              <Row justify="center">
                <Col
                  style={{
                    display: "flex",
                    padding: "1rem 0",
                  }}
                  xs={12}
                >
                  <Button
                    className="button1"
                    type="primary"
                    style={{
                      fontSize: "13.93px",
                      backgroundColor: "white",
                      color: "#0066FF",
                      borderRadius: "7.74px",
                      marginRight: "1rem",
                      display: "flex",
                      alignItems: "center",
                      border: "0.232161px solid #8e8e8e5c",
                    }}
                  >
                    <img width="15" src={tick} />
                    <div style={{ marginLeft: "3px" }} className="button_text">
                      CHOOSE LENSES
                    </div>
                  </Button>
                  <Button
                    className="button1"
                    type="primary"
                    style={{
                      fontSize: "13.93px",
                      backgroundColor: "white",
                      color: "#8E8E8E",
                      borderRadius: "7.74px",
                      marginLeft: "1rem",
                      display: "flex",
                      alignItems: "center",
                      border: "0.232161px solid #8e8e8e5c",
                    }}
                  >
                    <img src={camera} />
                    <div style={{ marginLeft: "3px" }} className="button_text">
                      TRY ONLINE
                    </div>
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col xs={8}>
              <ProductDetailComponent productID={props.payload.id} />
            </Col>
          </Row>
        </div>
      </Modal>
      <div className="product_card">
        <div>
          <div className="love_image">
            <div onClick={showModal} className="quick-view-sec quick-view-hide">
              Quick View
            </div>
            <div
              onClick={() => {
                if (!auth.currentUser) {
                  dispatch(setshowLogin(true));
                } else {
                  var items = wishlistItems.filter(
                    (val) => val["product_id"] === props.payload.id
                  );
                  if (items.length === 0) {
                    addtowishlist();
                  } else {
                    var index = wishlistItems.findIndex(
                      (val) => val["product_id"] === props.payload.id
                    );
                    removewishlist(items[0]["id"], index);
                  }
                }
              }}
              style={{ backgroundColor: "#404040" }}
              className="quick-view-sec quick-view-hide"
            >
              {wishlistbtntext()}
            </div>
          </div>

          <div
            onClick={() => {
              navigatetoProduct(props.payload.id);
            }}
            className="product_image"
          >
            <img width="100%" src={props.payload.primary_image} />
          </div>
        </div>
        <div
          onClick={() => {
            navigatetoProduct(props.payload.id);
          }}
        >
          <h3 className="title">{props.payload.name}</h3>
          <p className="description">{props.payload.sub_title}</p>
          <h3 className=" price">AED {props.payload.selling_price}</h3>
          <h3 className=" discount">
            AED {props.payload.product_price}/-{" "}
            <span style={{ color: "red", fontWeight: 400 }}>(46%off)</span>
          </h3>
        </div>
      </div>
    </StyledProductCard>
  );
};

export default ProductCard;
