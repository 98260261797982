import React from "react";
import StyledEyeWearText from "./StyledEyeWearText";

const EyeWearForEveryone = () => {
  return (
    <StyledEyeWearText>
      {window.screen.width > 900 ? (
        <>
          <h1 className="main_title">Eyewear for Everyone</h1>
          <h1 className="sub_title">
            Get a complete pair of prescription glasses starting at{" "}
            <span>AED 50.00</span>
          </h1>
        </>
      ) : (
        <>
          <h1 className="main_title_mob">Eyewear for Everyone</h1>
          <h1 className="sub_title_mob">
            Get a complete pair of prescription glasses starting at{" "}
            <span>AED 50.00</span>
          </h1>
        </>
      )}
    </StyledEyeWearText>
  );
};

export default EyeWearForEveryone;
