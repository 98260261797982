import { Breadcrumb, Button, Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { httpdelete } from "../../networkutils/networkutils";
import { removeWishlist } from "../../redux-tool/features/product-features";
import StyledWishlistScreen from "./StyledWishlistScreen";

const WishListScreen = () => {
    const wishlistItems = useSelector((state) => state.setproductData.wishlist);
    const dispatch = useDispatch();
    const removewishlist = (id, index) => {
        httpdelete(`wishlist/remove?wishlistID=${id}`).then((res) => {
            if (res["status"] === "SUCCESS") {
                var payload = {
                    index: index
                }
                dispatch(removeWishlist(payload))
            }
        })
    }
    return (
        <StyledWishlistScreen>
            <div className="container">
                <Row>
                    <Col xs={24} style={{ padding: "1rem 0" }}>
                        {" "}
                        <Breadcrumb style={{ marginLeft: "4%" }}>
                            <Breadcrumb.Item>
                                <a href="/home"> Home</a>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <a href="/listing/main"> Listing</a>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                Wish List
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        {
                            wishlistItems.map((value, index) => {
                                return <div className="row justify-content-center">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <img src={value["primary_image"]} width="100%" />
                                        </div>
                                        <div className="col-md-6">
                                            <h3 className="prod_name">{value["name"]}</h3>
                                            <h4 className="prod_price"> AED {value["selling_price"]} </h4><br />
                                            <Button onClick={() => {
                                                removewishlist(value["id"], index);
                                            }}>Remove</Button>
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </div> <br />
                </div>

            </div>
        </StyledWishlistScreen>
    )
}
export default WishListScreen;