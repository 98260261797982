import React from "react";
import { Link } from "react-router-dom";
import StyledGlassType from "./StyledGlassType";

const EyeGlassType = (props) => {
  return (
    <StyledGlassType>
      <div className="glass_img">
        <img src={props.category.image} />
      </div>
      <div className="glases_name">
        <h1 className="glasses_text">
          <Link to="/listing/main">{props.category.title}</Link>
        </h1>
      </div>
    </StyledGlassType>
  );
};

export default EyeGlassType;
