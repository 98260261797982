import { Col, Row } from "antd";
import { Link } from "react-router-dom";

const ContactLensmegamenu = () => {
  return (
    <div className="desktop-backdropStyle">
      <div className="desktop-paneContent">
        <Row>
          <Col>
            <li className="desktop-oddColumnContent">
              <ul className="desktop-navBlock">
                <li>
                  <Link to="/listing/women" className="desktop-categoryName">
                    Lens Types
                  </Link>
                </li>
                <li>
                  <Link to="/listing/women" className="desktop-categoryLink">
                    Shop All Contacts
                  </Link>
                </li>
                <li>
                  <Link to="/listing/women" className="desktop-categoryLink">
                    Daily
                  </Link>
                </li>
                <li>
                  <Link to="/listing/women" className="desktop-categoryLink">
                    Weekly
                  </Link>
                </li>
                <li>
                  <Link to="/listing/women" className="desktop-categoryLink">
                    Monthly
                  </Link>
                </li>
                <li>
                  <Link to="/listing/women" className="desktop-categoryLink">
                    {" "}
                    Single Vision
                  </Link>
                </li>

                <li>
                  <a href="" className="desktop-categoryLink">
                    Multifocal & Bifocal
                  </a>
                </li>
              </ul>
            </li>
          </Col>
          <Col>
            <li className="desktop-oddColumnContent">
              <ul className="desktop-navBlock">
                <li>
                  <a href="" className="desktop-categoryName">
                    Shop By Brand
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Ray-Ban
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Oakley
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    DKNY
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Persol
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    {" "}
                    Prada
                  </a>
                </li>

                <li>
                  <a href="" className="desktop-categoryLink">
                    Versace
                  </a>
                </li>
                <div className="desktop-hrLine"></div>
                <li>
                  <a href="" className="desktop-categoryLink">
                    {" "}
                    Coach
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Gucci
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Calvin Klien
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Vogue
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Shop all
                  </a>
                </li>
              </ul>
            </li>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default ContactLensmegamenu;
