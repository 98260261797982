import styled from "styled-components";


const StyledMyOrder = styled.div `
    padding:2rem 2rem;
    .product_name{
        font-size:18px;
        font-weight:500;
    }
    .product_subtitle{
        font-size:16px;
        font-weight:400;
    }
    .price{
        font-size:18px;
        font-weight:500;
    }
    .order_status{
        font-size:16px;
        font-weight:500;
    }
    .update_date{
        font-size:14px;
        color:#505050;
    }
`;

export default StyledMyOrder;