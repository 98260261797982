import styled from "styled-components";

const StyledProductDetail = styled.div`
  .product_title {
    font-size: 26px;
    line-height: 55.1px;
    font-weight: 400;
    color: #404040;
  }
  .login-modal{
    padding:1rem 2rem;
  }
  .modal-title{
    margin-bottom: 1rem !important;
  }
  .product_para {
    font-size: 16px;
    color: #8e8e8e;
    font-size: 17.03px;
    margin-bottom: 10px;
}
  .rating_component {
    margin-bottom: 1rem;
  }
  .ratings {
    color: #8e8e8e;
    font-size: 12px;
    padding: 3px;
  }
 
  .button_group {
    display: flex;
    padding-top: 0.5rem;
    }
    .button1 {
      display: flex;
      align-items: center;
      border: 0.232161px solid #8e8e8e5c;
      font-size: 13.93px;
      background-color: #fff;
      color: #333;
      border-radius: 7.74px;
      .button_text {
        margin-left: 0.5rem;
      }
    }
    .button2 {
      margin-left: 1rem;
      display: flex;
      align-items: center;
      border:none;
      font-size: 13.93px;
      background-color: #404040;
      border-radius: 7.74px;
      padding: 0  4rem;
      .button_text {
        margin-left: 0.5rem;
      }
    }
    @media screen and (max-width:900px){
      .product_title{
        text-align:center;
      }
      .product_para{
        text-align:center;
      }
      .button2 {
        text-align:center;
        border-radius: 0px;
        position:fixed;
        bottom:0;
        right:0;
        z-index:10;
        width:50%;
       
      }
      .button1 {
        border-radius: 0px;
        position:fixed;
        bottom:0;
        left:0;
        z-index:10;
        width:50%;
      }
    }
`;

export default StyledProductDetail;
