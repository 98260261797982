import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import "../../../styles/CarouselStyles.css";
import "swiper/css";

// Import Swiper styles
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
import { Link } from "react-router-dom";

const Carousel = (props) => {
  return (
    <div className="banner">
      <Swiper
        slidesPerView={1}
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        // onSlideChange={() => console.log("slide change")}
        // onSwiper={(swiper) => console.log(swiper)}
        pagination={{ clickable: true }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
      >
        {props.data.map((data) => {
          return window.screen.width > 900 ? (
            <SwiperSlide>
              <div style={{ width: "100%" }}>
                <Link to={data["path"]}>
                  <img width="100%" src={data["image"]} />
                </Link>
              </div>
            </SwiperSlide>
          ) : (
            <SwiperSlide>
              <div style={{ width: "100%" }}>
                <Link to={data["path"]}>
                  <img
                    width="100%"
                    src={
                      "https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/slider-1-mob.png?alt=media&token=281e9ba7-43ad-4458-9ddc-93289aa33a64"
                    }
                  />
                </Link>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default Carousel;
