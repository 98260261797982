import React from "react";
import StyledDiscoverMoreCard from "./StyledDiscoverMoreCard";

const DiscoverMoreCard = (props) => {
  return (
    <StyledDiscoverMoreCard>
      <i className="fas fa-search"></i>
      <h1>{props.payload.title}</h1>
    </StyledDiscoverMoreCard>
  );
};

export default DiscoverMoreCard;
