import styled from "styled-components";

const StyledSaleBanner = styled.div`
  img {
    width: 100%;
  }
  @media screen and (min-width: 900px) {
    padding-left: 2.2rem;
    padding-right: 2.2rem;
  }
`;

export default StyledSaleBanner;
