import styled from "styled-components";

const StyledTakeQuiz = styled.div`
  height: 100vh;
  background-color: white;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 0 0.5rem;
    background-color: white;
  }
  .logo_image {
    display: block;
    width: 100%;
    max-width: 100px;
  }
  .ant-progress-text {
    display: none;
  }
  .ant-progress-outer {
    /* border: 1px solid red; */
    padding: 0;
  }
  .ant-progress-inner {
    margin-top: -15px;
    padding: 0;
  }
  .main {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
    /* border: 1px solid red; */
    width: 100%;
    /* max-width: 2000px; */
    align-items: center;
  }
  .progress_controls {
    /* border: 1px solid green; */
    width: 100%;
    margin-left: auto;
    padding: 1rem 0;
  }
`;

export default StyledTakeQuiz;
