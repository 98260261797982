import styled from "styled-components";

const StyledSingleProduct = styled.div`
  display: flex;
  justify-content: center;
  .ant-image {
    width: 100%;
    margin: 0 1rem;
    border: 0.1px solid #9696962e;
  }
  .magnify_container {
    background: #fff !important;
  }
`;

export default StyledSingleProduct;
