import React from "react";
import StyledColorFilterComponent from "../../../../ListingMainPage/Components/StyledColorFilterComponent";
import StyledColorComponent from "./StyledColorComponent";
import { useSelector } from "react-redux";

const ColorComponent = () => {
  const productData = useSelector(
    (state) => state.setproductData.productData
  );
  return (
    <StyledColorComponent>
      <div className="color_sec">
      <h1>color</h1>
      <div className="color_palette">
        {
          productData["colors"].map((value) => {
            return <StyledColorFilterComponent bg={value["code"]} />
          })
        }

      </div>
      </div>
    </StyledColorComponent>
  );
};

export default ColorComponent;
