import styled from "styled-components";

const StyledFilterText = styled.div`
  display: flex;
  text-align: left;
  margin-left: 1.5rem;
  margin-top: 1rem;
  font-size: 20px;
  font-weight: 500;
`;

export default StyledFilterText;
