import React from "react";
import StyledQuizCardComponent from "../QuizCardComponent/StyledQuizCardComponent";

const QuizCardComponent = ({ title, percentage, setpercentage }) => {
  return (
    <StyledQuizCardComponent>
      <div onClick={() => console.log("sameer")}>
        {" "}
        <h3>{title}</h3>
      </div>
    </StyledQuizCardComponent>
  );
};

export default QuizCardComponent;
