import styled from "styled-components";

const StyledProductCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  justify-content: space-around;
  cursor: pointer;

  .love_image {
    display: flex;
    justify-content: end;
    align-items: flex-start;
    margin-right: 10px;
  }
  .quick-view-sec {
    padding: 0.2rem 0.4rem;
    background: #0066ffb5;
    color: #fff;
    border-radius: 6px;
    margin-right: 10px;
  }
  .quick-view-hide {
    display: none;
  }
  .product_card {
    padding: 0.5rem 0rem;
  }
  .product_card:hover {
    box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  .product_card:hover .quick-view-hide {
    display: block;
  }

  .product_image {
    // margin-top: 2rem;
    display: flex;
    justify-content: center;
    // padding: 0.3rem 0;
  }
  .title {
    margin-top: 1rem !important;
    text-align: center;
    padding: 0rem 0 0.2rem !important;
    color: #404040;
    font-size: 14px;
    font-weight: 500;
  }
  .description {
    text-align: center;
    max-width: 350px;
    padding: 0;
    font-size: 10px;
    color: #8e8e8e;
    margin-bottom: 0.3rem;
  }
  .price {
    text-align: center;
    color: #404040;
    font-size: 14px;
    font-weight: 500;
  }
  .discount {
    text-align: center;
    font-size: 10px;
    margin-top: 0rem !important;
    color: #40404070;
    text-transform: uppercase;
  }
`;

export default StyledProductCard;
