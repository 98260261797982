import styled from "styled-components";

const StyleDealofMonth = styled.div`
  .deal-of-month-title {
    text-align: center;
    font-size:28px;
    font-weight:400;
    margin-bottom:15px !important;

  }
  .deal_month_sec{
    padding:1rem 0 3rem 0;
  }
  .deal-of-month-sec {
    margin: 0 2rem;
  }
  .deal-of-month-img {
    margin: 1rem auto;
    text-align: center;
    img {
      // width: 220px;
      border-radius: 10px;
    }
  }
  .deal-of-month-sec {
    margin: 0 2rem 2rem;
  }
  @media only screen and (min-width: 1400px) and (max-width: 1700px)  {
    .deal-of-month-img {
      img {
        // width: 265px;
      }
    }

`;
export default StyleDealofMonth;
