import styled from "styled-components";


const StyledProfileScreen = styled.div`
padding:2rem 2rem;
.txt-phone{
    font-size:14px;
    font-weight:500;
    color:#505050;
    margin-bottom:2px;
}
.txt-email{
    font-size:14px;
    font-weight:500;
    color:#505050;
    margin-bottom:5px;
}
.ant-card-body{
}
.manage-addrs{
    font-size:14px;
    font-weight:500;
    color:#505050;
    border: 0.5px solid #000000;
    cursor:pointer;
}
`;
export default StyledProfileScreen;