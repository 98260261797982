import React, { useState } from "react";
import { Progress, Button, Input } from "antd";
import logo from "../../../src/assets/Images/Logo.svg";
import StyledTakeQuiz from "./Components/StyledTakeQuiz";

import {
  MinusOutlined,
  PlusOutlined,
  RightOutlined,
  LeftOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { Row, Col } from "antd";
import { QuizOneComponent } from "./Components/QuizComponent/QuizOneComponent";

const TakeQuiz = () => {
  const [percentage, setPercentage] = useState(0);
  const Increase = () => {
    setPercentage(percentage + 10);
    percentage >= 100 && setPercentage(100);
  };
  const Decrease = () => {
    setPercentage(percentage - 10);
    percentage === 0 && setPercentage(0);
  };

  console.log(percentage);
  return (
    <>
      <StyledTakeQuiz>
        <div className="header">
          <div className="main">
            <div className="percentage">
              <h1>{percentage}%</h1>
            </div>
            <img className="logo_image" src={logo} />
            <div className="close ">
              <CloseOutlined style={{ width: "100%" }} />
            </div>
          </div>

          <div className="progress_controls">
            <Button.Group>
              {percentage > 0 && (
                <Button
                  style={{ borderRadius: "10px" }}
                  onClick={Decrease}
                  icon={<LeftOutlined />}
                />
              )}
              <Button
                // style={{ border: "1px solid red", padding: "0 2rem" }}
                style={{ borderRadius: "10px", marginLeft: "0.4rem" }}
                onClick={Increase}
                icon={<RightOutlined />}
              />
            </Button.Group>
          </div>
        </div>
        <Progress percent={percentage} />

        <Row justify="center">
          <Col
            // style={{
            //   display: "flex",
            //   justifyContent: "center",
            //   alignItems: "center",
            //   flexDirection: "column",
            // }}
            xs={24}
          ></Col>
        </Row>

        <QuizOneComponent
          setPercentage={setPercentage}
          percentage={percentage}
        />
      </StyledTakeQuiz>
    </>
  );
};

export default TakeQuiz;
