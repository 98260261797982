import React from "react";
import StyledButton from "../../../../Components/StyledButton";
import StyledListingCard from "./StyledListingCard";
import { Link } from "react-router-dom";

const ListingCards = (props) => {
  return (
    <StyledListingCard>
      <div className="img_container">
        <img src={props.payload.image} />
      </div>
      <div className="card_body">
        <h3 className="card_text">{props.payload.title}</h3>
        <div className="shopnow_btn">
          <Link to={props.payload.path}>Shop Now</Link>
        </div>
      </div>
    </StyledListingCard>
  );
};

export default ListingCards;
