import React from "react";
import StyledImageGrid from "./StyledImageGrid";

const ProductImageGrid = ({ arrayImg, setImg, Img }) => {
  return (
    <StyledImageGrid>
      {arrayImg.map((image) => {
        return (
          <img
            style={image == Img ? { border: "1px solid #0f0f0f" } : null}
            onClick={() => setImg(image)}
            src={image}
          />
        );
      })}
    </StyledImageGrid>
  );
};

export default ProductImageGrid;
