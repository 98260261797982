import styled from "styled-components";

const StyledMainCart = styled.div`
  border-radius: 16px;
  display: flex;
  .image-section {
    .cartimage {
    }
    .button {
      display: flex;
      justify-content: center;
    }
  }
  .description_section {
    .price {
      display: flex;
      align-items: center;
      h3 {
        margin: 0 0.5rem !important;
      }
      .discount {
        color: red;
      }
    }
    .tax {
      padding-left: 0.6rem;
    }
  }
  .button_controls {
    display: flex;
    justify-content: center;
    button {
      margin: 1rem;
      border-radius: 8px;
      background-color: #f4f4f4;
      border:none;
      color:#8E8E8E;
    }
  }
  .cart-image {
    width: 100%;
    max-width: 490px;
  }
`;

export default StyledMainCart;
