import React from "react";

import { Col, Row } from "antd";
import ListingCards from "../ListingMainPage/Components/ListinMainCard/ListingCards";
import { Grid } from "@mui/material";
const ListingPage = () => {
  const maincategories = [
    {
      id: 1,
      title: "Women’s Glasses",
      image:
        "https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/listing-page%2Fwome_glass1.png?alt=media&token=ee94eb25-f68a-4f24-b414-e4c55da9e4ec",
      path: "/listing/women",
    },
    {
      id: 2,
      title: "Kid’s Glasses",
      image:
        "https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/listing-page%2Fkids_glass1.png?alt=media&token=c6380603-4f40-40e4-9024-0bfb7fce6b60",
      path: "/listing/kids",
    },
    {
      id: 3,
      title: "Men’s Glasses",
      image:
        "https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/listing-page%2Fmens_glass1.png?alt=media&token=9fc3a5a4-b2dc-498a-84d1-cdc71743326f",
      path: "/listing/men",
    },
  ];
  return (
    <>
      {window.screen.width > 900 ? (
        <Row>
          {maincategories.map((data) => {
            return (
              <Col xs={8}>
                {" "}
                <ListingCards payload={data} />
              </Col>
            );
          })}
        </Row>
      ) : (
        <div style={{ marginTop: "3rem" }}>
          <Grid container spacing={2}>
            {maincategories.map((data) => {
              return (
                <Grid item xs={6}>
                  {" "}
                  <ListingCards payload={data} />
                </Grid>
              );
            })}
          </Grid>
        </div>
      )}
    </>
  );
};

export default ListingPage;
