import styled from "styled-components";

const StyledPriceDetailComponent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.2rem 0;

  .price_details {
    display: flex;
    align-items: center;
    color: #404040;
    font-weight: 500;
    h3 {
      font-size: 19.35px;
      font-weight: 700;
      margin-left: 1rem;
    }
    h6 {
      margin-left: 1rem !important;
      color: #8e8e8e;
      font-size: 15px;
      font-weight: 400;
    }
    .discount_price {
      margin-left: 10px !important;
      color: #ff0000;
    }
  }
  .tax_text {
    /* margin-left: 1rem !important; */
    font-size: 11.61px;
    font-weight: 400;
    color: #8e8e8e;
  }
  @media screen and (max-width:900px){
    .price_sec{
      padding-left:25px;
    }
  }
`;

export default StyledPriceDetailComponent;
