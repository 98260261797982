import styled from "styled-components";

const StyledQuizOneComponent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .title {
    text-align: center;
    h1 {
      padding: 3.5rem 0 !important;
    }
  }

  .cards {
    display: flex;
  }
`;

export default StyledQuizOneComponent;
