import Styled from "styled-components";

const StyledDiscoverMoreCard = Styled.div`
cursor:pointer;
padding:0.3rem;
border-radius:9px;
display:flex;
align-items:center;
margin:0.5rem;
background-color: #E5E5E5;
height:30px;
justify-content:space-around;
i{
  font-size:20px;
  color: #404040;
}
  h1{
     font-size:14px;
     font-weight: 400;
     color: #404040;
  }


`;

export default StyledDiscoverMoreCard;
