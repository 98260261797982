import React from "react";
import StyledSizeComponent from "../SizeComponent/StyledSizeComponent";
import { useSelector } from "react-redux";

const SizeComponent = () => {
  const productData = useSelector(
    (state) => state.setproductData.productData
  );
  return (
    <StyledSizeComponent>
      <div className="size_text">
        <h1 className="size">size</h1>
        <h1 className="size_chart">
          sizechart{" "}
          <span className="greater_sign">
            {" "}
            <i class="fa-solid fa-greater-than  greater"> </i>
          </span>
        </h1>
      </div>
      <div className="size_circles">
        {
          productData["sizes"].map((value) => {
            return <div className="circle">{value}</div>
          })
        }

      </div>
    </StyledSizeComponent>
  );
};

export default SizeComponent;
