import styled from "styled-components";

const StyledHeader = styled.div`
  background-color: #f2f4f7;
  display: flex;
  justify-content: end;
  color: #333;
  padding: 0.2rem 3rem 0.2rem 0px;
  .right_box {
    display: flex;
    .right_box_items {
      padding: 0.2rem 0.4rem;
      img {
        width: 25px;
        margin-right: 15px;
        margin-bottom: 2px;
      }
    }
  }
  .active_currency_btn {
    background-color: #fff;
    color: #333;
    padding: 5px 2px;
    margin: 3px 0;
    border-radius: 10px;
    cursor: pointer;
  }
  .currency_btn {
    padding: 5px 2px;
    margin: 3px 0;
    cursor: pointer;
  }
  @media only screen and (min-width: 1400px) and (max-width: 1700px) {
    .right_box {
      .right_box_items {
        padding: 0.2rem 3rem 0.2rem 0.9rem;
      }
    }
  }
`;

export default StyledHeader;
