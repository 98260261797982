import styled from "styled-components";

const StyledColorComponent = styled.div`
  // padding: 2rem 1rem;
  .color-col {
    cursor: pointer;
    margin: 0 auto;
    height: 50px;
    width: 50px;
    border-radius: 30px;
  }
  .color-title {
    p {
      margin-bottom: 0px;
    }
  }
  .black {
    background-color: #333;
  }
  .blue {
    background-color: #0023f5;
  }
  .green {
    background-color: #377d22;
  }
  .red {
    background-color: #e81010;
  }
  .clear {
    background-color: #e0f3f4;
  }
  .pink {
    background-color: #ffcfcf;
  }
  .gold {
    background-color: #ca8b30;
  }
  .color-title {
    cursor: pointer;
    color: #404040;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
  }
  .group-row {
    margin: 3rem 0rem 2rem 0rem;
  }
  .shop-app-btn {
    width: 8rem;
    padding: 0.6rem 2rem;
    margin-left: 3rem;
    background: #404040;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
  }
`;

export default StyledColorComponent;
