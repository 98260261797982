import styled from "styled-components";

const StyledRatingComponent = styled.div`
  display: flex;
  border-radius: 10px;
  width: 100%;
  padding: 0.1rem;
  justify-content: space-between;

  border: 1px solid #b0b0b0;
  max-width: 138.52px;
  .rating_number{
    padding-left:5px;
  }
  .division {
    margin-left: 0.2rem !important;
  } */
`;

export default StyledRatingComponent;
