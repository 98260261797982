import { Col, Row } from "antd";
import React from "react";
import QuizCardComponent from "../QuizCardComponent/QuizCardComponent";
import StyledQuizOneComponent from "./StyledQuizOneComponent";

export const QuizOneComponent = ({ setPercentage, percentage }) => {
  return (
    <StyledQuizOneComponent>
      <div className="title">
        <h1>You are looking for?</h1>
        <div className="cards">
          <QuizCardComponent
            percentage={percentage}
            setPercentage={setPercentage}
            title="men"
          />
          <QuizCardComponent title="women" />
          <QuizCardComponent title="kids" />
        </div>
      </div>
    </StyledQuizOneComponent>
  );
};
