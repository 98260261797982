import styled from "styled-components";

const StyledCategoryCard = styled.div`
  /* width: 300px;
  height: 100px; */
  margin: 1rem 0rem 3rem 0;
  @media screen and (max-width: 900px) {
    margin: 0;
  }
  img {
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .category_main_title {
    font-size: 35px;
    font-weight: 400;
    text-align: center;
    color: #fff;
  }
  .category_main_title_mob {
    font-size: 16px;
    font-weight: 500;
    bottom: 5px;
    color: #fff;
  }
  .shop-now-btn {
    margin-left: 30%;
    margin-right: 30%;
    background-color: #fff;
    border-radius: 10px;
  }
  .shop-now-btn a {
    color: #333;
    font-weight: 500;
  }
  @media screen and (max-width: 900px) {
    .shop-now-btn {
      margin-left: 0%;
      margin-right: 0%;
      background-color: #eeeeee;
      order-top-right-radius: 0px;
      border-top-left-radius: 0px;
    }
  }
`;

export default StyledCategoryCard;
