import React, { useEffect } from "react";
import Header from "../../Components/Header/Header";
import Navbar from "../../Components/Navbar/Navbar";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import FooterTop from "../MainPage/Components/FooterTop";
import FooterMain from "../MainPage/Components/FooterMain";
import ProductPage from "../MainPage/ProductPage/ProductPage";
import SizeChart from "../SizeChart/SizeChart";
import PrescriptionTab from "../MainPage/ProductPage/Components/PrescriptionTab/PrescriptionTab";
import { Routes, Route } from "react-router-dom";
import CartScreen from "../CartScreen/CartScreen";
import { auth } from "../../Firebase/Config";
import { useDispatch } from "react-redux";
import { setcartproducts, setwishlist } from "../../redux-tool/features/product-features";
import { httpGet } from "../../networkutils/networkutils";

const HomeScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    const getcrtitems = localStorage.getItem("cart");
    if (getcrtitems) {
      const localItems = JSON.parse(getcrtitems);
      dispatch(setcartproducts(localItems));
    } else {
      auth.onAuthStateChanged((user) => {
        getproducts();
      }, (err) => {

      })
    }

    if (location.pathname == "/") {
      navigate("/home");
    }
  });
  const getproducts = () => {
    httpGet("cart/getproducts").then((res) => {
      if (res["status"] === "SUCCESS") {
        dispatch(setcartproducts(res["data"]))
        httpGet("wishlist/get").then((wsres) => {
          if (wsres["status"] === "SUCCESS") {
            dispatch(setwishlist(wsres["data"]))
          }
        })
      }
    })
  }
  return (
    <>
      <Header />
      <Navbar />
      <Routes>
        {/* <Route path="/" element={<MainPage />} /> */}
        {/* <Route path="/" element={<ListingMainPage />} />
        <Route path="/" element={<ProductPage />} />
        <Route path="/" element={<SizeChart />} />
        <Route path="/" element={<SizeChart />} />
        <Route path="/" element={<PrescriptionTab />} /> */}

        {/* <Route path="/" element={<CartScreen />} /> */}
      </Routes>

      {/* <Navbar /> */}
      <Outlet />
      <FooterTop />
      <FooterMain />
    </>
  );
};

export default HomeScreen;
