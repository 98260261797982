import { Grid } from "@mui/material";
import { Col, Row } from "antd";
import React from "react";
import StyleBannerColumn from "./StyleBanner";

const ListingMenpage = () => {
  return (
    <>
      <StyleBannerColumn>
        {window.screen.width > 900 ?
          <Row>
            <Col xs={12}>
              <div className="text-banner">
                <div>
                  <h2>Men’s Glasses</h2>
                  <p>
                    Our men's glasses include shapes & materials to fit any face.
                    Go for a classic aviator or pick modern men's glasses in a
                    trendy color.
                  </p>
                </div>
              </div>
            </Col>
            <Col xs={12}>
              <img
                width="100%"
                src="https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/listin_men_banner.png?alt=media&token=8ae9e38c-bc33-4745-9fd4-43e9f105a7c2"
              />
            </Col>
          </Row> : <Grid container spacing={1}>
            <Grid item xs={12}>
              <img
                style={{ marginTop: "3.5rem" }}
                width="100%"
                src="https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/listin_men_banner.png?alt=media&token=8ae9e38c-bc33-4745-9fd4-43e9f105a7c2"
              />
            </Grid>
            <Grid item xs={12}>
              <div className="text-banner">
                <div>
                  <h2>Men’s Glasses</h2>
                  <p>
                    Our men's glasses include shapes & materials to fit any face.
                    Go for a classic aviator or pick modern men's glasses in a
                    trendy color.
                  </p>
                </div>
              </div>
            </Grid>

          </Grid>}
      </StyleBannerColumn>
    </>
  );
};
export default ListingMenpage;
