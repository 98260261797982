import { auth } from "./Config";
import firebase from "firebase";

export const signin = (mobilenumber = "") => {
    return new Promise((resolve, reject) => {
        if (mobilenumber === "" || mobilenumber.length < 10) return;
        if (!mobilenumber.startsWith("+")) {
            mobilenumber = `+${mobilenumber}`;
        }
        let verify = new firebase.auth.RecaptchaVerifier('recaptcha-container',{
            size: 'invisible',
        });
        auth.signInWithPhoneNumber(mobilenumber, verify).then((result) => {
            resolve(result);
        })
            .catch((err) => {
                reject(err)
            });
    })

}
export const ValidateOtp = (final, otp = "",) => {
 return   new Promise((resolve, reject) => {
        if (otp === null)
            return;
        final.confirm(otp).then((result) => {
            resolve(result);
        }).catch((err) => {
            reject(err);
        })
    })
}
