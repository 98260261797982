import styled from "styled-components";

const StyledShopByText = styled.div`
  padding: 1rem;
  .shop_text {
    color: #404040;
    font-size: 35px;
    font-weight: 400;
    text-align: center;
  }
  @media screen and (min-width: 900px) {
    .shop_text {
      text-align: left;
    }
  }
`;

export default StyledShopByText;
