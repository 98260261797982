import styled from "styled-components";

const StyledButton = styled.div`
  background-color: #404040;
  color: white;
  padding: 0.3rem 1rem;
  text-transform: uppercase;
  margin: 1rem 1em;
  cursor: pointer;
  text-align: center;
  border-radius: 10px;
`;

export default StyledButton;
