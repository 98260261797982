import styled from "styled-components";

const StyledFrameComponent = styled.div`
  // padding: 3rem 2rem;
  @media screen and (max-width: 900px) {
    padding: 0px;
  }
  .face_img {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 1rem;
    .text {
      cursor: pointer;
      font-size: 20px;
      font-weight: 400;
      color: #404040;
    }
  }
  .face_row {
    margin: 1rem 0;
  }
  .frame-group {
    margin-top: 3rem;
  }

  /* min-height: 89.9%; */
`;

export default StyledFrameComponent;
