import styled from "styled-components";

const StyledTransCard = styled.div`
  margin: 0rem;
  img {
    width: 100%;
  }
  .trans-img-mob {
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 20%);
    border-radius: 13px;
    margin-bottom: 20px;
  }
  .trans_sec_mob {
    position: relative;
  }
  .trans_text {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    margin: 1rem 1rem 0 !important;
  }
  .trans_text_mob {
    position: absolute;
    z-index: 1;
    bottom: 30px;
    left: 10px;
    font-size: 18px;
    color: #fff;
    font-weight: 400;
  }
  .shop-btn {
    background-color: #fff;
    color: #333;
    border-radius: 10px;
    padding: 0.3rem 1rem;
    text-transform: uppercase;
    margin: 1rem 1em;
    cursor: pointer;
    text-align: center;
    font-weight: 500;
  }
  .trans_bottom {
    // display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f6f6f6;

    p {
      margin: 0rem 1rem 0.5rem !important;
      color: #fff;
      font-size: 12px;
      font-weight: 400;
    }
  }
`;

export default StyledTransCard;
