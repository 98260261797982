import React from "react";
import { Row, Col, Divider } from "antd";
import productImage from "../../../../../assets/uploadImage/singleproduct.png";
import StyledSingleProduct from "./StyledSingleProduct";
import { Image } from "antd";
import { GlassMagnifier } from "react-image-magnifiers";
const SingleProductImage = ({ Img }) => {
  return (
    <>
      <StyledSingleProduct>
        {/* <Image style={{ width: "100%" }} src={Img} /> */}
        <Row>
          {Img !== undefined ?
          <GlassMagnifier
          className="ant-image"
          // src={Img}
          imageSrc = {Img}
          
          style={{ width: "100%" }}
        />: null}
        </Row>
      </StyledSingleProduct>
    </>
  );
};

export default SingleProductImage;
