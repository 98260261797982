import styled from "styled-components";

const StyledPriceComponent = styled.div`
  h5 {
    padding: 10px 10px !important;
    font-size: 18px;
    font-weight: 400;
  }
  li {
    padding-left: 0px !important;
  }

  .frame_img_text {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 1rem;
    .frame_img {
      display: flex;
      flex-direction: column;
      /* border: 1px solid red; */

      img {
        width: 100%;
        max-width: 88px;
        margin-top: 1rem;
      }
    }
  }
`;

export default StyledPriceComponent;
