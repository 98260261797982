import styled from "styled-components";

const StyledCategorySelector = styled.div`
  padding: 1rem 0;
  .color_tab {
    /* display: flex;
     justify-content: center; */
  }
  .select_category_h3_mob {
    font-size: 16px;
    color: #404040;
    font-weight: 400;
    cursor: pointer;
    text-align: center;
  }
  .vl {
    border-left: 2px solid black;
    height: 45px;
  }

  h3 {
    font-size: 25px;
    color: #404040;
    font-weight: 400;
    cursor: pointer;
    text-transform: uppercase;
  }
`;

export default StyledCategorySelector;
