import styled from "styled-components";

const StyledDescriptionComponent = styled.div`
  display: flex;

  align-items: flex-start;

  flex-direction: column;

  h1 {
    font-size: 24px;
    color:#404040;
    padding: 0.5rem 0 0 !important;
  }
  .product_price{
    color:#8E8E8E;
    font-size:16px;
    font-weight:400;
    text-decoration: line-through;
  }
  .tax-text{
    color:#8E8E8E;
    font-weight:400;
  }
  .price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .mainprice {
      font-size: 19.35px;
    }
    h3 {
      font-size: 15.3px;
      padding: 0 0.4rem 0 0 !important;
    }
    .discount {
      color: red;
    }
  }
  .tax {
    padding: 0.2rem 0rem !important;
  }
  .Quantity-container {
    padding: 0.5rem 0rem;
    display: flex;
    align-items: center;
    span {
      margin-right: 0.5rem;
      font-weight:500;
    }
    h6 {
      padding: 0 0.5rem !important;
    }
  }
  .pls_btn{
    span{
      margin:0px !important;
      font-size:20px;
      font-weight:500;
    }
  }
  .size {
    padding: 0.5rem 0rem;
    h3 {
      font-size: 15.48px;
      font-weight:500 !important;
    }
    span{
      color:#404040 !important;
      font-size:14px !important;
      font-weight: 400;
    }
  }
  .color {
    padding: 0.5rem 0rem;
    display: flex;
    align-items: center;
    justify-content: center;
    h3{
      font-size: 15.48px;
      font-weight:500 !important;
    }
    .color-palette {
      margin-left: 0.3rem;
      width: 15px;
      height: 15px;
      border-radius: 50%;
    }
  }
`;

export default StyledDescriptionComponent;
