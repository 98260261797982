import React, { useEffect, useState } from "react";
import StyledNavbar from "./StyledNavbar";
import LogoImage from "../../assets/Images/Logo.svg";
import profile from "../../assets/icons/profileicon.svg";
import wishlist from "../../assets/icons/wishlisticon.svg";
import carticon from "../../assets/icons/carticon.svg";
import { Row, Col, Badge, Modal, Button, Spin, Layout } from "antd";
import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";

import "../../styles/NavbarStyle.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { httpGet, httpPost } from "../../networkutils/networkutils";
import {
  setcartproducts,
  setcartproductsatindex,
} from "../../redux-tool/features/product-features";
import { auth } from "../../Firebase/Config";
import StyledDescriptionComponent from "../../Screens/CartScreen/Components/StyledDescriptionComponent";
import NavbarMob from "./NavbarMob";
import Menmegamenu from "./MenMegaMenu";
import Womenmegamenu from "./WomenMegaMenu";
import Kidsmegamenu from "./KidsMegamenu";
import EyeGlassmegamenu from "./EyeGlassMegamenu";
import SunGlassmegamenu from "./SunGlassMegamenu";
import ContactLensmegamenu from "./ContactLensmegamenu";
import StyledHeader from "../Header/StyledHeader";

import uaeflag from "../../assets/icons/uaeflag.svg";
import usaflag from "../../assets/icons/usa_flg.png";
import fastdelivery from "../../assets/icons/fastdelivery.svg";
import genuine from "../../assets/icons/genuine.svg";
const Navbar = () => {
  const navigate = useNavigate();
  const { Header, Content, Sider, AntHeader } = Layout;

  const [menDropdown, setmenDropdown] = useState({ display: "none" });
  const [showCartmodal, setshowCartmodal] = useState(false);
  const [womenDropdown, setwomenDropdown] = useState({ display: "none" });
  const [spinning, setspinning] = useState(false);
  const [activecurrency, setactivecurrency] = useState("AED");
  const [activeFlag, setactiveFlag] = useState("USD");
  // const [kidDropdown, setkidDropdown] = useState({ display: "none" });
  // const [eyeDropdown, seteyeDropdown] = useState({ display: "none" });
  // const [sunDropdown, setsunDropdown] = useState({ display: "none" });
  const [stickyClass, setStickyClass] = useState("relative");
  var cartItems = useSelector((state) => state.setproductData.cartProducts);
  var wishlistitems = useSelector((state) => state.setproductData.wishlist);
  const dispatch = useDispatch();
  useEffect(() => {
    if (auth.currentUser) {
      getproducts();
    }
    window.addEventListener("scroll", stickNavbar);
    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  }, []);
  const handleOk = (e) => {
    setshowCartmodal(false);
  };

  const handleCancel = (e) => {
    setshowCartmodal(false);
  };
  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 100 ? setStickyClass("fixed") : setStickyClass("relative");
    }
  };
  const getproducts = () => {
    httpGet("cart/getproducts").then((res) => {
      if (res["status"] === "SUCCESS") {
        dispatch(setcartproducts(res["data"]));
      }
    });
  };

  const setproductPrice = () => {
    var totlprice = 0;
    cartItems.map((value) => {
      if (value["cart_quantity"]) {
        totlprice +=
          parseFloat(value["selling_price"]) * value["cart_quantity"];
      }
    });
    return totlprice;
  };

  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState("right");
  const [sidenavColor, setSidenavColor] = useState("#1890ff");
  const [sidenavType, setSidenavType] = useState("transparent");
  const [fixed, setFixed] = useState(false);

  const openDrawer = () => setVisible(!visible);
  const handleSidenavType = (type) => setSidenavType(type);
  const handleSidenavColor = (color) => setSidenavColor(color);
  const handleFixedNavbar = (type) => setFixed(type);

  useEffect(() => {
    setPlacement("left");
  });

  return (
    <StyledNavbar>
      <Modal
        visible={showCartmodal}
        title="Cart"
        style={{ right: 20, top: 20 }}
        width="700px"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button className="checkout_btn">
            TOTAL AMOUNT : AED {setproductPrice()}
          </Button>,
          <Button
            className="checkout_btn"
            style={{
              backgroundColor: "#505050",
              color: "#fff",
              fontSize: "16px",
              fontWeight: "400",
            }}
            onClick={() => {
              setshowCartmodal(false);
              navigate("/checkout");
            }}
          >
            PROCEED TO CHECKOUT
          </Button>,
        ]}
      >
        <div className="container">
          {cartItems.map((val, index) => {
            return (
              <>
                <div className="row">
                  <div className="col-md-6">
                    <img src={val.primary_image} width="100%" />
                  </div>
                  <div className="col-md-6">
                    <StyledDescriptionComponent>
                      <h1 style={{ fontSize: "16px" }}>
                        {" "}
                        {val["name"] ?? val["product_name"]}{" "}
                      </h1>
                      <p> {val["sub_title"]}</p>
                      <div className="price">
                        <h3 style={{ fontSize: "14px" }} className="mainprice">
                          AED {val["selling_price"]}
                        </h3>
                        <h3
                          style={{ fontSize: "14px" }}
                          className="product_price"
                        >
                          AED {val["product_price"]}
                        </h3>
                        <h3 style={{ fontSize: "14px" }} className="discount">
                          (25% OFF)
                        </h3>
                      </div>
                      <Spin spinning={spinning}>
                        <div className="Quantity-container">
                          <span>Quantity:</span>
                          <Button
                            className="pls_btn"
                            onClick={() => {
                              if (val["quantity"] >= val["cart_quantity"]) {
                                var qty = val["cart_quantity"] + 1;
                                if (!auth.currentUser) {
                                  const data = {
                                    ...val,
                                    cart_quantity: qty,
                                  };
                                  var payload = {
                                    data: data,
                                    index: index,
                                  };
                                  dispatch(setcartproductsatindex(payload));
                                } else {
                                  setspinning(true);
                                  const body = {
                                    cart_id: val["cart_id"],
                                    quantity: qty,
                                  };
                                  httpPost("cart/updatequantity", body).then(
                                    (res) => {
                                      if (res["status"] === "SUCCESS") {
                                        const data = {
                                          ...val,
                                          cart_quantity: qty,
                                        };
                                        var payload = {
                                          data: data,
                                          index: index,
                                        };
                                        dispatch(
                                          setcartproductsatindex(payload)
                                        );
                                        setspinning(false);
                                      }
                                    }
                                  );
                                }
                              }
                            }}
                            style={{ padding: " 0  0.3rem" }}
                          >
                            +
                          </Button>
                          <h6 style={{ fontSize: "14px" }}>
                            {val["cart_quantity"]}
                          </h6>
                          <Button
                            className="pls_btn"
                            onClick={() => {
                              if (val["cart_quantity"] > 0) {
                                if (!auth.currentUser) {
                                  var qty = val["cart_quantity"] - 1;
                                  const data = {
                                    ...val,
                                    cart_quantity: qty,
                                  };
                                  var payload = {
                                    data: data,
                                    index: index,
                                  };
                                  dispatch(setcartproductsatindex(payload));
                                } else {
                                  setspinning(true);
                                  var qty = val["cart_quantity"] - 1;
                                  const body = {
                                    cart_id: val["cart_id"],
                                    quantity: qty,
                                  };
                                  httpPost("cart/updatequantity", body).then(
                                    (res) => {
                                      if (res["status"] === "SUCCESS") {
                                        const data = {
                                          ...val,
                                          cart_quantity: qty,
                                        };
                                        var payload = {
                                          data: data,
                                          index: index,
                                        };
                                        dispatch(
                                          setcartproductsatindex(payload)
                                        );
                                        setspinning(false);
                                      }
                                    }
                                  );
                                }
                              }
                            }}
                            style={{ padding: " 0  0.3rem" }}
                          >
                            -
                          </Button>
                        </div>
                      </Spin>
                      <div style={{ padding: "0px" }} className="size">
                        <h3 style={{ padding: "0px" }}>
                          Size: <span>{val["size"]}</span>
                        </h3>
                      </div>
                      <div style={{ padding: "0px" }} className="color">
                        <h3>Color:</h3>
                        <div
                          style={{ backgroundColor: val["color_code"] }}
                          className="color-palette"
                        ></div>
                      </div>
                    </StyledDescriptionComponent>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </Modal>
      {window.screen.width > 900 ? (
        <div>
          <div className={`desktop-container fixed`}>
            <StyledHeader>
              <div className="right_box">
                <Row justify="right">
                  <div className="right_box_items">
                    <img src={fastdelivery} />
                    Fast Delivery & Exchanges
                  </div>
                  <div className="right_box_items">
                    <img src={genuine} />
                    100% Genuine Brands
                  </div>
                  <div className="right_box_items">
                    <Dropdown
                      overlay={() => {
                        return (
                          <Menu>
                            {activeFlag !== "AED" ? (
                              <Menu.Item
                                key="0"
                                onClick={() => {
                                  setactiveFlag("AED");
                                }}
                              >
                                ةيبرعلا <img src={uaeflag} />
                              </Menu.Item>
                            ) : (
                              <Menu.Item
                                key="0"
                                onClick={() => {
                                  setactiveFlag("USD");
                                }}
                              >
                                ENGLISH <img src={usaflag} height="30px" />
                              </Menu.Item>
                            )}
                          </Menu>
                        );
                      }}
                      trigger={["click"]}
                    >
                      <div
                        className="ant-dropdown-link"
                        onClick={(e) => e.preventDefault()}
                      >
                        {activeFlag === "AED" ? (
                          <>
                            ةيبرعلا <img src={uaeflag} />
                          </>
                        ) : (
                          <>
                            ENGLISH <img src={usaflag} />
                          </>
                        )}
                        <DownOutlined />
                      </div>
                    </Dropdown>
                  </div>
                  <div
                    className={`right_box_items ${
                      activecurrency == "USD"
                        ? " active_currency_btn"
                        : "currency_btn"
                    }`}
                    onClick={() => {
                      setactivecurrency("USD");
                    }}
                  >
                    $ Dollar
                  </div>
                  <div
                    className={`right_box_items ${
                      activecurrency == "AED"
                        ? "active_currency_btn"
                        : "currency_btn"
                    }`}
                    onClick={() => {
                      setactivecurrency("AED");
                    }}
                    style={{ marginLeft: "5px" }}
                  >
                    AED
                  </div>
                </Row>
              </div>
            </StyledHeader>
            <div className="container-fluid" style={{ margin: "0" }}>
              <Row align="middle">
                <Col xs={1}></Col>
                <Col xs={2}>
                  {" "}
                  <div className="logo_image">
                    <Link to="/home">
                      <img src={LogoImage} />
                    </Link>
                  </div>
                </Col>

                <Col xs={12}>
                  <div className="menu">
                    <div className="menu_items desktop-navLink">
                      <a
                        className="desktop-main"
                        style={{ borderBottomColor: "#f26a10" }}
                      >
                        {" "}
                        Men{" "}
                      </a>
                      <Menmegamenu />
                    </div>
                    <div className="menu_items desktop-navLink">
                      <a
                        className="desktop-main"
                        style={{ borderBottomColor: "#f26a10" }}
                      >
                        Women
                      </a>
                      <Womenmegamenu />
                    </div>
                    <div className="menu_items desktop-navLink">
                      <a
                        className="desktop-main"
                        style={{ borderBottomColor: "#f26a10" }}
                      >
                        {" "}
                        KIDS
                      </a>
                      <Kidsmegamenu />
                    </div>
                    <div className="menu_items desktop-navLink">
                      <a
                        className="desktop-main"
                        style={{ borderBottomColor: "#f26a10" }}
                      >
                        EyeGlasess
                      </a>
                      <EyeGlassmegamenu />
                    </div>
                    <div className="menu_items desktop-navLink">
                      <a
                        className="desktop-main"
                        style={{ borderBottomColor: "#f26a10" }}
                      >
                        Sunglasses
                      </a>
                      <SunGlassmegamenu />
                    </div>
                    <div className="menu_items desktop-navLink">
                      <a
                        className="desktop-main"
                        style={{ borderBottomColor: "#f26a10" }}
                      >
                        ContactLens
                      </a>
                      <ContactLensmegamenu />
                    </div>
                  </div>
                </Col>

                <Col xs={5}>
                  <div className="search_box">
                    <input
                      className="search_input"
                      placeholder="Search for products, brands and more"
                    />
                  </div>
                </Col>

                <Col xs={4}>
                  <div className="product_menu">
                    <div className="product_controls">
                      <div
                        onClick={() => {
                          navigate("/profile");
                        }}
                        className="product_control_text_img"
                      >
                        <img className="profile_image" src={profile} alt="" />
                        <h1 className="product_control_text">Profile</h1>
                      </div>
                      <div
                        onClick={() => {
                          navigate("/wishlist");
                        }}
                        className="product_control_text_img"
                        style={{ textAlign: "center", marginTop: "5px" }}
                      >
                        <Badge count={wishlistitems.length}>
                          <img src={wishlist} alt="" />
                          <h1 className="product_control_text">WishList</h1>
                        </Badge>
                      </div>
                      <div
                        onClick={() => {
                          // navigate("/cart");
                          setshowCartmodal(true);
                        }}
                        className="product_control_text_img"
                        style={{ textAlign: "center" }}
                      >
                        <Badge count={cartItems.length}>
                          <img src={carticon} alt="" />
                          <h1 className="product_control_text"> Cart</h1>
                        </Badge>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div
              className="dropdown-content"
              onMouseLeave={(e) => {
                setmenDropdown({ display: "none" });
              }}
              style={menDropdown}
            ></div>
            <div
              className="dropdown-content"
              onMouseLeave={(e) => {
                setwomenDropdown({ display: "none" });
              }}
              style={womenDropdown}
            ></div>
          </div>
          <div style={{ height: "43px" }}></div>
        </div>
      ) : (
        <NavbarMob />
      )}
    </StyledNavbar>
  );
};

export default Navbar;
