import React from "react";
import StyledFooterTop from "./StyledFooterTop";
import question from "../../../assets/ImageContainer/question.svg";
import call from "../../../assets/ImageContainer/call.svg";
import chat from "../../../assets/ImageContainer/chat.svg";
import email from "../../../assets/ImageContainer/email.svg";
import { Grid } from "@mui/material";

const FooterTop = () => {
  return window.screen.width > 900 ? (
    <StyledFooterTop>
      <div className="container">
        <div className="row" align="middle">
          <div xs={6} className="col footer_top_items">
            <div className="circle_footer_item">
              <div className="circle">
                <img src={question} alt="" />
              </div>
              <h5 className="text">Help Center</h5>
            </div>
          </div>
          <div xs={6} className="col footer_top_items">
            <div className="circle_footer_item">
              <div className="circle">
                <img src={chat} alt="" />
              </div>
              <h5 className="text">Live Chat</h5>
            </div>
          </div>
          <div xs={6} className="col footer_top_items">
            <div className="circle_footer_item">
              <div className="circle">
                <img src={call} alt="" />
              </div>
              <div style={{ display: "inline-block" }}>
                <h5 className="text">Call</h5>
                <p className="subtitle">Mon-Fri 7:00am - 11:00pm</p>
              </div>
            </div>{" "}
          </div>
          <div xs={6} className="col footer_top_items">
            <div className="circle_footer_item">
              <div className="circle">
                <img src={email} alt="" />
              </div>
              <div style={{ display: "inline-block" }}>
                <h5 className="text">Email</h5>
                <p className="subtitle">info@oakann.com</p>
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
    </StyledFooterTop>
  ) : (
    <></>
  );
};

export default FooterTop;
