import { Button, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "../../../Firebase/Config";
import { httpPost } from "../../../networkutils/networkutils";
import { setcartproducts, setcartproductsatindex } from "../../../redux-tool/features/product-features";
import StyledDescriptionComponent from "../Components/StyledDescriptionComponent";

const DescriptionComponent = (props) => {
  const [spinning, setspinning] = useState(false);
  const [quantity, setquantity] = useState(0);
  const dispatch = useDispatch();
  const getcartproducts = useSelector((state) => state.setproductData.cartProducts);
  const data = props.data;
  useEffect(() => {
    setquantity(data["cart_quantity"]);
  }, [])
  return (
    <StyledDescriptionComponent>
      <h1> {data["name"]} </h1>
      {/* <p> {data["sub_title"]}</p> */}
      <div className="price">
        <h3 className="mainprice">AED {data["selling_price"]}</h3>
        <h3 className="product_price">AED {data["product_price"]}</h3>
        <h3 className="discount">(25% OFF)</h3>
      </div>
      <div className="tax">
        <h6 className="tax-text">Inclusive of all Taxes</h6>
      </div>
      <Spin spinning={spinning}>
        <div className="Quantity-container">
          <span>Quantity:</span>
          <Button className="pls_btn" onClick={() => {
            if (data["quantity"] >= getcartproducts[props.index]["cart_quantity"]) {
              var qty = getcartproducts[props.index]["cart_quantity"] + 1;
              if (!auth.currentUser) {
                const data = {
                  ...props.data,
                  cart_quantity: qty
                }
                var payload = {
                  data: data,
                  index: props.index
                }
                dispatch(setcartproductsatindex(payload));
              } else {
                setspinning(true);
                const body = {
                  "cart_id": data["cart_id"],
                  "quantity": qty
                }
                httpPost("cart/updatequantity", body).then((res) => {
                  if (res["status"] === "SUCCESS") {
                    setquantity(qty);
                    const data = {
                      ...props.data,
                      cart_quantity: qty
                    }
                    var payload = {
                      data: data,
                      index: props.index
                    }
                    dispatch(setcartproductsatindex(payload));
                    setspinning(false);
                  }
                })
              }

            }
          }} style={{ padding: " 0  0.3rem" }}>
            +
          </Button>
          <h6 style={{ fontSize: "14px" }}>{getcartproducts[props.index]["cart_quantity"]}</h6>
          <Button className="pls_btn" onClick={() => {
            if (getcartproducts[props.index]["cart_quantity"] > 0) {
              if (!auth.currentUser) {
                var qty = getcartproducts[props.index]["cart_quantity"] - 1;
                const data = {
                  ...props.data,
                  cart_quantity: qty
                }
                var payload = {
                  data: data,
                  index: props.index
                }
                dispatch(setcartproductsatindex(payload));
              } else {
                setspinning(true);
                var qty = getcartproducts[props.index]["cart_quantity"] - 1;
                const body = {
                  "cart_id": data["cart_id"],
                  "quantity": qty
                }
                httpPost("cart/updatequantity", body).then((res) => {
                  if (res["status"] === "SUCCESS") {
                    setquantity(qty);
                    const data = {
                      ...props.data,
                      cart_quantity: qty
                    }
                    var payload = {
                      data: data,
                      index: props.index
                    }
                    dispatch(setcartproductsatindex(payload));
                    setspinning(false);
                  }
                })
              }

            }
          }

          } style={{ padding: " 0  0.3rem" }}>
            -
          </Button>
        </div>
      </Spin>
      <div className="size">
        <h3>Size: <span>{data["size"]}</span></h3>
      </div>
      <div className="color">
        <h3>Color:</h3>
        <div style={{ backgroundColor: data["color_code"] }} className="color-palette"></div>
      </div>

    </StyledDescriptionComponent>
  );
};

export default DescriptionComponent;
