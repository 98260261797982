import styled from "styled-components";

const StyledFaceComponent = styled.div`
  .face_img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 1rem;
    .text {
      font-size: 20px;
      font-weight: 400;
      color: #404040;
    }
  }
  .face_row {
    margin: 1rem 0;
  }
  .last-row-text {
    margin-left: 3rem;
    color: #404040;
    font-size: 16px;
    font-weight: 400;
    span {
      color: #0066ff;
    }
  }

  /* min-height: 89.9%; */
`;

export default StyledFaceComponent;
