import { React, useState } from "react";
import { Link } from "react-router-dom";

import { styled, alpha } from "@mui/material/styles";
import LogoImage from "../../assets/Images/Logo.svg";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import MailIcon from "@mui/icons-material/Mail";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import { Slide, useScrollTrigger } from "@mui/material";
import PropTypes from "prop-types";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import CloseIcon from "@mui/icons-material/Close";
import { Modal } from "antd";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  marginTop: "5px",
  borderRadius: "13px",
  backgroundColor: "#F2F4F7",
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export default function NavbarMob(props) {
  const [showdrawer, setshowdrawer] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const [showsearchmodal, setshowsearchmodal] = useState(false);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setshowdrawer(!showdrawer);
  };
  const HideOnScroll = (props) => {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      target: window ? window() : undefined,
    });

    return (
      <Slide appear={false} direction="down" in={!trigger}>
        {children}
      </Slide>
    );
  };
  HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
  };
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      // onClick={toggleDrawer()}
      // onKeyDown={toggleDrawer()}
    >
      <List>
        <ListItem onClick={toggleDrawer()} button key="">
          <ListItemIcon>
            <CloseIcon />
          </ListItemIcon>
          <ListItemText primary={""} />
        </ListItem>
        {/* <List>
          {["MEN", "WOMEN", "KIDS"].map((text, index) => (
            <ListItem button key={text}>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List> */}
        <Accordion
          style={{
            boxShadow: "none",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Eyeglasses</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List style={{ fontSize: "20px" }}>
              <ListItem>Women's Eyeglasses</ListItem>
              <ListItem>Men's Eyeglasses</ListItem>
              <ListItem>Kids Eyeglasses</ListItem>
              <ListItem>Multifocal</ListItem>
              <ListItem>Designer Eyeglasses</ListItem>
            </List>
          </AccordionDetails>
        </Accordion>
        <Accordion
          style={{
            boxShadow: "none",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Sunglassses</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List style={{ fontSize: "20px" }}>
              <ListItem>Men's Sunglasses</ListItem>
              <ListItem>Women's Sunglasses</ListItem>
              <ListItem>Kids Sunglasses</ListItem>
              <ListItem>Polarized Sunglasses</ListItem>
            </List>
          </AccordionDetails>
        </Accordion>
        <Accordion
          style={{
            boxShadow: "none",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Multifocal</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List style={{ fontSize: "20px" }}>
              <ListItem>Men</ListItem>
              <ListItem>Women</ListItem>
              <ListItem>Rx. Sunglasses</ListItem>
            </List>
          </AccordionDetails>
        </Accordion>
        <Accordion
          style={{
            boxShadow: "none",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Brands</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List style={{ fontSize: "20px" }}>
              <ListItem>Ray-ban</ListItem>
              <ListItem>Oakley</ListItem>
              <ListItem>DKNY</ListItem>
              <ListItem>Persol</ListItem>
              <ListItem>Versace</ListItem>
              <ListItem>Coach</ListItem>
              <ListItem>Calvein Klien</ListItem>
              <ListItem>Vogue</ListItem>
            </List>
          </AccordionDetails>
        </Accordion>
        <Accordion
          style={{
            boxShadow: "none",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Colors</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <List style={{ fontSize: "20px" }}>
              <ListItem>Tortoise</ListItem>
              <ListItem>Black</ListItem>
              <ListItem>Clear</ListItem>
              <ListItem>Gold</ListItem>
              <ListItem>Brown</ListItem>
              <ListItem>Blue</ListItem>
              <ListItem>Silver</ListItem>
              <ListItem>Gray</ListItem>
              <ListItem>Pink</ListItem>
              <ListItem>White</ListItem>
            </List>
          </AccordionDetails>
        </Accordion>
      </List>
    </Box>
  );
  const menuId = "primary-search-account-menu";
  return (
    <>
      <Modal
        title=""
        width="100%"
        style={{ top: 0, maxWidth: "100%", marginTop: "0px" }}
        visible={showsearchmodal}
        onOk={() => setshowsearchmodal(false)}
        onCancel={() => setshowsearchmodal(false)}
        footer={null}
      >
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            inputProps={{ "aria-label": "search" }}
          />
        </Search>
      </Modal>
      <Box sx={{ flexGrow: 1 }}>
        <HideOnScroll {...props}>
          <AppBar
            style={{
              backgroundColor: "#fff",
              boxShadow: "0 3px 6px 0 rgb(58 72 80 / 20%)",
              color: "#333",
              zIndex: "10",
            }}
          >
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="open drawer"
                sx={{ mr: 2 }}
                onClick={toggleDrawer(true)}
              >
                <MenuIcon color="#333" />
              </IconButton>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ display: { xs: "none", sm: "block" } }}
              >
                MUI
              </Typography>
              <div className="logo_image">
                <Link to="/home">
                  <img
                    src={LogoImage}
                    height="30px"
                    style={{ marginTop: "7px" }}
                  />
                </Link>
              </div>

              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ display: { xs: "flex", md: "flex" } }}>
                <IconButton
                  size="large"
                  aria-label="show 4 new mails"
                  color="inherit"
                  onClick={() => {
                    setshowsearchmodal(true);
                  }}
                >
                  <SearchIcon />
                </IconButton>
                <IconButton
                  size="large"
                  aria-label="show 4 new mails"
                  color="inherit"
                >
                  <Badge badgeContent={1} color="error">
                    <FavoriteBorderIcon />
                  </Badge>
                </IconButton>
                <IconButton
                  size="large"
                  aria-label="show 17 new notifications"
                  color="inherit"
                >
                  <Badge badgeContent={2} color="error">
                    <LocalMallIcon />
                  </Badge>
                </IconButton>
                {/* <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton> */}
              </Box>
            </Toolbar>
          </AppBar>
        </HideOnScroll>
      </Box>
      <Drawer
        anchor={"left"}
        open={showdrawer}
        onClose={toggleDrawer("left", false)}
      >
        {list("left")}
      </Drawer>
    </>
  );
}
