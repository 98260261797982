import { Col, Row } from "antd";
import { Link } from "react-router-dom";

const Womenmegamenu = () => {
  return (
    <div className="desktop-backdropStyle">
      <div className="desktop-paneContent">
        <Row>
          <Col>
            <li className="desktop-oddColumnContent">
              <ul className="desktop-navBlock">
                <li>
                  <Link to="/listing/women" className="desktop-categoryName">
                    Women's Glasses{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/listing/women" className="desktop-categoryLink">
                    Shop All Glasses
                  </Link>
                </li>
                <li>
                  <Link to="/listing/women" className="desktop-categoryLink">
                    Sunglasses
                  </Link>
                </li>
                <li>
                  <Link to="/listing/women" className="desktop-categoryLink">
                    Eye Glasses
                  </Link>
                </li>
                <li>
                  <Link to="/listing/women" className="desktop-categoryLink">
                    Contact Lens
                  </Link>
                </li>
                <li>
                  <Link to="/listing/women" className="desktop-categoryLink">
                    {" "}
                    Computer Glasses
                  </Link>
                </li>

                <li>
                  <a href="" className="desktop-categoryLink">
                    Protective Eyewear
                  </a>
                </li>
                <div className="desktop-hrLine"></div>
                <li>
                  <a href="" className="desktop-categoryLink">
                    {" "}
                    Designer Glasses
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    {" "}
                    Best Sellers
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    {" "}
                    New Arrivals
                  </a>
                </li>
              </ul>
            </li>
          </Col>
          <Col>
            <li className="desktop-oddColumnContent">
              <ul className="desktop-navBlock">
                <li>
                  <a href="" className="desktop-categoryName">
                    Shop By Frame Shape
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Rectangle
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Square
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Round
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Cat Eye
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    {" "}
                    Oval
                  </a>
                </li>

                <li>
                  <a href="" className="desktop-categoryLink">
                    Geometric
                  </a>
                </li>
                <div className="desktop-hrLine"></div>
                <li>
                  <a href="" className="desktop-categoryLink">
                    {" "}
                    Browline
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    {" "}
                    Aviator
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    {" "}
                    Wrap
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    {" "}
                    Classic Wayframe
                  </a>
                </li>

                <li>
                  <a href="" className="desktop-categoryLink">
                    {" "}
                    Oversized
                  </a>
                </li>
              </ul>
            </li>
          </Col>
          <Col>
            <li className="desktop-oddColumnContent">
              <ul className="desktop-navBlock">
                <li>
                  <a href="" className="desktop-categoryName">
                    Shop By Face Shape
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Oval
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Square
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Diamond
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Round
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    {" "}
                    Heart
                  </a>
                </li>

                <li>
                  <a href="" className="desktop-categoryLink">
                    Triangle
                  </a>
                </li>
                <div className="desktop-hrLine"></div>
              </ul>
            </li>
          </Col>
          <Col>
            <li className="desktop-oddColumnContent">
              <ul className="desktop-navBlock">
                <li>
                  <a href="" className="desktop-categoryName">
                    Shop By Color
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Tortoise
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Black
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Clear
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Gold
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    {" "}
                    Brown
                  </a>
                </li>

                <li>
                  <a href="" className="desktop-categoryLink">
                    Blue
                  </a>
                </li>
                <div className="desktop-hrLine"></div>
                <li>
                  <a href="" className="desktop-categoryLink">
                    {" "}
                    Silver
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Blue
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Gray
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Pink
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    View all
                  </a>
                </li>
              </ul>
            </li>
          </Col>
          <Col>
            <li className="desktop-oddColumnContent">
              <ul className="desktop-navBlock">
                <li>
                  <a href="" className="desktop-categoryName">
                    Shop By Frame Type
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Full frame
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Light weight
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Rimless
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Half frame
                  </a>
                </li>
              </ul>
            </li>
          </Col>
          <Col>
            <li className="desktop-oddColumnContent">
              <ul className="desktop-navBlock">
                <li>
                  <a href="" className="desktop-categoryName">
                    Shop By Brand
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Ray-Ban
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Oakley
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    DKNY
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Persol
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    {" "}
                    Prada
                  </a>
                </li>

                <li>
                  <a href="" className="desktop-categoryLink">
                    Versace
                  </a>
                </li>
                <div className="desktop-hrLine"></div>
                <li>
                  <a href="" className="desktop-categoryLink">
                    {" "}
                    Coach
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Gucci
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Calvin Klien
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Vogue
                  </a>
                </li>
                <li>
                  <a href="" className="desktop-categoryLink">
                    Shop all
                  </a>
                </li>
              </ul>
            </li>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default Womenmegamenu;
