import styled from "styled-components";

const StyledBrandCard = styled.div`
  // background-color: #f1f1f1;
  border-radius: 10px;
  margin: 0px;
  height: 45px;
  display: flex;
  justify-content: center;
  padding: 5px;
  .shop_text {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img {
    width: 100%;
  }
`;

export default StyledBrandCard;
