import styled from "styled-components";

const StyledColorComponent = styled.div`

  h1 {
    font-size: 15.48px;
    font-weight: 700;
  }
  .color_palette {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 150px;
    padding: 1rem 0;
  }
  @media screen and (max-width:900px){
    .color_sec{
      margin-left:25px;
    }
  }
`;

export default StyledColorComponent;
