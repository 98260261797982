import styled from "styled-components";

const StyledImage = styled.div`
  .slect-image {
    margin: 0rem 0.4rem 3rem !important;
    img {
      // border-radius: 13px;
      display: block;
      // max-width: 500px;
      width: auto;
      height: auto;
    }
  }
`;

export default StyledImage;
