import { Button, Card, Modal, notification, Row } from "antd"
import Title from "antd/lib/typography/Title";
import { useEffect, useState } from "react";
import { httpGet, httpPost } from "../../networkutils/networkutils";
import StyledMyOrder from "./Components/StyledMyOrderScreen"
import Moment from 'react-moment';

const MyOrderScreen = () => {
    const [orders, setorders] = useState([]);
    const [visible, setvisible] = useState(false);
    const [orderID, setorderID] = useState();
    useEffect(() => {
        getmyOrders().then((res) => {
            console.log(res);
            if (res["status"] === "SUCCESS") {
                setorders([...res["data"]]);
            }
        });
    }, [])
    const getmyOrders = () => {
        return new Promise((resolve, reject) => {
            httpGet("myorders/get").then((res) => {
                resolve(res);
            })
                .catch((err) => {
                    resolve({ status: "ERROR" })
                })
        })
    }
    const showModal = () => {
        setvisible(true)
    };
    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: message,
            description:
                '',
        });
    };

    const hideModal = (action) => {
        if (action === "OK") {
            const body = {
                order_id: orderID
            }
            httpPost("myorders/cancel", body).then((res) => {
                if (res["status"] === "SUCCESS") {
                    openNotificationWithIcon("success", "Your order is cancelled");
                    getmyOrders().then((res) => {
                        console.log(res);
                        if (res["status"] === "SUCCESS") {
                            setorders([...res["data"]]);
                        }
                    });
                }
            })
        }
        setvisible(false)
    };

    return (
        <StyledMyOrder>
            <Modal
                title="Are you sure want to Cancel your Order"
                visible={visible}
                onOk={() => {
                    hideModal("OK")
                }}
                onCancel={hideModal}
                okText="Confirm"
                cancelText="Cancel"
            >

            </Modal>
            <Row xs={24}>
                <Title level={5}>My Orders</Title>
            </Row>
            {
                orders.map((value) => {
                    return <Row gutter={[24, 0]}>
                        <Card xs="24" xl={24} style={{ marginTop: "1rem" }}>
                            <div className="row">
                                <div className="col-md-2"><img src={value["primary_image"]} width="100%" />
                                </div>
                                <div className="col-md-10">
                                    <div className="row">
                                        <div className="col-md-4" >
                                            <h2 className="product_name"> {value["name"]} </h2>
                                            <p className="product_subtitle">{value["sub_title"]}</p>
                                        </div>
                                        <div className="col-md-4">
                                            <h2 className="price">AED {value["order_price"]}</h2>
                                        </div>
                                        <div className="col-md-4">
                                            {(value["cancel"] == 0  || value["cancel"] == null)? (<>
                                                <h2 className="order_status" style={{ color: "green" }}> {value["status"]}</h2>
                                                <h2 className="update_date">
                                                    <Moment fromNow ago>{value["status_updated_at"]}</Moment> ago
                                                </h2> <br />
                                                <Button onClick={() => {
                                                    setorderID(value["order_id"]);
                                                    showModal();
                                                }}>Cancel Order</Button>
                                            </>) : (
                                                <>
                                                    <h2 className="order_status" style={{ color: "red" }}> {value["cancel_status"]}</h2>
                                                    <h2 className="update_date">
                                                        <Moment fromNow ago>{value["status_updated_at"]}</Moment> ago
                                                    </h2> <br />
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </Card>
                    </Row>;
                })
            }
        </StyledMyOrder>
    )
}
export default MyOrderScreen;