import React from "react";
import StyledTransCard from "./StyledTransCard";
import StyledButton from "../../../Components/StyledButton";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";

const TransCard = (props) => {
  return (
    <StyledTransCard>
      {window.screen.width > 900 ? (
        <>
          <div className="trans_bottom" style={{ position: "relative" }}>
            <img
              sty
              src={props.payload.image}
              className="default-boxshadow"
              style={{ borderRadius: "13px" }}
            />
            <Grid container>
              <Grid
                item
                xs={6}
                style={{ position: "absolute", bottom: "10px" }}
              >
                <h1 className="trans_text">{props.payload.title}</h1>
                {props.payload.desc == null ? null : (
                  <p className="trans_desc">{props.payload.desc}</p>
                )}
                <Link to="/listing/main">
                  <div className="shop-btn">{props.payload.btn_title}</div>
                </Link>
              </Grid>
            </Grid>
          </div>
        </>
      ) : (
        <>
          <div className="trans_sec_mob">
            <img className="trans-img-mob" src={props.payload.image} />
            <h1 className="trans_text_mob">{props.payload.title}</h1>
          </div>
        </>
      )}
    </StyledTransCard>
  );
};

export default TransCard;
