import styled from "styled-components";


const StyledWishlistScreen = styled.div`
    padding:2rem 0;
    .prod_name{
        margin-top:4rem !important;
        font-size:16px;
        font-weight:500;
    }
    .prod_price{
        font-size:14px;
        font-weight:500;
        color:#404040;
    }

`
export default StyledWishlistScreen;