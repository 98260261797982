import React from "react";
import StyledHeader from "./StyledHeader";
import uaeflag from "../../assets/icons/uaeflag.svg";
import usaflag from "../../assets/icons/usa_flg.png";
import fastdelivery from "../../assets/icons/fastdelivery.svg";
import genuine from "../../assets/icons/genuine.svg";
import { Row, Col } from "antd";

const Header = () => {
  return window.screen.width > 900 ? (
    <StyledHeader>
      <div className="right_box">
        <Row justify="right">
          <div className="right_box_items">
            <img src={fastdelivery} />
            Fast Delivery & Exchanges
          </div>
          <div className="right_box_items">
            <img src={genuine} />
            100% Genuine Brands
          </div>
          <div className="right_box_items">
            ةيبرعلا <img src={uaeflag} />
          </div>
        </Row>
      </div>
    </StyledHeader>
  ) : (
    <></>
  );
};

export default Header;
