import styled from "styled-components";

const StyledEyeWearText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem;
  .main_title {
    font-size: 48px;
    color: #404040;
    font-weight: 400;
  }
  .sub_title {
    align-text: "center";
    color: #404040;
    font-size: 25px;
    font-weight: 400;
    span {
      font-weight: 500;
    }
  }
  .main_title_mob {
    font-size: 25px;
    color: #404040;
    font-weight: 400;
  }
  . .sub_title {
    align-text: "center";
    color: #404040;
    font-size: 25px;
    font-weight: 400;
    span {
      font-weight: 500;
    }
  }
  .sub_title_mob {
    text-align: center;
    color: #404040;
    font-size: 15px;
    font-weight: 400;
    span {
      font-weight: 500;
    }
  }
`;

export default StyledEyeWearText;
