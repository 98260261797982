import React from "react";
import StyledImage from "./StyledImage";
const ImageContainer = (props) => {
  return (
    <StyledImage>
      <div className="slect-image" style={{ paddingLeft: "2.2rem" }}>
        <img
          width="100%"
          style={{
            margin: "0 auto",
            // padding: "0rem 4rem 0rem 1rem"
          }}
          src={props.src}
        />
      </div>
    </StyledImage>
  );
};

export default ImageContainer;
