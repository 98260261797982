import React from "react";

import StyledFrameComponent from "./StyledFrameComponent";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
const FrameShapeComponent = () => {
  const frameShapes = [
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/frame-shape%2Faviator.png?alt=media&token=cd8bc6ee-1e33-4fc8-a119-c60f7e1fda50",
      title: "Aviator",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/frame-shape%2Fbrowline.png?alt=media&token=1a21c94c-74eb-4067-adbb-e072c9032dcd",
      title: "Browline",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/frame-shape%2Fcateye.png?alt=media&token=1a21c94c-74eb-4067-adbb-e072c9032dcd",
      title: "Cat-Eye",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/frame-shape%2Fgeometric.png?alt=media&token=1a21c94c-74eb-4067-adbb-e072c9032dcd",
      title: "Geometric",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/frame-shape%2Foval.png?alt=media&token=1a21c94c-74eb-4067-adbb-e072c9032dcd",
      title: "Oval",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/frame-shape%2Frectangle.png?alt=media&token=1a21c94c-74eb-4067-adbb-e072c9032dcd",
      title: "Rectangle",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/frame-shape%2Fround.png?alt=media&token=1a21c94c-74eb-4067-adbb-e072c9032dcd",
      title: "Round",
    },
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/kareyeapp.appspot.com/o/frame-shape%2Fsquare.png?alt=media&token=1a21c94c-74eb-4067-adbb-e072c9032dcd",
      title: "Square",
    },
  ];
  return (
    <StyledFrameComponent>
      <div className="container">
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Link to="/listing/main">
              <div className="face_img">
                <img src={frameShapes[0]["image"]} alt="" />
                <h6 className="text">{frameShapes[0]["title"]}</h6>
              </div>
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link to="/listing/main">
              <div className="face_img">
                <img src={frameShapes[1]["image"]} alt="" />
                <h6 className="text">{frameShapes[1]["title"]}</h6>
              </div>
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link to="/listing/main">
              <div className="face_img">
                <img src={frameShapes[2]["image"]} alt="" />
                <h6 className="text">{frameShapes[2]["title"]}</h6>
              </div>
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link to="/listing/main">
              <div className="face_img">
                <img src={frameShapes[3]["image"]} alt="" />
                <h6 className="text">{frameShapes[3]["title"]}</h6>
              </div>
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link to="/listing/main">
              <div className="face_img">
                <img src={frameShapes[4]["image"]} alt="" />
                <h6 className="text">{frameShapes[4]["title"]}</h6>
              </div>
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link to="/listing/main">
              <div className="face_img">
                <img src={frameShapes[5]["image"]} alt="" />
                <h6 className="text">{frameShapes[5]["title"]}</h6>
              </div>
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link to="/listing/main">
              <div className="face_img">
                <img src={frameShapes[6]["image"]} alt="" />
                <h6 className="text">{frameShapes[6]["title"]}</h6>
              </div>
            </Link>
          </Grid>
          <Grid item xs={4}>
            <Link to="/listing/main">
              <div className="face_img">
                <img src={frameShapes[7]["image"]} alt="" />
                <h6 className="text">{frameShapes[7]["title"]}</h6>
              </div>
            </Link>
          </Grid>
        </Grid>
      </div>
    </StyledFrameComponent>
  );
};

export default FrameShapeComponent;
