import { Button, Form, Input, notification, Radio, Select, Spin, Steps } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signin, ValidateOtp } from "../../Firebase/auth";
import { auth } from "../../Firebase/Config";
import { httpGet, httpPost } from "../../networkutils/networkutils";
import { setcartproducts } from "../../redux-tool/features/product-features";
import StyledCheckoutScreen from "./StyledCheckoutscreen";

const CheckoutScreen = () => {
    const navigate = useNavigate();
    const [verifyspinning, setverifyspinning] = useState(false);
    const [countrycode, setcountryCode] = useState("+91");
    const [mobilenumber, setmobilenumber] = useState("");
    const [showotp, setshowotp] = useState(false);
    const [otp, setotp] = useState("");
    const [fireresult, setfireresult] = useState("");

    const [form] = Form.useForm()
    const formRef = React.createRef();
    const [current, setCurrent] = useState(0);
    const [spinning, setspinning] = useState(false);
    const dispatch = useDispatch();
    const getcartproducts = useSelector((state) => state.setproductData.cartProducts);
    useEffect(() => {
        if (auth.currentUser) {
            setCurrent(1);
            httpGet("profile/getaddress").then((res) => {
                if (res["status"] === "SUCCESS") {
                    form.setFieldsValue({
                        ...res["data"],
                        mobileno: res["data"]["mobile_no"]
                    })
                }
            })
        } else {
            setCurrent(0);
        }

    }, []);

    const { Step } = Steps;
    const { Option } = Select;

    const steps = [
        {
            title: 'Verify Mobile',
            content: 'login-sec',
        },
        {
            title: 'Shipping Address',
            content: 'shipping-sec',
        },
        {
            title: 'Payment ',
            content: 'payment-sec',
        },
    ];

    const validateMessages = {
        required: '${label} is required!',
        types: {
            email: '${label} is not a valid email!',
            number: '${label} is not a valid number!',
        },
        number: {
            range: '${label} must be between ${min} and ${max}',
        },
    };
    const openNotificationWithIcon = (type, message) => {
        notification[type]({
            message: message,
            description:
                '',
        });
    };

    const extractids = () => {
        return new Promise((resolve, reject) => {
            var items = [];
            getcartproducts.forEach((value, index) => {
                items.push({
                    id: value["productID"],
                    qty: value["cart_quantity"]
                });
                if (index === getcartproducts.length - 1) resolve(items);
            });
        })
    }
    const onFinish = (values) => {
        setspinning(true);
        httpPost("cart/addaddress", values).then((res) => {
            setspinning(false);
            if (res["status"] === "SUCCESS") {
                openNotificationWithIcon("success", "Address updated");
                httpPost("cart/checkout").then((res) => {
                    console.log(res);
                    if (res["status"] === "SUCCESS") {
                        openNotificationWithIcon("success", "Product placed successfully");
                        dispatch(setcartproducts([]))
                    } else {
                        openNotificationWithIcon("error", "Could not connect");
                    }
                })
            } else {
                openNotificationWithIcon("error", "Could not connect")
            }
        })
    };
    return (
        <StyledCheckoutScreen>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <Steps current={current}>
                            {steps.map(item => (
                                <Step key={item.title} title={item.title} />
                            ))}
                        </Steps><br />
                        {current === 1 ? <Spin spinning={spinning} tip="Loading...">
                            <div className="steps-content">
                                <Form form={form} ref={formRef} validateMessages={validateMessages} onFinish={onFinish}>
                                    <div className="row">
                                        <div className="col">
                                            <Form.Item name="first_name" rules={[{ required: true }]}>
                                                <Input style={{ width: '100%' }} placeholder="First Name" />
                                            </Form.Item>
                                        </div>
                                        <div className="col">
                                            <Form.Item name="last_name" rules={[{ required: true }]}>
                                                <Input style={{ width: '100%' }} defaultValue="" placeholder="Last Name" />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <Form.Item name="gender" rules={[{ required: true }]}>
                                                <Radio.Group onChange={() => {
                                                }} value="">
                                                    <Radio value="male">Male</Radio>
                                                    <Radio value="female">Female</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <Form.Item name="mobileno" rules={[{ required: true, type: '' }]} >
                                                <Input style={{ width: '100%' }} defaultValue="" placeholder="Mobile*" />
                                            </Form.Item>
                                        </div>
                                        <div className="col">
                                            <Form.Item name="email" rules={[{ required: true, type: 'email' }]} >
                                                <Input style={{ width: '100%' }} defaultValue="" placeholder="Email*" />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <Form.Item name="address1" rules={[{ required: true, }]} >
                                                <Input style={{ width: '100%' }} defaultValue="" placeholder="Address Line 1*" />
                                            </Form.Item>
                                        </div>
                                        <div className="col">
                                            <Form.Item name="address2" rules={[{ required: false }]} >
                                                <Input style={{ width: '100%' }} defaultValue="" placeholder="Address Line 1" />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <Form.Item name="zip" rules={[{ required: true, }]} >
                                                <Input style={{ width: '100%' }} defaultValue="" placeholder="Zip/Postal code" />
                                            </Form.Item>
                                        </div>
                                        <div className="col">
                                            <Form.Item name="city" rules={[{ required: true, }]} >
                                                <Input style={{ width: '100%' }} defaultValue="" placeholder="City/District" />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <Form.Item name="country" rules={[{ required: true }]} >
                                                <Input style={{ width: '100%' }} defaultValue="" placeholder="Country" />
                                            </Form.Item>
                                        </div>
                                        <div className="col">
                                            <Form.Item name="state" rules={[{ required: true }]} >
                                                <Input style={{ width: '100%' }} defaultValue="" placeholder="State/Province" />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    {/* <Form.Item name="address" rules={[{ required: true }]} >
                                    <TextArea placeholder="Address" autoSize />
                                </Form.Item> */}
                                    <Form.Item>
                                        <Button
                                            htmlType="submit"
                                            style={{ width: "100%", marginTop: "1rem", backgroundColor: "#1A1A1A", color: "#fff" }}
                                        >SUBMIT</Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </Spin> : null}
                        {
                            current === 0 ?
                                <Spin spinning={verifyspinning} tip="Loading...">
                                    <div className="container">
                                        <div className="row justify-content-center">
                                            <div className="col-md-8">
                                                <br />
                                                <Input.Group compact>
                                                    <Select onSelect={(e) => {
                                                        setcountryCode(e);
                                                    }} defaultValue={countrycode}>
                                                        <Option value="+91">+91</Option>
                                                        <Option value="+971">+971</Option>
                                                    </Select>

                                                    <Input onChange={(e) => {
                                                        setmobilenumber(e.target.value)
                                                    }} style={{ width: '80%' }} defaultValue="" placeholder="Phone number" />
                                                </Input.Group>
                                                {showotp ? <Input placeholder="OTP" onChange={(e) => {
                                                    setotp(e.target.value)
                                                }} style={{ width: '100%' }} defaultValue="" /> : null}
                                                <div id="recaptcha-container"></div>
                                                <Button
                                                    onClick={() => {
                                                        setverifyspinning(true);
                                                        if (showotp) {
                                                            ValidateOtp(fireresult, otp).then((res) => {
                                                                setverifyspinning(false);
                                                                openNotificationWithIcon("success", 'Successfully verified');
                                                                extractids().then((data) => {
                                                                    httpPost("cart/addcartbyID", { items: JSON.stringify(data) }).then((res) => {
                                                                        if (res["status"] === "SUCCESS") {
                                                                            localStorage.removeItem("cart");
                                                                            setCurrent(1);
                                                                        }
                                                                    })
                                                                })
                                                            }).catch(() => {
                                                                setverifyspinning(false);
                                                                openNotificationWithIcon("Error", "Not able to verify");
                                                            })
                                                        } else {
                                                            signin(countrycode + mobilenumber).then((result) => {
                                                                setfireresult(result);
                                                                setshowotp(true);
                                                                setverifyspinning(false);
                                                            }).catch(() => {
                                                                setverifyspinning(false);
                                                                openNotificationWithIcon("Error", "Not able to verify");
                                                            });
                                                        }

                                                    }}
                                                    style={{ width: "100%", marginTop: "1rem", backgroundColor: "#1A1A1A", color: "#fff" }}
                                                >{showotp ? "VERIFY" : "SEND OTP"}</Button>

                                            </div>
                                        </div>
                                    </div>
                                </Spin> : null
                        }

                    </div>
                </div>
            </div>
        </StyledCheckoutScreen>
    );
}
export default CheckoutScreen;