// import React, { useState } from "react";
// import StyledBanner from "./StyledBanner";
// import image1 from "../../../assets/Images/image1.png";
// import { Col, Row } from "antd";
// import freeship from "../../../assets/icons/freeship.jpg";
// import moneyback from "../../../assets/icons/money-back.png";
// import genuine from "../../../assets/icons/genuine.png";
// import StyledButton from "../../../Components/StyledButton";

// const BannerContainer = () => {
//   const [hover, setHover] = useState(false);

//   return (
//     <StyledBanner>
//       <div className="ad-banner2">
//         <img width="100%" src={image1} />
//         <div className="ad-container">
//           <h1 className="ad-container-title">Why Kareye.com</h1>
//           <div className="divider"></div>
//           <Row className="point-row">
//             <Col xs={8}>
//               <div className="point-icon">
//                 <img src={freeship} />
//               </div>
//             </Col>
//             <Col xs={16}>
//               <div>
//                 <h1 className="grid-title">Free shipping & returns</h1>
//                 <p className="grid-title2">No minimum required.</p>
//               </div>
//             </Col>
//           </Row>
//           <Row className="point-row">
//             <Col xs={8}>
//               <div className="point-icon">
//                 <img src={moneyback} />
//               </div>
//             </Col>
//             <Col xs={16}>
//               <div>
//                 <h1 className="grid-title">100% money back guarantee</h1>
//                 <p className="grid-title2">14 days for full refund.</p>
//               </div>
//             </Col>
//           </Row>
//           <Row className="point-row">
//             <Col xs={8}>
//               <div className="point-icon">
//                 <img src={genuine} />
//               </div>
//             </Col>
//             <Col xs={16}>
//               <div>
//                 <h1 className="grid-title">100% Genuine Products</h1>
//                 <p className="grid-title2">With manufacturer's warranty.</p>
//               </div>
//             </Col>
//           </Row>
//           <Row>
//             <Col xs={22}>
//               <StyledButton
//                 onMouseEnter={() => {
//                   setHover(true);
//                 }}
//                 onMouseLeave={() => {
//                   setHover(false);
//                 }}
//                 style={{
//                   ...(hover
//                     ? {
//                         background: "#25d366",
//                         margin: "0px 4rem 0 3.2rem",
//                         padding: "4px",
//                       }
//                     : {
//                         margin: "0px 4rem 0 3.2rem",
//                         padding: "4px",
//                       }),
//                 }}
//               >
//                 Get Connected By WhatsApp
//               </StyledButton>
//             </Col>
//           </Row>
//         </div>
//       </div>
//     </StyledBanner>
//   );
// };

// export default BannerContainer;
import React, { useState } from "react";
import StyledBanner from "./StyledBanner";
import image1 from "../../../assets/Images/image1.png";
import freeship from "../../../assets/icons/freeship.jpg";
import moneyback from "../../../assets/icons/money-back.png";
import genuine from "../../../assets/icons/genuine.png";
import StyledButton from "../../../Components/StyledButton";
import { Grid } from "@mui/material";

const BannerContainer = () => {
  const [hover, setHover] = useState(false);

  return window.screen.width > 900 ? (
    <StyledBanner>
      <div className="ad-banner2">
        <img width="100%" src={image1} />
        <div className="ad-container">
          <h1 className="ad-container-title">Why Kareye.com</h1>
          <div className="divider"></div>
          <Grid container className="point-row" justifyContent="center">
            <Grid item className="" xs={4}>
              <div className="point-icon">
                <img src={freeship} />
              </div>
            </Grid>
            <Grid xs={8}>
              <div>
                <h1 className="grid-title">Free shipping & returns</h1>
                <p className="grid-title2">No minimum required.</p>
              </div>
            </Grid>
          </Grid>

          <Grid container className="point-div">
            <Grid item xs={4}>
              <div className="point-icon">
                <img src={moneyback} />
              </div>
            </Grid>
            <Grid item xs={8}>
              <div>
                <h1 className="grid-title">100% money back guarantee</h1>
                <p className="grid-title2">14 days for full refund.</p>
              </div>
            </Grid>
          </Grid>

          <Grid container className="point-div">
            <Grid item xs={4}>
              <div className="point-icon">
                <img src={genuine} />
              </div>
            </Grid>
            <Grid item xs={8}>
              <div>
                <h1 className="grid-title">100% Genuine Products</h1>
                <p className="grid-title2">With manufacturer's warranty.</p>
              </div>
            </Grid>
          </Grid>
          <div className="row">
            <div className="col" xs={22}>
              <StyledButton
                onMouseEnter={() => {
                  setHover(true);
                }}
                onMouseLeave={() => {
                  setHover(false);
                }}
                style={{
                  ...(hover
                    ? {
                        background: "#25d366",
                        margin: "0px 4rem 0 3.2rem",
                        padding: "10px",
                      }
                    : {
                        margin: "0px 4rem 0 3.2rem",
                        padding: "10px",
                      }),
                }}
              >
                Get Connected By WhatsApp
              </StyledButton>
            </div>
          </div>
        </div>
      </div>
    </StyledBanner>
  ) : (
    <StyledBanner>
      <div
        className="ad-banner2"
        style={{
          backgroundColor: "#F6F6F6",
          padding: "20px 10px",
          margin: "0 0 10px 0",
        }}
      >
        <h1
          className="ad-container-title-mob"
          style={{ margingBottom: "1rem" }}
        >
          Why Kareye.com
        </h1>
        <Grid container>
          <div className="divider"></div>
          <Grid container>
            <Grid item xs={2}>
              <div className="point-icon">
                <img src={freeship} />
              </div>
            </Grid>
            <Grid item xs={10}>
              <div className="ad-caption-mob">
                <h1 className="grid-title">Free shipping & returns</h1>
                <p className="grid-title2">No minimum required.</p>
              </div>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={2}>
              <div className="point-icon">
                <img src={moneyback} />
              </div>
            </Grid>
            <Grid item xs={10}>
              <div className="ad-caption-mob">
                <h1 className="grid-title">100% money back guarantee</h1>
                <p className="grid-title2">14 days for full refund.</p>
              </div>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={2}>
              <div className="point-icon">
                <img src={genuine} />
              </div>
            </Grid>
            <Grid item xs={10}>
              <div className="ad-caption-mob">
                <h1 className="grid-title">100% Genuine Products</h1>
                <p className="grid-title2">With manufacturer's warranty.</p>
              </div>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <div
                className="get-connect-whatsapp-btn"
                onMouseEnter={() => {
                  setHover(true);
                }}
                onMouseLeave={() => {
                  setHover(false);
                }}
                style={{
                  ...(hover
                    ? {
                        background: "#25d366",
                        padding: "10px",
                      }
                    : {
                        padding: "10px",
                      }),
                }}
              >
                Get Connected By WhatsApp
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </StyledBanner>
  );
};

export default BannerContainer;
