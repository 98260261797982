import styled from "styled-components";

const StyledFooterMain = styled.div`
  padding: 2rem 0;
  @media screen and (max-width: 900px) {
    background-color: #404040;
    padding-bottom:0;
  }
  .logo-section {
    padding-left: 2rem;
  }
  .ship_text_newsletter {
    display: flex;
    justify-content: space-around;
  }
  .logo {
    margin-left: 1.5rem;
    img {
      margin-bottom: 1rem;
    }
  }
  .socialmedia {
    margin-left: 1.2rem;
    img {
      margin: 0.2rem;
    }
  }
  .ship_text_logo {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    .ship_text {
      font-size: 15px;
    }
  }
  .footer_menu{
    text-align:center;
    text-transform:uppercase;
  }
  .subscribe_newsltr {
    display: flex;
    justify-content: space-around;
  }
  .subscribe-btn {
    background-color: #404040;
    padding: 2px 4px;
    margin-left: 10px;
    cursor: pointer;
    p {
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 0px;
    }
  }
  .subsrcibe_newletr_title {
    text-align: center;
    color: #333;
    margin-bottom: 0px;
    margin-top: 15px;
  }
  .subsrcibe_newletr_input {
    border: none;
    color: #333;
    border-radius: 13px;
    margin-top: 10px;
    padding: 10px 4px;
    background-color: #F2F4F7;
  }
  .subscribe-btn {
    border-radius: 13px;
    margin-top: 10px;
    background-color: #404040;
    padding: 10px 4px;
    margin-left: 0px;
    cursor: pointer;
    p {
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 0px;
      text-align: center;
    }
  }
  @media screen and (max-width: 900px) {
    .subscribe-btn {
      border-radius: 13px;
      margin-top: 10px;
      background-color: #fff;
      padding: 10px 4px;
      margin-left: 0px;
      cursor: pointer;
      p {
        color: #333;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 0px;
        text-align: center;
      }
    }
    .float{
      position:fixed;
      width:60px;
      height:60px;
      bottom:40px;
      right:40px;
      background-color:#404040;
      color:#FFF;
      border-radius:50px;
      text-align:center;
      font-size:30px;
      // box-shadow: 2px 2px 3px #999;
      z-index:100;
    }
    
    .my-float{
      margin-top:16px;
    }
    .social-media-img {
      margin: 0 auto;
      padding: 2px;
      height: 30px;
      width: 30px;
      border-radius: 30px;
    }
    .subsrcibe_newletr_title {
      text-align: center;
      color: #fff;
      margin-bottom: 0px;
      margin-top: 15px;
    }
    .subsrcibe_newletr_form input:: placeholder{
      color:#fff;
      padding-left:5px;
    }
    .subsrcibe_newletr_input {
      border: none;
      color: #fff;
      border-radius: 13px;
      margin-top: 10px;
      padding: 10px 4px;
      background-color: #666666;
    }
    .terms {
      padding:1rem 0;
      display: block !important;
      margin-left: 0px !important;
      .terms_privacy {
        margin-left: 0rem !important;
        color: #404040;
        font-size: 10px;
        font-weight: 500;
      }
      .terms_copyright {
        margin-right: 0rem !important;
        color: #404040;
        font-size: 10px;
        font-weight: 500;
      }
  }
  .news_letter {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    /* align-items:center ; */
    input {
      border: none;
      border-bottom: 1px solid black;
      width: 100%;
      /* padding: 0.5rem; */
    }
    .payment_text {
      font-weight: 400;
    }
    .payment_text_image {
      .payment_image {
        display: flex;
        img {
          width: 100%;
          max-width: 40px;
          margin: 0.2rem;
        }
      }
    }
  }
  .footer_main_menu {
    border-top: 0.1px solid #c4c4c4;
    border-bottom: 0.5px solid #c4c4c4;
    margin: 2rem 0;
    padding: 1.5rem;
    display: flex;
    justify-content: center;
    .footer_menu {
      cursor: pointer;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .terms {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 3rem;
    .terms_privacy {
      margin-left: 0.5rem !important;
      color: #404040;
      font-size: 10px;
      font-weight: 500;
    }
    .terms_copyright {
      margin-left: 0.5rem !important;
      color: #404040;
      font-size: 10px;
      font-weight: 500;
    }
  }
`;

export default StyledFooterMain;
