import React from "react";
import StyledDiscoverMore from "./StyledDiscoverMore";

const DiscoverMore = () => {
  return (
    <StyledDiscoverMore>
      <h1 className="">Still Looking? Discover more below</h1>
    </StyledDiscoverMore>
  );
};

export default DiscoverMore;
