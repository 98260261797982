import { Row, Col } from "antd";
import React, { useEffect } from "react";
import StyledMainCart from "./StyledMainCart";
import cartImage from "../../../assets/cartimage.png";
import { Button } from "antd";
import DescriptionComponent from "./DescriptionComponent";
import { useDispatch } from "react-redux";
import { setquantity } from "../../../redux-tool/features/product-features";

const MainCart = (props) => {
  const dispatch  = useDispatch();
  const data = props.data;
  useEffect(()=>{
    dispatch(setquantity(data["cart_quantity"]));
  },[])
  return (
    <StyledMainCart>
      <div className="row" justify="center">
        <div className="col-1"></div>
        <div className="col-6" span={12}>
          <div className="image_section">
            <img
              style={{ width: "100%",padding:"5px" }}
              className="cartimage"
              src={data.primary_image}
            />

            <div className="button_controls">
              <Button style={{ padding: " 0 2rem" }}>Remove</Button>
              {/* <Button style={{ padding: " 0 2rem" }}>Edit</Button> */}
            </div>
          </div>
        </div>
        <div className="col-5"  span={12}>
          <DescriptionComponent data  ={data} index = {props.index} />
        </div>
      </div>
    </StyledMainCart>
  );
};

export default MainCart;
