import styled from "styled-components";

const StyledCartScreen = styled.div`
.chckout-total{
    margin-bottom:0px;
    font-size:14px;
    font-weight:500;
    color:#404040;
}
.chckout-price{
    margin-bottom:0px;
    font-size:16px;
    font-weight:500;
    color:#404040;
}
.order_button{
   
    }
    .checkout-btn{
        margin: 1rem 1.5rem;
        background-color: #404040;
        color: #fff;
        width:65%;
        border-radius: 10px;
        height:3rem;
        span{
            font-size:16px;
            font-weight:500;
        }
}
`;

export default StyledCartScreen;
