import { Button, Card, Col, Form, Input, Modal, Row, Spin } from "antd";
import Title from "antd/lib/typography/Title";
import StyledProfileScreen from "./StyledProfileScreen"
import ProfileAvatar from '../../assets/icons/profile_avatar.png'
import { httpGet, httpPost } from "../../networkutils/networkutils";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
const ProfileScreen = () => {
    const navigate = useNavigate();
    const [profileData, setprofileData] = useState({
        name: "",
        mobile_number: "",
        email: ""

    });
    const [visible, setVisible] = useState(false);
    const [spinning, setspinning] = useState(false);
    useEffect(() => {
        getData().then((res) => {
            console.log(res);
            if (res != "Error") {
                setprofileData(res["data"]);
            }
        })
    }, [])

    const getData = () => {
        return new Promise((resolve, reject) => {
            httpGet("profile/get").then((res) => {
                resolve(res)
            })
                .catch("Error")
        })
    }
    const handleOk = (e) => {
        setVisible(false);
      };
    const handleCancel = (e) => {
        setVisible(false);
      }; const { TextArea } = Input;
    
      const formRef = React.createRef();
      const validateMessages = {
        required: '${label} is required!',
        types: {
          email: '${label} is not a valid email!',
          number: '${label} is not a valid number!',
        },
        number: {
          range: '${label} must be between ${min} and ${max}',
        },
      };
      
      const onFinish = (values) => {
        setspinning(true);
        var body = {
          "name": values["name"],
          "email": values["email"],
          "address": values["address"]
        };
        httpPost("cart/addaddress", body).then((res) => {
          setspinning(false);
          if (res["status"] === "SUCCESS") {
           
          } else {
          }
        })
      };
    
    return (<>
        <StyledProfileScreen>
        <Modal
        title=""
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{ disabled: true }}
        cancelButtonProps={{ disabled: true }}
        footer={null}
        width={500}
      >
        <Spin spinning={spinning} tip="Loading...">
          <div className="login-modal">
            <Row justify="center">
              <Col xs={20}>
                <Title className="modal-title" level={2} style={{ textAlign: "center", marginBottom: "1rem !important" }}>Profile</Title>
                <br />
                <Form ref={formRef} validateMessages={validateMessages} initialValues = {{
                    name:profileData["name"],
                    email:profileData["email"],
                    address:profileData["address"]
                }} onFinish={onFinish}>
                  <Form.Item name="name" rules={[{ required: true }]}>
                    <Input style={{ width: '100%' }} defaultValue="" placeholder="Your Name" />
                  </Form.Item>
                  <Form.Item name="email" rules={[{ required: true, type: 'email' }]} >
                    <Input style={{ width: '100%' }} defaultValue="" placeholder="Email" />
                  </Form.Item>
                  <Form.Item name="address" rules={[{ required: true }]} >
                    <TextArea placeholder="Address" autoSize />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      htmlType="submit"
                      style={{ width: "100%", marginTop: "1rem", backgroundColor: "#1A1A1A", color: "#fff" }}
                    >SUBMIT</Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </div>
        </Spin>
      </Modal>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <div className="row justify-content-center">
                            <div className="col-md-6 justify-content-center">
                                <Card>
                                    <div className="text-center">
                                        <img src={ProfileAvatar} width="90px" height="90px" />
                                    </div>
                                    <h3 className="text-center">{profileData["name"]}</h3>
                                    <p className="txt-phone text-center">{profileData["mobile_number"]}</p>
                                    <p className="txt-email text-center">{profileData["email"]}</p>
                                    <div onClick={()=>{
                                       // setVisible(true);
                                    }} className="manage-addrs text-center">
                                        <p className="m-1">Manage Profile</p>
                                    </div> <br/>
                                    <div onClick={()=>{
                                        navigate("/myorders")
                                    }} className="manage-addrs text-center">
                                        <p className="m-1">My Orders</p>
                                    </div>
                                </Card>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </StyledProfileScreen>
    </>)
}
export default ProfileScreen;