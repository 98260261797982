import React from "react";
import StyledFooterMain from "./StyledFooterMain";
import Logo from "../../../assets/Images/Logo.svg";
import facebook from "../../../assets/socialMediaIcons/fb_icon.png";
import insta from "../../../assets/socialMediaIcons/insta_icon.png";
import snapchat from "../../../assets/socialMediaIcons/snap_icon.png";
import youtube from "../../../assets/socialMediaIcons/yt_icon.png";
import ticktok from "../../../assets/socialMediaIcons/tiktok_icon.png";
import aramex from "../../../assets/socialMediaIcons/aramex-logo.png";
import visa from "../../../assets/socialMediaIcons/visa.svg";
import mastercard from "../../../assets/socialMediaIcons/MasterCard.svg";
import paypal from "../../../assets/socialMediaIcons/paypal.svg";
import applepay from "../../../assets/socialMediaIcons/applepay.svg";
import cod from "../../../assets/socialMediaIcons/cod.svg";
import { Grid } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import WhatsappIcon from "../../../assets/icons/whatsapp_icon.png";

const FooterMain = () => {
  return window.screen.width > 900 ? (
    <StyledFooterMain>
      <Grid container>
        <Grid container>
          <Grid item xs={3}>
            <div className="logo-section">
              <div className="logo">
                <img src={Logo} />
              </div>
              <div className="socialmedia">
                <img src={facebook} />
                <img src={insta} />
                <img src={snapchat} />
                <img src={youtube} />
                <img src={ticktok} />
              </div>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className="ship_text_logo">
              <h6 className="ship_text">We ship with</h6>
              <img src={aramex} width="60%" />
            </div>
          </Grid>
          <Grid item xs={3}>
            <h6 className="payment_text">Payment Methods</h6>
            <div className="payment_image">
              <img src={visa} />
              <img src={paypal} />
              <img src={mastercard} />
              <img src={applepay} />
              <img src={cod} />
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className="news_letter">
              <Grid container justifyContent="center">
                <Grid item xs={10}>
                  <p className="subsrcibe_newletr_title">
                    Subscribe to our newsletter
                  </p>
                  <div className="">
                    <input
                      className="subsrcibe_newletr_input"
                      width="100%"
                      style={{ width: "100%" }}
                      placeholder="Enter your name"
                    />
                    <input
                      className="subsrcibe_newletr_input"
                      width="100%"
                      style={{ width: "100%" }}
                      placeholder="Enter your email address"
                    />
                    <div className="subscribe-btn">
                      <p>SUBSCRIBE</p>
                    </div>
                  </div>
                  <br />
                </Grid>
              </Grid>
              <br />
            </div>
          </Grid>
        </Grid>
        <div className="divider"></div>
        <Grid container justifyContent="center">
          <Grid item xs={2}>
            <div className="footer_menu">WOMEN</div>
          </Grid>
          <Grid item xs={2}>
            {" "}
            <div className="footer_menu">Men</div>
          </Grid>
          <Grid item xs={2}>
            {" "}
            <div className="footer_menu">Kids</div>
          </Grid>
          <Grid item xs={2}>
            {" "}
            <div className="footer_menu">Eye glasses</div>
          </Grid>
          <Grid item xs={2}>
            {" "}
            <div className="footer_menu">Sunglasses</div>
          </Grid>
          <Grid item xs={2}>
            {" "}
            <div className="footer_menu">CONTACT LENS</div>
          </Grid>
        </Grid>
        <div className="divider"></div>
      </Grid>
      <Grid container>
        <Grid item xs={6}>
          <p
            className="terms_copyright"
            style={{
              fontSize: "14px",
              fontWeight: "400",
              paddingLeft: "2rem",
              marginBottom: "0px",
            }}
          >
            Copyright © kareye 2022 . All Rights Reserved.
          </p>
        </Grid>
        <Grid item xs={6} justifyContent="end">
          <p
            className="terms_privacy"
            style={{
              textAlign: "end",
              fontSize: "14px",
              fontWeight: "400",
              paddingRight: "2rem",
              marginBottom: "0px",
            }}
          >
            Terms & Conditions | Privacy & Cookies
          </p>
        </Grid>
      </Grid>
    </StyledFooterMain>
  ) : (
    <StyledFooterMain>
      <a
        href="https://api.whatsapp.com/send?phone=51955081075&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Varela%202."
        class="float"
        target="_blank"
      >
        <img src={WhatsappIcon} width="30px" height="30px" />
      </a>
      <Grid container>
        <Grid item xs={12}>
          <Grid container justifyItems="center" justifyContent="center">
            <Grid xs={2}>
              <div className="social-media-img">
                <img width="100%" src={facebook} />
              </div>
            </Grid>
            <Grid xs={2}>
              <div className="social-media-img">
                <img width="100%" src={insta} />
              </div>
            </Grid>
            <Grid xs={2}>
              <div className="social-media-img">
                <img width="100%" src={snapchat} />
              </div>
            </Grid>
            <Grid xs={2}>
              <div className="social-media-img">
                <img width="100%" src={youtube} />
              </div>
            </Grid>
            <Grid xs={2}>
              <div className="social-media-img">
                <img width="100%" src={ticktok} />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <br />
        <Grid container justifyContent="center">
          <Grid item xs={10}>
            <p className="subsrcibe_newletr_title">
              Subscribe to our newsletter
            </p>
            <div className="subsrcibe_newletr_form">
              <input
                className="subsrcibe_newletr_input"
                width="100%"
                style={{ width: "100%" }}
                placeholder="Enter your name"
              />
              <input
                className="subsrcibe_newletr_input"
                width="100%"
                style={{ width: "100%" }}
                placeholder="Enter your email address"
              />
              <div className="subscribe-btn">
                <p>SUBSCRIBE</p>
              </div>
            </div>
            <br />
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Accordion
            style={{
              backgroundColor: "#404040",
              color: "#fff",
              boxShadow: "none",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>SHOP BY FACE SHAPE</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List style={{ fontSize: "20px" }}>
                <ListItem>Oval</ListItem>
                <ListItem>Square</ListItem>
                <ListItem>Diamond</ListItem>
                <ListItem>Round</ListItem>
                <ListItem>Heart</ListItem>
                <ListItem>Traingle</ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{
              backgroundColor: "#404040",
              color: "#fff",
              boxShadow: "none",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>SHOP BY FRAME SHAPE</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List style={{ fontSize: "20px" }}>
                <ListItem>Rectangle</ListItem>
                <ListItem>Square</ListItem>
                <ListItem>Round</ListItem>
                <ListItem>Cat-Eye</ListItem>
                <ListItem>Oval</ListItem>
                <ListItem>Geometric</ListItem>
                <ListItem>Browline</ListItem>
                <ListItem>Aviator</ListItem>
                <ListItem>Wrap</ListItem>
                <ListItem>Classic wayframe</ListItem>
                <ListItem>Oversized</ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{
              backgroundColor: "#404040",
              color: "#fff",
              boxShadow: "none",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>SHOP BY BRAND</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List style={{ fontSize: "20px" }}>
                <ListItem>Ray-ban</ListItem>
                <ListItem>Oakley</ListItem>
                <ListItem>DKNY</ListItem>
                <ListItem>Persol</ListItem>
                <ListItem>Versace</ListItem>
                <ListItem>Coach</ListItem>
                <ListItem>Calvein Klien</ListItem>
                <ListItem>Vogue</ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{
              backgroundColor: "#404040",
              color: "#fff",
              boxShadow: "none",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>SHOP BY COLOR</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List style={{ fontSize: "20px" }}>
                <ListItem>Tortoise</ListItem>
                <ListItem>Black</ListItem>
                <ListItem>Clear</ListItem>
                <ListItem>Gold</ListItem>
                <ListItem>Brown</ListItem>
                <ListItem>Blue</ListItem>
                <ListItem>Silver</ListItem>
                <ListItem>Gray</ListItem>
                <ListItem>Pink</ListItem>
                <ListItem>White</ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <div className="terms" style={{ backgroundColor: "#fff" }}>
            <h6 className="terms_privacy">
              Terms & Conditions | Privacy & Cookies
            </h6>
            <h6 className="terms_copyright">
              Copyright © kareye 2022 . All Rights Reserved.
            </h6>
          </div>
        </Grid>
      </Grid>
    </StyledFooterMain>
  );
};

export default FooterMain;
