import styled from "styled-components";

const StyledNavbar = styled.div`
  justify-content: space-evenly; */
  align-items: center;
  /* padding: 1rem 0; */
  .checkout_btn{
    background-color: #505050 !important;
  }

  .logo_image {
    display: flex;
    align-items: center;
    img {
      width: 100%;
      max-width: 150px;
    }
  }
  .menu {
    display: flex;
    /* border: 1px solid black; */
    align-items: center;
  }
 
  .search_box {
    align-items: center;
    /* border: 1px solid red; */
    .search_input {
      background-color: #e5e5e5;
      border-radius: 10px;
    }
  }

  .product_menu {
    display: flex;
    align-items: center;

    .product_controls {
      display: flex;
      justify-content: space-between;
      /* border: 1px solid red; */

      .product_control_text_img {
        font-size: 14px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        /* border: 1px solid red; */
        padding: 1rem 0.5rem;
        img {
          width: 100%;
          max-width: 16px;
        }
        .product_control_text {
          font-size: 14px;
          margin: 0 !important;
        }
      }
    }
  }
`;

export default StyledNavbar;
