import React, { useState } from "react";
import FaceComponent from "./FaceComponent";
import FrameShapeComponent from "./FrameShapeComponent";
import StyledCategorySelector from "./StyledCategorySelector";
import ColorComponent from "../Components/ColorComponent";
import { Row, Col } from "antd";
import ImageContainer from "../Components/ImageContainer";
import { Grid } from "@mui/material";
import ShopByText from "./ShopByText";
import faceShape from "../../../assets/Images/face_shape_img.png";
import frameshape from "../../../assets/Images/frame_shape_img.jpeg";
import colorImg from "../../../assets/Images/color_img.jpeg";

const SelectComponent = () => {
  const [state, setstate] = useState("face");
  const imageThumbs = {
    face: faceShape,
    frame: frameshape,
    color: colorImg,
  };
  return window.screen.width > 900 ? (
    <>
      <Grid container justifyContent="center" style={{ marginTop: "3rem" }}>
        <Grid item xs={7}>
          <ImageContainer src={imageThumbs[state]} />
        </Grid>
        <Grid item xs={5}>
          <Grid container justifyContent="left">
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
              <ShopByText />
              <div className="divider"></div>
            </Grid>
          </Grid>
          <StyledCategorySelector>
            <Row justify="center">
              <Col className="color_tab" xs={6}>
                <h3
                  style={
                    state == "face" ? { fontWeight: 500 } : { fontWeight: 400 }
                  }
                  onClick={() => setstate("face")}
                >
                  FaceShape
                </h3>
              </Col>
              <Col className="color_tab" xs={1}>
                <div className="vl"></div>
              </Col>
              <Col className="color_tab" xs={7}>
                <h3
                  onClick={() => setstate("frame")}
                  style={
                    state == "frame" ? { fontWeight: 500 } : { fontWeight: 400 }
                  }
                >
                  FrameShape
                </h3>
              </Col>
              <Col className="color_tab" xs={1}>
                <div className="vl"></div>
              </Col>
              <Col className="color_tab" xs={4}>
                <h3
                  onClick={() => setstate("color")}
                  style={
                    state == "color" ? { fontWeight: 500 } : { fontWeight: 400 }
                  }
                >
                  Color
                </h3>
              </Col>
            </Row>
          </StyledCategorySelector>
          {state === "face" ? (
            <FaceComponent />
          ) : state === "frame" ? (
            <FrameShapeComponent />
          ) : state === "color" ? (
            <ColorComponent />
          ) : null}
        </Grid>
      </Grid>
      <div className="divider"></div>
    </>
  ) : (
    <Grid container alignItems={"center"} spacing={2}>
      <Grid item xs={12}>
        <StyledCategorySelector>
          <div className="container">
            <Grid container spacing={1} alignItems={"center"}>
              <Grid item xs={4}>
                <h3
                  className="select_category_h3_mob"
                  style={
                    state == "face" ? { fontWeight: 500 } : { fontWeight: 400 }
                  }
                  onClick={() => setstate("face")}
                >
                  FACE SHAPE
                </h3>
              </Grid>
              <Grid item xs={4}>
                <h3
                  className="select_category_h3_mob"
                  onClick={() => setstate("frame")}
                  style={
                    state == "frame" ? { fontWeight: 500 } : { fontWeight: 400 }
                  }
                >
                  FRAME SHAPE
                </h3>
              </Grid>
              <Grid item xs={4}>
                <h3
                  className="select_category_h3_mob"
                  onClick={() => setstate("color")}
                  style={
                    state == "color"
                      ? { fontWeight: 500, marginTop: "1rem" }
                      : { fontWeight: 400, marginTop: "1rem" }
                  }
                >
                  COLOR
                </h3>
              </Grid>
            </Grid>
          </div>
        </StyledCategorySelector>
        {state === "face" ? (
          <FaceComponent />
        ) : state === "frame" ? (
          <FrameShapeComponent />
        ) : state === "color" ? (
          <ColorComponent />
        ) : null}
      </Grid>
    </Grid>
  );
};

export default SelectComponent;
