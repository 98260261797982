import styled from "styled-components";

const StyledViewAll = styled.h5`
  display: flex;
  justify-content: center;
  /* border: 1px solid red; */
  padding: 1rem !important;
`;

export default StyledViewAll;
