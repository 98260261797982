import styled from "styled-components";

const StyledFooterTop = styled.div`
  /* border: 1px solid red; */
  background: #f1f1f1;
  padding: 2rem 0;

  .footer_top_items {
    display: flex;
    justify-content: center;
    align-items: center;
    .circle_footer_item {
      display: flex;
      align-items: center;
      .circle {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 0.5rem;
        img {
          width: 60%;
        }
      }
      .text {
        font-size: 14px;
        font-weight: 500;
        color: #404040;
      }
      .subtitle {
        margin-bottom: 0px;
        color: #404040;
        font-size: 14px;
      }
    }
  }
`;

export default StyledFooterTop;
